import { isSome, treeUtil } from '@salescore/buff-common'
import type { ReferenceToProperty, ViewConfigTreeNode } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { hasChangeToViewSchemaAtom } from '../../atoms'
import { configSheetSelector } from '../../selectors/configSheetSelector'
import { setConfigMutation } from '../setConfigMutation'

export type ReferenceToPropertyForAddChildNodeMutation = Omit<ReferenceToProperty, 'nodeName'> & {
  nodeName?: string
  parentNodeName: string
}
export const addChildNodeMutation = mutation<{
  node: ViewConfigTreeNode
  referenceToProperty: ReferenceToPropertyForAddChildNodeMutation
  onAdded: (newNodeName: string) => void
}>({
  key: `view/addChildNodeMutation`,
  set({ get, set }, { node, referenceToProperty, onAdded }) {
    const { config } = get(configSheetSelector)
    const { tree } = config
    if (tree === undefined) {
      // 初期化はsetRootNodeにより終わっているはず
      return
    }
    const added = addViewConfigChildNode(tree, node, referenceToProperty)
    if (added.tree !== undefined) {
      set(setConfigMutation, {
        ...config,
        tree: added.tree,
      })
      set(hasChangeToViewSchemaAtom, true)
    }
    onAdded(added.newNodeName)
  },
})

export function findSameReferenceToChild(
  node: ViewConfigTreeNode,
  referenceToProperty: ReferenceToPropertyForAddChildNodeMutation,
) {
  return node.children?.find(
    (child) =>
      referenceToProperty.parentNodeName === node.name &&
      isSome(child.referenceToProperty) &&
      (referenceToProperty.nodeName === undefined
        ? // nodeNameが空のときは、これから生成する子ノード側にプロパティがあるということ。子ノード側にプロパティがあるのかを判定する
          child.name === child.referenceToProperty.nodeName
        : // nodeNameが存在するときは、生成済みの親ノードにプロパティがあるということ。親ノードの名前が一致するかを判定する
          node.name === child.referenceToProperty.nodeName && node.name === referenceToProperty.nodeName) &&
      referenceToProperty.modelName === child.referenceToProperty.modelName &&
      referenceToProperty.propertyName === child.referenceToProperty.propertyName &&
      referenceToProperty.referenceTo.modelName === child.referenceToProperty.referenceTo.modelName &&
      referenceToProperty.referenceTo.key === child.referenceToProperty.referenceTo.key,
  )
}

function addViewConfigChildNode(
  tree: ViewConfigTreeNode,
  node: ViewConfigTreeNode,
  referenceToProperty: ReferenceToPropertyForAddChildNodeMutation,
) {
  // 対象のnodeが既に同じ条件のchildをもっていればそれを返す
  const child = findSameReferenceToChild(node, referenceToProperty)
  if (isSome(child)) {
    return {
      newNodeName: child.name,
      tree: undefined,
    }
  }

  const newNodeModelName =
    referenceToProperty.modelName === node.modelName
      ? referenceToProperty.referenceTo.modelName
      : referenceToProperty.modelName
  const newNodeName = generateNewNodeName(treeUtil.flatNodes(tree), newNodeModelName)
  const newNode: ViewConfigTreeNode = {
    type: 'model',
    name: newNodeName,
    modelName: newNodeModelName,
    referenceToProperty: {
      ...referenceToProperty,
      nodeName: referenceToProperty.nodeName ?? newNodeName,
    },
  }
  const newTree = treeUtil.add(tree, node.name, newNode)
  return {
    tree: newTree,
    newNodeName: newNode.name,
  }
}

function generateNewNodeName(nodes: ViewConfigTreeNode[], originalName: string, depth = 0): string {
  const name = depth === 0 ? originalName : `${originalName}_${depth}`
  if (nodes.map((x) => x.name).includes(name)) {
    return generateNewNodeName(nodes, originalName, depth + 1)
  }
  return name
}
