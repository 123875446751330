import { DownOutlined, ExclamationCircleOutlined, RedoOutlined, SwapOutlined } from '@ant-design/icons'
import { useMutation, useSubscription } from '@apollo/client'
import { SubscribeJobQueueDocument, SyncContextEnum, SyncHubConnectionDocument } from '@salescore/client-api'
import { Posthog } from '@salescore/client-base'
import { type ConnectionWithSourceProvider, getOrganizationIdFromPath } from '@salescore/client-common'
import { ButtonWithTooltip } from '@salescore/frontend-common'
import { App, Dropdown, Popconfirm } from 'antd'
import { t } from 'i18next'
import { type ReactElement, useState } from 'react'

const SubscribeJob = ({ jobId, onSuccess }: { jobId: string; onSuccess: () => void }): ReactElement => {
  const { message } = App.useApp()

  useSubscription(SubscribeJobQueueDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      jobId,
    },
    onError: () => {
      message.error(t('エラーが発生しました'))
    },
    onComplete: () => {
      message.success(t('同期が完了しました'))
      onSuccess()
    },
  })
  return <></>
}

export const SyncButton = ({
  connection,
  onSuccess,
}: {
  connection: ConnectionWithSourceProvider
  onSuccess: () => void
}): ReactElement => {
  const [jobId, setJobId] = useState<string | undefined>()
  const { message } = App.useApp()

  const [syncHubConnectionMutation] = useMutation(SyncHubConnectionDocument)
  const queueSync = (syncContext: SyncContextEnum): void => {
    void syncHubConnectionMutation({
      variables: {
        id: connection.id,
        organizationId: getOrganizationIdFromPath(),
        syncContext,
      },
      onCompleted: (data) => {
        const { syncConnection } = data
        if (syncConnection == null) {
          // queueが一杯で入れられなかった
          message.warning(t('別の同期が実行中です'))
        } else {
          message.info(t('同期を開始しました'))
          setJobId(syncConnection.id)
        }
      },
      onError: (error) => {
        void message.error(error.message)
      },
    })
    Posthog.track('click-syncConnectionButton', {
      organizationId: getOrganizationIdFromPath(),
    })
  }
  const items = [
    {
      key: 'incremental',
      icon: <SwapOutlined />,
      label: t(`差分のみ取得`),
      onClick: () => {
        queueSync(SyncContextEnum.ManualIncremental)
      },
    },
    {
      key: 'bulk',
      icon: <ExclamationCircleOutlined />,
      label: (
        <Popconfirm
          title={
            <div>
              {t(`全てのレコードを取得するには非常に時間がかかる可能性があります。よろしいですか？`)}
              <br />
              {t(`※取得データに不整合がおきた場合などのみお使いください。`)}
            </div>
          }
          onConfirm={() => {
            queueSync(SyncContextEnum.ManualBulk)
          }}
          okText={t(`実行`)}
          cancelText={t(`キャンセル`)}
        >
          {t(`全てのレコードを取得`)}
        </Popconfirm>
      ),
    },
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']} arrow disabled={!connection.active}>
      <ButtonWithTooltip
        tooltipTitle={t(`連携が停止中のため同期できません`)}
        showTooltip={!connection.active}
        icon={<RedoOutlined />}
        disabled={!connection.active}
        loading={jobId !== undefined}
      >
        {jobId !== undefined && (
          <SubscribeJob
            jobId={jobId}
            onSuccess={() => {
              onSuccess()
              setJobId(undefined)
            }}
          />
        )}
        {t(`同期`)}
        <DownOutlined />
      </ButtonWithTooltip>
    </Dropdown>
  )
}
