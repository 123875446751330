import type { FetchMeQuery } from '@salescore/client-api'
import { atom } from 'recoil'

export interface Me {
  myUser: FetchMeQuery['myUser']
  myIdentity: FetchMeQuery['myUser']['identity']
  organization: FetchMeQuery['myUser']['organization']
  isAdmin: boolean
  isAdminOrManager: boolean
  isPlayingManager: boolean
}

export const meAtom = atom<Me | undefined>({
  key: `me`, // global側のmeと名前を統一している
  default: undefined,
})
