import dayjs from 'dayjs'
import { t } from 'i18next'
import { selector, useRecoilValue, useSetRecoilState } from 'recoil'

import { highlightConfigAtom } from '../atoms'

// i18n: グローバル変数・配列で保持する系のラベルはi18nがうまく動かないことがありそうなので一旦保留
const dateForDiffHighlightOptions = [
  {
    value: '',
    label: t(`ハイライトなし`),
    date: undefined,
  },
  {
    value: '1 day',
    label: t(`昨日`),
    date: dayjs().subtract(1, 'day'),
  },
  {
    value: '2 day',
    label: t(`一昨日`),
    date: dayjs().subtract(2, 'day'),
  },
  {
    value: '3 day',
    label: t(`3日前`),
    date: dayjs().subtract(3, 'day'),
  },
  {
    value: '1 week',
    label: t(`1週間`),
    date: dayjs().subtract(1, 'week'),
  },
]

export const highlightConfigSelector = selector({
  key: `highlightConfigSelector`,
  get({ get }) {
    const highlightConfig = get(highlightConfigAtom)
    const selectedDateForDiffHighlightOption =
      dateForDiffHighlightOptions.find((x) => x.value === highlightConfig?.value) ??
      dateForDiffHighlightOptions.first()!
    const dateForDiffHighlight = selectedDateForDiffHighlightOption.date?.format('YYYY-MM-DD')

    return {
      highlightConfig,
      dateForDiffHighlight,
      selectedDateForDiffHighlightOption,
      dateForDiffHighlightOptions,
    }
  },
})

const highlightConfigMutation = selector<string>({
  key: `highlightConfigMutation`,
  get() {
    return ''
  },
  set({ get, set }, newValue) {
    const selectedDateForDiffHighlightOption =
      dateForDiffHighlightOptions.find((x) => x.value === newValue) ?? dateForDiffHighlightOptions.first()!
    set(highlightConfigAtom, selectedDateForDiffHighlightOption)
  },
})

export const useHighlightConfig = () => {
  const getter = useRecoilValue(highlightConfigSelector)
  const setter = useSetRecoilState(highlightConfigMutation)
  return {
    ...getter,
    setHighlightConfig: setter,
  }
}
