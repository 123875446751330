import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignJustify, faCamera, faCodeSimple } from '@fortawesome/sharp-solid-svg-icons'
import { CORE_CONSTANT, type CoreModel } from '@salescore/core'

export const CustomModelOrSnapshotIcon = ({ model }: { model: CoreModel }): JSX.Element => {
  const isUserCustomModel = model.name.startsWith(CORE_CONSTANT.CUSTOM_MODEL_PREFIX)
  const isSalescoreCustomModel = model.name.startsWith(CORE_CONSTANT.SALESCORE_CUSTOM_MODEL_PREFIX)

  if (isUserCustomModel) {
    return <FontAwesomeIcon icon={faCodeSimple} width={22} />
  }
  if (isSalescoreCustomModel) {
    return <FontAwesomeIcon icon={faAlignJustify} width={22} />
  }
  if (isSnapshotModel(model)) {
    return <FontAwesomeIcon icon={faCamera} width={22} />
  }
  return <></>
}

export function isCustomModel(model: CoreModel): boolean {
  return (
    model.name.startsWith(CORE_CONSTANT.CUSTOM_MODEL_PREFIX) ||
    model.name.startsWith(CORE_CONSTANT.SALESCORE_CUSTOM_MODEL_PREFIX)
  )
}

export function isSnapshotModel(model: CoreModel): boolean {
  return model.name.startsWith(CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX)
}

// TODO: 適切な場所に移す
export function isDataExtensionModel(model: CoreModel): boolean {
  return isCustomModel(model) || isSnapshotModel(model)
}
