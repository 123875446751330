import { isPresent, isTruthy } from '@salescore/buff-common'
import type { ViewFieldsFragment } from '@salescore/client-api'
import {
  CORE_CONSTANT,
  type ViewConfigAdditionalConfig,
  type ViewConfigKpi,
  type ViewQueryRecordNode,
} from '@salescore/core'
import { isSharedLinkPresetName, mutation } from '@salescore/frontend-common'
import type { GetRecoilValue } from 'recoil'

import { changesModalAtom, kpiPivotPickedPresetNameAtom, sheetPickedPresetNameAtom } from '../../navigation/atoms'
import {
  additionalConfigAtom,
  changesAtom,
  errorsDrawerOpenAtom,
  recordsAtom,
  sheetInSaveErrorsAtom,
} from '../../records/atoms'
import {
  hasChangeToKpiPivotParameterAtom,
  hasChangeToViewSchemaAtomRaw,
  kpiFormAtom,
  kpiFormModifiedAtom,
  viewAtom,
} from '../atoms'
import { cachedViewsSelector } from '../selectors/cachedViewSelector'
import { setConfigWithoutChangeMutation } from './setConfigMutation'

export const initializeViewMutation = mutation({
  key: `view/initializeViewMutation`,

  set(
    { get, set },
    {
      view,
      additionalConfig,
      isSharedUrlApplied,
    }: { view: ViewFieldsFragment; additionalConfig?: ViewConfigAdditionalConfig; isSharedUrlApplied?: boolean },
  ) {
    // viewの変更時、まとめてconfigなどを変更する
    // viewの変更とconfigの変更を別々のhooksで行なったりすると、新しいviewId・古いconfigの状態でmutationが走ったりするので注意

    // キャッシュがある場合はキャッシュから復元する
    const cache = getCachedViewValue({ view, isSharedUrlApplied: isSharedUrlApplied ?? false, get })

    set(viewAtom, view)
    set(changesAtom, cache.changes ?? [])
    set(recordsAtom, cache.records ?? [])

    set(setConfigWithoutChangeMutation, cache.config ?? view.config)
    set(additionalConfigAtom, additionalConfig ?? cache.additionalConfig ?? {})
    set(kpiPivotPickedPresetNameAtom, cache.kpiPivotPickedPresetName)
    set(sheetPickedPresetNameAtom, cache.sheetPickedPresetName)

    // hasChangeToViewSchemaAtom自体はviewがundefinedだと値をセットできないので、rawの方に値を入れている
    set(hasChangeToViewSchemaAtomRaw, cache.hasChangeToViewSchema ?? false)
    set(hasChangeToKpiPivotParameterAtom, cache.hasChangeToKpiPivotParameter ?? false)

    // kpiのフォーム用の対応
    if (view.config.type === 'kpi') {
      set(kpiFormAtom, cache.kpiForm ?? generateInitialKpiForm({ viewName: view.name, config: view.config }))
      set(kpiFormModifiedAtom, cache.isModifiedKpiForm ?? false)
    }

    set(sheetInSaveErrorsAtom, cache.sheetInSaveErrors)
    set(errorsDrawerOpenAtom, cache.errorsDrawerOpen ?? false)
    set(changesModalAtom, cache.changesModal ?? { visible: false, content: undefined })
  },
})

function getCachedViewValue({
  view,
  isSharedUrlApplied,
  get,
}: {
  view: ViewFieldsFragment
  isSharedUrlApplied: boolean
  get: GetRecoilValue
}) {
  const { getCachedView } = get(cachedViewsSelector)
  const currentCachedView = getCachedView(view.id)
  const cachedChanges = currentCachedView?.changes

  const latestChange = cachedChanges?.last()
  const cachedRecords = isPresent(latestChange)
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      (JSON.parse(latestChange.snapshotAfterChange) as ViewQueryRecordNode[])
    : undefined

  // キャッシュされたconfigを使用するべきか否か
  const isViewTemporary = [
    CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX,
    CORE_CONSTANT.KPI_TIME_SERIES_DRILL_DOWN_VIEW_ID_PREFIX,
    // TODO: 他にもあるかも
  ].some((temporaryPrefix) => view.id.startsWith(temporaryPrefix))
  const isEqualViewUpdatedAt = view.updatedAt === currentCachedView?.updatedAt
  const isSharedLinkPresetPickedInCache = isSharedLinkPresetName(currentCachedView?.kpiPivotPickedPresetName)
  const shouldApplyCachedConfig =
    !isViewTemporary &&
    !isSharedUrlApplied &&
    (isSharedLinkPresetPickedInCache ||
      isEqualViewUpdatedAt ||
      isPresent(cachedChanges) ||
      isTruthy(currentCachedView?.hasChangeToViewSchema))

  return {
    ...currentCachedView,
    config: shouldApplyCachedConfig ? currentCachedView?.config : undefined,
    records: cachedRecords,
  }
}

// 他に適切な置き場所があるかも。。。
export function generateInitialKpiForm({ viewName, config }: { viewName: string; config: ViewConfigKpi }) {
  return {
    ...config,
    name: viewName,
    dashboardIds: [], // TODO
  }
}
