import { selector, useRecoilValue } from 'recoil'

import { contextAtom } from '../atoms'

const kpisSelector = selector({
  key: `view/kpisSelector`,
  get({ get }) {
    const context = get(contextAtom)
    const kpis = context.kpis ?? []

    return {
      kpis,
      kpisMapper: kpis.groupByUniqueKey((x) => x.id),
    }
  },
})

export const useKpisSelector = () => useRecoilValue(kpisSelector)
