import { CORE_CONSTANT, type ViewConfigField, type ViewConfigFilterNode, type ViewQueryField } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'
import { useSetRecoilState } from 'recoil'

import { additionalConfigAtom } from '../../records/atoms'
import { configSheetAtom, viewAtom } from '../atoms'
import { isSameField, isSameNodeProperty } from './field/util'
import { setConfigMutation, setConfigWithoutChangeMutation } from './setConfigMutation'

const removeFilterByFieldMutation = mutation({
  key: `view/removeFilterByFieldMutation`,
  set({ get, set }, { field }: { field: ViewQueryField }) {
    const config = get(configSheetAtom)
    const {filterTree} = config
    if (filterTree === undefined) {
      return
    }
    const configField = (config.fields ?? []).find((configField) => isSameField(configField, field))
    if (configField === undefined) {
      return
    }
    set(setConfigMutation, {
      ...config,
      filterTree: removeLeafsByFilterRec(filterTree, configField),
    })
  },
})

function removeLeafsByFilterRec(node: ViewConfigFilterNode, field: ViewConfigField): ViewConfigFilterNode {
  return {
    ...node,
    children: node.children.map((child) => removeLeafsByFilterRec(child, field)),
    leafs: node.leafs.filter((leaf) => leaf.type === 'property' && !isSameNodeProperty(leaf.property, field.property)),
  }
}

const setFilterMutation = mutation({
  key: `view/setFilterMutation`,
  set({ get, set }, { filterTree }: { filterTree: ViewConfigFilterNode | undefined }) {
    const config = get(configSheetAtom)
    const view = get(viewAtom)
    const additionalConfig = get(additionalConfigAtom)
    if (
      view.id.startsWith(
        CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX + CORE_CONSTANT.KPI_PREVIEW_DRILL_DOWN_VIEW_ID_PREFIX,
      ) ||
      additionalConfig.previousDimmensions === undefined
    ) {
      // KPI タブの一覧パネル、KPI プレビューのドリルダウン、あるいはシートタブの場合は、フィルターを変更したら「設定を保存」ボタンが出現するようにする
      set(setConfigMutation, {
        ...config,
        filterTree: filterTree ?? {
          logicalOperator: 'and',
          leafs: [],
          children: [],
        },
      })
      return
    }
    // ダッシュボードからドリルダウンした場合は、フィルターを変更しても「設定を保存」ボタンが出現しないようにする
    set(setConfigWithoutChangeMutation, {
      ...config,
      filterTree: filterTree ?? {
        logicalOperator: 'and',
        leafs: [],
        children: [],
      },
    })
  },
})

export const useFilterMutations = () => ({
  set: useSetRecoilState(setFilterMutation),
  removeFilterByField: useSetRecoilState(removeFilterByFieldMutation),
})
