import { FolderOpenOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { TAILWIND_CSS_COLORS } from '@salescore/client-base'
import { checkVisualizeEnabled, recoil } from '@salescore/client-recoil'
import { Avatar, Space, Spin } from 'antd'
import { t } from 'i18next'
import platform from 'platform'
import { useEffect, useRef, useState } from 'react'

const ROW_HEIGHT = 58
const VISIBLE_ROW_NUM = 4
const LITTLE_VISIBLE_ROW_RATIO = 0.5 // ちょっと見える行
const color = `#555`

export function LauncherCommandList({
  closeLauncher,
  launcherState,
}: {
  closeLauncher: () => void
  launcherState: ReturnType<typeof recoil.global.launcher.useLauncherState>
}) {
  const me = recoil.global.useMe()
  const isVisEnabled = checkVisualizeEnabled(me.myUser)
  const reference = useRef<HTMLDivElement>(null)
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>()
  useEffect(() => {
    if (reference.current !== null) {
      handleScroll({
        cursorTopY: launcherState.cursor * ROW_HEIGHT,
        cursorHeight: ROW_HEIGHT,
        rootDivElement: reference.current,
      })
    }
  }, [launcherState.cursor])
  if (!isVisEnabled && (launcherState.filterType === 'kpi' || launcherState.filterType === 'kpiPivot')) {
    return (
      <div
        style={{
          padding: '16px 0px',
          fontSize: 12.8,
          color: '#656565',
        }}
        className="flex align-middle"
      >
        <Space style={{ paddingLeft: 30, height: 26 }}>
          <span style={{ fontSize: 14, letterSpacing: 1.05 }}>
            {t(`VISUALIZEライセンスがないため、KPI・ダッシュボードは閲覧できません`)}
          </span>
        </Space>
      </div>
    )
  }
  if (launcherState.filteredCommands.length === 0) {
    return (
      <div
        style={{
          padding: '16px 0px',
          fontSize: 12.8,
          color: '#656565',
        }}
        className="flex align-middle"
      >
        <Space style={{ paddingLeft: 30, height: 26 }}>
          <span style={{ fontSize: 14, letterSpacing: 1.05 }}>{t(`見つかりませんでした`)}</span>
        </Space>
      </div>
    )
  }

  return (
    <div
      className="relative"
      style={{
        maxHeight: ROW_HEIGHT * (VISIBLE_ROW_NUM + LITTLE_VISIBLE_ROW_RATIO),
        overflowY: 'scroll',
        scrollbarColor: '#717171',
        // scrollbarWidth: "auto",
        color,
      }}
      ref={reference}
    >
      {}
      {launcherState.filteredCommands.map((command, index) => {
        const isActive = hoveredIndex === undefined ? index === launcherState.cursor : index === hoveredIndex
        const backgroundColor = isActive ? TAILWIND_CSS_COLORS.blue['50'] : 'inherit'

        return (
          <div
            style={{
              padding: '16px 0px',
              fontSize: 12.8,
              backgroundColor,
              height: ROW_HEIGHT,
            }}
            className="relative flex cursor-pointer justify-between align-middle"
            onMouseOver={() => {
              // setHoveredIndex(i)
              launcherState.setCursor(index)
            }}
            onMouseOut={() => {
              // setHoveredIndex(undefined)
            }}
            onClick={() => {
              launcherState.executeCommand(command, closeLauncher)
            }}
          >
            {isActive && (
              <div
                className="absolute left-0 top-0 h-full"
                style={{
                  width: 3,
                  backgroundColor: '#4C82F7',
                }}
              />
            )}
            <Space style={{ paddingLeft: 30, height: 26 }}>
              <Avatar
                icon={command.icon}
                style={{ backgroundColor, border: `1px solid ${color}`, color, marginRight: 8 }}
                size={26}
              />
              <div style={{ lineHeight: 1.4 }}>
                <span style={{ fontSize: 14, letterSpacing: 1.05 }}>{command.title}</span>
                {command.subTitle !== undefined && (
                  <>
                    <br />
                    <span
                      className="flex flex-row gap-x-0.5 text-gray-400"
                      style={{ fontSize: 10, letterSpacing: 1.05 }}
                    >
                      <FolderOpenOutlined />
                      {command.subTitle ?? ''}
                    </span>
                  </>
                )}
              </div>
            </Space>
            <Space className="mr-4">
              {command.loading === true ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#B8B9B9' }} spin />} />
              ) : command.createdBy === undefined ? (
                (command.shortcut ?? []).map((keyText) => <KeyIcon keyText={keyText} />)
              ) : (
                <div
                  className="flex flex-row gap-x-0.5 text-gray-400"
                  style={{ fontSize: 10, letterSpacing: 1.05, marginTop: 24 }}
                >
                  <UserOutlined />
                  {command.createdBy.name}
                </div>
              )}
            </Space>
          </div>
        )
      })}
    </div>
  )
}

// 引数をkeyにするとエラーになるので注意
function KeyIcon({ keyText }: { keyText: string }) {
  const key = getKeyText(keyText)
  return (
    <div
      style={{
        width: 19 + 5 * key.length,
        height: 24,
        // backgroundColor: 'rgba(0, 0, 0, .2)',
        border: '1px solid rgba(0, 0, 0, .5)',
        color,
        // fontFamily: AvenirBook;
        fontSize: '.7em',
        padding: '4px 5px',
        textAlign: 'center',
        borderRadius: 3,
        borderColor: `#bbb`,
      }}
      className="uppercase"
    >
      {key}
    </div>
  )
}

function getKeyText(key: string): string {
  const isWindows = platform.os?.family === 'Windows'
  switch (key) {
    case 'command': {
      return isWindows ? `Ctrl` : '⌘'
    }
  }

  return key
}

function handleScroll({
  cursorTopY,
  cursorHeight,
  rootDivElement,
}: {
  cursorTopY: number
  cursorHeight: number
  rootDivElement: HTMLDivElement
}) {
  const cursorBottomY = cursorTopY + cursorHeight
  const areaHeight = rootDivElement.clientHeight
  const areaTopY = rootDivElement.scrollTop
  const areaBottomY = areaTopY + areaHeight

  if (areaBottomY <= cursorBottomY) {
    // カーソルが表示領域の下側にいってしまった場合
    rootDivElement.scrollTo({
      top: cursorBottomY - areaHeight + ROW_HEIGHT * LITTLE_VISIBLE_ROW_RATIO,
      behavior: undefined,
    }) // カーソルの下端が表示領域の下端となるようにする
  } else if (cursorTopY <= areaTopY) {
    // カーソルが表示領域の上側にいってしまった場合
    rootDivElement.scrollTo({ top: cursorTopY - ROW_HEIGHT * LITTLE_VISIBLE_ROW_RATIO, behavior: undefined })
  }
}
