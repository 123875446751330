import { useMutation } from '@apollo/client'
import { r } from '@salescore/buff-common'
import {
  CreateViewDocument,
  type CreateViewInput,
  DeleteViewDocument,
  UpdateViewDocument,
  type ViewFieldsFragment,
  type ViewUpdateInputSingle,
} from '@salescore/client-api'
import { mutation } from '@salescore/frontend-common'
import { useSetRecoilState } from 'recoil'

import { useOrganizationId } from '../../global/hooks'
import { viewAtomFamily, viewIdsAtom } from '../atoms'
import { useClearViewQueryCacheMutation } from './clearViewQueryCacheMutation'

const upsertViewMutation = mutation<(ViewFieldsFragment & { deleted?: boolean }) | { id: string; deleted: true }>({
  key: `rsheet/initializeViewGroupsMutation`,
  set({ get, set }, view) {
    const ids = get(viewIdsAtom)
    if (view.deleted === true) {
      set(
        viewIdsAtom,
        ids.filter((id) => id !== view.id),
      )
      return
    }
    if (!ids.includes(view.id)) {
      set(viewIdsAtom, [...ids, view.id])
    }
    set(viewAtomFamily({ id: view.id }), view)
  },
})

export const useUpsertViewMutation = () => {
  const organizationId = useOrganizationId()
  const [updateViewMutation] = useMutation(UpdateViewDocument)
  const [createViewMutation] = useMutation(CreateViewDocument)
  const [deleteViewMutation] = useMutation(DeleteViewDocument)
  const { clearRelatedViewQueryCaches } = useClearViewQueryCacheMutation()
  const setView = useSetRecoilState(upsertViewMutation)

  const updateView = async (argument: ViewUpdateInputSingle) => {
    // 権限のチェックは、表示側とサーバーサイドで行うこととし、このレイヤーではチェックしない
    const { id, ...others } = argument
    if (r(others).keys().isBlank()) {
      return
    }

    const result = await updateViewMutation({
      variables: {
        organizationId,
        view: argument,
      },
    })
    // TODO: エラーハンドリング
    const view = result.data?.updateView
    if (view === undefined) {
      // エラー以外ありえないはず
      return
    }
    await clearRelatedViewQueryCaches({ viewId: view.id, config: view.config })
    setView(view)
    return view
  }

  const deleteView = async (argument: { id: string }) => {
    const result = await deleteViewMutation({
      variables: {
        organizationId,
        id: argument.id,
      },
    })

    setView({
      id: argument.id,
      deleted: true,
    })
  }

  const createView = async (argument: CreateViewInput) => {
    const result = await createViewMutation({
      variables: {
        organizationId,
        view: {
          viewGroupId: argument.viewGroupId,
          config: argument.config,
          name: argument.name,
        },
      },
    })

    const view = result.data?.createView
    if (view === undefined) {
      // エラー以外ありえないはず
      return
    }
    setView(view)
    return view
  }

  return {
    createView,
    updateView,
    deleteView,
  }
}
