import { isNull, isPresent } from '@salescore/buff-common'
import { CORE_CONSTANT, flatRecordNodes, generateLabelSqlFieldName } from '@salescore/core'
import { EChartStacked } from '@salescore/frontend-common'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { type ReactNode, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { usePeriodSelector } from '../../../../recoil/navigation/selector/PeriodSelector'
import { recordsAtom } from '../../../../recoil/records/atoms'
import { useViewConfigKpiTimeSeries } from '../../../../recoil/view/hooks'
import { getMergedRecordsEmptyToNull } from '../../ViewUIKpi/KpiStackedChart'
import type { ViewUIRiPreviewProperties } from '../viewUIRiPreviewProperties'
import { ViewUiRiPreviewWrapper } from './ViewUiRiPreviewWrapper'
import {
  mapIndexToDimensionFieldName,
  useViewUiRi,
  ViewUiRiTimeSeriesPreviewHeader,
} from './ViewUiRiTimeSeriesPreviewHeader'

const ACTUAL_KEY = JSON.stringify(['actual'])
const GOAL_KEY = JSON.stringify(['goal'])
const TOTAL_VALUE = CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING

export function ViewUiRiTimeSeriesPreview(properties: ViewUIRiPreviewProperties): ReactNode {
  return (
    <ViewUiRiPreviewWrapper
      header={<ViewUiRiTimeSeriesPreviewHeader {...properties} />}
      body={<ViewUIRiTimeSeriesGraph />}
    />
  )
}

function ViewUIRiTimeSeriesGraph(): ReactNode {
  const [config] = useViewConfigKpiTimeSeries()
  const [, setPeriod] = usePeriodSelector()
  const { drillDownModal } = useNavigationModal()

  const { createdDateKey, breakdownPropertyKey, actualRecords, goalRecords } = useRiTimeSeriesGraphRecords()
  const mergedActualRecords = getMergedRecordsEmptyToNull(actualRecords)

  const designSettings =
    config?.designSettings === undefined
      ? undefined
      : {
          axisLabelDisplayMode: config.designSettings.chartAxisLabelDisplayMode,
          valueLabelDisplayMode: config.designSettings.chartValueLabelDisplayMode,
          valueLabelUnitType: config.designSettings.chartValueLabelUnitType,
          valueLabelDecimalPlaceType: config.designSettings.chartValueLabelDecimalPlaceType,
        }

  useEffect(() => {
    if (config?.period === undefined) {
      return
    }
    setPeriod({
      ...config.period,
      startAt: config.period.startAt === undefined ? undefined : dayjs(config.period.startAt),
      endAt: config.period.startAt === undefined ? undefined : dayjs(config.period.endAt),
    })
  }, [config])

  if (createdDateKey === undefined) {
    return null
  }

  return (
    <div className="h-full overflow-hidden py-6">
      <EChartStacked
        title=""
        hideImageDownload
        onSeriesClick={(value) => {
          if (isNull(config)) {
            return
          }

          drillDownModal.showModal({
            type: 'kpi',
            dimensionValues: {
              rows: [
                {
                  value: value.key,
                  label: value.label,
                },
                isPresent(value.groupKey)
                  ? {
                      value: value.groupKey,
                      label: value.groupLabel,
                    }
                  : undefined,
              ].compact(),
              columns: [],
            },
          })
        }}
        onXAxisClick={({ value }) => {
          drillDownModal.showModal({
            type: 'kpi',
            dimensionValues: {
              rows: [
                {
                  value,
                  label: String(value),
                },
              ],
              columns: [],
            },
          })
        }}
        options={[
          {
            chartType: 'bar',
            records: mergedActualRecords,
            xKey: createdDateKey,
            stackGroups: [
              {
                valueKey: ACTUAL_KEY,
                stackKey: t('実績'),
              },
            ],
            groupKey: breakdownPropertyKey,
            xLabelKey: generateLabelSqlFieldName(createdDateKey),
            groupLabelKey:
              breakdownPropertyKey === undefined ? undefined : generateLabelSqlFieldName(breakdownPropertyKey),
            fillKeysAsDate: true,
            seriesName: (groupLabel) => (isPresent(groupLabel) ? groupLabel : t('集計値')),
            emphasis: { focus: 'self' },
            displayTotal: true,
            ...designSettings,
          },
          {
            chartType: 'line',
            records: goalRecords,
            xKey: createdDateKey,
            stackGroups: [
              {
                valueKey: GOAL_KEY,
                stackKey: t('目標'),
              },
            ],
            groupKey: breakdownPropertyKey,
            fillKeysAsDate: true,
            seriesName: (_, groupKey) => groupKey,
            emphasis: { focus: 'self' },
            connectNulls: true,
            ...designSettings,
          },
        ]}
      />
    </div>
  )
}

function useRiTimeSeriesGraphRecords() {
  const [ui] = useViewUiRi()

  const recordNodes = useRecoilValue(recordsAtom)
  const records = recordNodes.flatMap((v) => flatRecordNodes(v, []))

  const createdDateIndex = ui?.queriedDimensions.indexOf('createdDate')
  const breakdownPropertyIndex = ui?.queriedDimensions.indexOf('breakdownProperty')

  const createdDateKey = mapIndexToDimensionFieldName(createdDateIndex)
  const breakdownPropertyKey = mapIndexToDimensionFieldName(breakdownPropertyIndex)

  const actualRecords = (() => {
    if (createdDateKey === undefined) {
      return []
    }

    if (breakdownPropertyKey === undefined) {
      return records
        .filter((r) => r[createdDateKey] !== TOTAL_VALUE && isNumber(r[ACTUAL_KEY]))
        .map((r) => ({
          [createdDateKey]: r[createdDateKey],
          [ACTUAL_KEY]: r[ACTUAL_KEY],
        }))
    }

    return records
      .filter((r) => r[createdDateKey] !== TOTAL_VALUE && isNumber(r[ACTUAL_KEY]))
      .map((r) => ({
        [createdDateKey]: r[createdDateKey],
        [breakdownPropertyKey]: r[breakdownPropertyKey],
        [generateLabelSqlFieldName(breakdownPropertyKey)]: r[generateLabelSqlFieldName(breakdownPropertyKey)],
        [ACTUAL_KEY]: r[ACTUAL_KEY],
      }))
  })()

  const goalRecords = (() => {
    if (createdDateKey === undefined) {
      return []
    }

    const firstActualRecord = actualRecords.at(0)
    const lastActualRecord = actualRecords.at(-1)
    const totalGoalRecord = records.find((r) => r[createdDateKey] === TOTAL_VALUE && isNumber(r[GOAL_KEY]))

    if (firstActualRecord === undefined || lastActualRecord === undefined || totalGoalRecord === undefined) {
      return []
    }

    return [
      {
        [createdDateKey]: firstActualRecord[createdDateKey],
        [GOAL_KEY]: totalGoalRecord[GOAL_KEY],
      },
      {
        [createdDateKey]: lastActualRecord[createdDateKey],
        [GOAL_KEY]: totalGoalRecord[GOAL_KEY],
      },
    ]
  })()

  return {
    createdDateKey,
    breakdownPropertyKey,
    actualRecords,
    goalRecords,
  }
}

function isNumber(v: unknown) {
  return typeof v === 'number' && !Number.isNaN(v)
}
