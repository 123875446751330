import type { MutableSnapshot } from 'recoil'

import { type Me, meAtom } from './atoms'

type InitializeState = ((mutableSnapshot: MutableSnapshot) => void) | undefined

export const initializeState =
  ({ me }: { me: Me }): InitializeState =>
  ({ set }) => {
    set(meAtom, me)
  }
