import * as holidayJp from '@holiday-jp/holiday_jp'
import type { BusinessDayFieldsFragment } from '@salescore/client-api'
import dayjs, { type Dayjs } from 'dayjs'

const generateDates = (startAt: Dayjs, endAt: Dayjs) => {
  const days = []
  let current = startAt.startOf('day')
  while (current.isBefore(endAt.endOf('day'))) {
    // 閉区間を期待し、endAtを含む。
    days.push(current)
    current = current.add(1, 'day').startOf('day')
  }
  return days
}

interface DateWithHoliday {
  date: Dayjs
  year: number
  month: number
  day: number
  day_of_the_week: string
  is_holiday: boolean
  is_business_day: boolean
  holiday_name?: string
}

export const generateDatesWithHoliday = ({
  startAt,
  endAt,
  businessDays,
}: {
  startAt: Dayjs
  endAt: Dayjs
  businessDays: BusinessDayFieldsFragment[]
}): DateWithHoliday[] => {
  const dates = generateDates(startAt, endAt.startOf('day'))
  const holidays = holidayJp.between(startAt.toDate(), endAt.toDate()).map((holiday) => ({
    holiday,
    date: dayjs(holiday.date).startOf('day'),
  }))

  const dateWithHoliday = dates.map((date): DateWithHoliday => {
    const holiday = holidays.find((x) => x.date.isSame(date))
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const is_holiday = holidayJp.isHoliday(date.toDate())
    const businessDay = businessDays.find((x) => x.date === date.format('YYYY-MM-DD'))
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const is_business_day =
      businessDay === undefined ? !(is_holiday || date.day() === 0 || date.day() === 6) : businessDay.isBusinessDay

    return {
      date,
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
      day_of_the_week: date.format('ddd'),
      is_holiday,
      is_business_day,
      holiday_name: holiday?.holiday.name,
    }
  })

  return dateWithHoliday
}
