import { compact } from '@salescore/buff-common'
import type { ViewQueryRecordNode } from '@salescore/core'
import { GOAL_MODEL_NAME } from '@salescore/features'
import { organizationHasFeature } from '@salescore/frontend-common'
import { selector, useRecoilValue } from 'recoil'

import type { RSheetColumn } from '../../rsheet/types'
import { pickedIdsAtom } from '../records/atoms'
import { listQueryAtom, meAtom } from '../view/atoms'

const sheetSystemColumnsSelector = selector<Array<RSheetColumn<ViewQueryRecordNode>>>({
  key: `rsheet/sheetSystemColumnsSelector`,
  get({ get }) {
    const query = get(listQueryAtom)
    const pickedIds = get(pickedIdsAtom)
    const me = get(meAtom)
    const node = query.tree
    // NOTE: NX様専用フラグ(システムカラムからcheckboxのカラムを除外するために使用)、indexのずれによるデグレ等を嫌いwidthを0で実現
    const enableNxOnlyFeature = organizationHasFeature(me.organization, 'enable_nx_only_feature')
    const isGoalView = node.name === GOAL_MODEL_NAME

    // .compact()すると推論が微妙になる？
    const systemColumns: Array<RSheetColumn<ViewQueryRecordNode>> = compact([
      {
        index: 0,
        configIndex: undefined,
        isSystem: true,
        node,
        key: `rowIndex`,
        title: '',
        width: 28,
        type: `rowIndex`,
        value(record: ViewQueryRecordNode) {
          // empty
        },
      },
      {
        index: 1,
        configIndex: undefined,
        isSystem: true,
        node,
        key: `checkbox`,
        title: '',
        width: isGoalView || enableNxOnlyFeature ? 0 : 38,
        type: `rowCheckbox`,
        value(record: ViewQueryRecordNode) {
          if (record.id === undefined) {
            return false
          }
          return pickedIds.includes(record.id)
        },
      },
      {
        index: 2,
        configIndex: undefined,
        isSystem: true,
        node,
        key: `open`,
        title: '',
        width: isGoalView || enableNxOnlyFeature ? 0 : 38,
        type: `open`,
        value(record: ViewQueryRecordNode) {
          return record.id
        },
      },
    ])

    return systemColumns
  },
})

export const useSheetSystemColumns = () => useRecoilValue(sheetSystemColumnsSelector)
