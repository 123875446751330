import type { ViewRecordFieldChangeItem } from '@salescore/core'
import { generateEltChangesChunk } from '@salescore/core'
import { selector, useRecoilValue } from 'recoil'

import { mergeChanges } from '../../../domain/service/mergeChanges'
import { configAtom } from '../../view/atoms'
import { changesAtom } from '../atoms'

export type LatestFieldChangeItem = ViewRecordFieldChangeItem & {
  streamName: string
}

const changesSelector = selector({
  key: 'view/changesSelector',
  get({ get }) {
    const changes = get(changesAtom)
    const config = get(configAtom)
    const tree = config.type === 'sheet' ? config.tree : config.type === 'form' ? config.tree : undefined
    const latestFieldChangeArray = changes
      .flatMap((x) => x.changes)
      .flatMap((x) => x.fieldChanges.map((fc) => ({ ...fc, streamName: x.streamName })))
      .toReversed()
      .uniqueBy((x) => `${x.id},${x.streamName},${x.fieldName}`)
    const latestFieldChanges = new Map<string, Map<string, LatestFieldChangeItem>>()
    for (const fc of latestFieldChangeArray) {
      const existing = latestFieldChanges.get(fc.id)
      const key = `${fc.id},${fc.streamName},${fc.fieldName}`
      if (existing === undefined) {
        latestFieldChanges.set(fc.id, new Map([[key, fc]]))
      } else {
        existing.set(key, fc)
      }
    }

    function getLatestFieldChanges(rowRecordIds: string[]) {
      const latestFieldChangesRow = new Map<string, LatestFieldChangeItem>()
      for (const recordId of rowRecordIds) {
        const changes = latestFieldChanges.get(recordId)
        if (changes === undefined) {
          continue
        }
        for (const [key, value] of changes.entries()) {
          latestFieldChangesRow.set(key, value)
        }
      }
      return latestFieldChangesRow
    }

    return {
      changes,
      mergedChanges: mergeChanges(changes.flatMap((x) => x.changes)), // TODO: deprecated
      changesChunks: generateEltChangesChunk(
        changes.flatMap((x) => x.changes),
        tree,
      ),
      getLatestFieldChanges,
    }
  },
})

export const useChangesSelector = () => useRecoilValue(changesSelector)
