import { useRecoilValue } from 'recoil'

import { type Me, meAtom } from './atoms'

export const useMe = (): Me => {
  const me = useRecoilValue(meAtom)
  if (me === undefined) {
    throw new Error('useMe must be used after logged in')
  }
  return me
}

export function useOrganizationId(): string {
  const me = useRecoilValue(meAtom)
  if (me === undefined) {
    throw new Error('useOrganizationId must be used after logged in')
  }
  return me.myUser.organization.id
}
