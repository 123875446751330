import { useMutation } from '@apollo/client'
import { CreateFavoriteDocument, DeleteFavoriteDocument, type FavoriteCreateInput } from '@salescore/client-api'
import { message } from 'antd'
import { t } from 'i18next'

import { useOrganizationId } from '../../global/hooks'
import { useRefetchFavorites } from './refetchFavorites'

export const useCreateFavorite = () => {
  const [createFavoriteMutation] = useMutation(CreateFavoriteDocument)
  const organizationId = useOrganizationId()
  const refetchFavorites = useRefetchFavorites()

  return async (input: FavoriteCreateInput) => {
    try {
      const result = await createFavoriteMutation({
        variables: {
          organizationId,
          favorite: input,
        },
      })
      await refetchFavorites()
      return result.data?.createFavorite
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }
}

export const useDeleteFavorite = () => {
  const [deleteFavoriteMutation] = useMutation(DeleteFavoriteDocument)
  const organizationId = useOrganizationId()
  const refetchFavorites = useRefetchFavorites()

  return async (id: string) => {
    try {
      const result = await deleteFavoriteMutation({
        variables: {
          organizationId,
          id,
        },
      })
      await refetchFavorites()
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }
}
