import type { CoreModel } from '@salescore/core'
import type { ModelProperty as CoreModelProperty } from '@salescore/core'
import type { DimensionGroupProperty } from '@salescore/core'
import type { EltChangeResultsChunk } from '@salescore/core'
import type { EltChangesChunk } from '@salescore/core'
import type { PolicyAction } from '@salescore/features'
import type { PolicyPrincipal } from '@salescore/features'
import type { PolicySubject } from '@salescore/features'
import type { ValidationFunction } from '@salescore/buff-common'
import type { ViewConfig } from '@salescore/core'
import type { ViewQuery } from '@salescore/core'
import type { ViewQueryResult } from '@salescore/core'
import type { ViewUI } from '@salescore/core'
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** CoreModel custom scalar type */
  CoreModel: { input: CoreModel; output: CoreModel }
  /** CoreModelProperty custom scalar type */
  CoreModelProperty: { input: CoreModelProperty; output: CoreModelProperty }
  /** Date custom scalar type */
  Date: { input: string; output: string }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string }
  /** DimensionGroupProperty custom scalar type */
  DimensionGroupProperty: { input: DimensionGroupProperty; output: DimensionGroupProperty }
  /** EltChangeResultsChunk custom scalar type */
  EltChangeResultsChunk: { input: EltChangeResultsChunk; output: EltChangeResultsChunk }
  /** EltChangesChunk custom scalar type */
  EltChangesChunk: { input: EltChangesChunk; output: EltChangesChunk }
  /** JSON custom scalar type */
  JSON: { input: unknown; output: unknown }
  /** PolicyAction custom scalar type */
  PolicyAction: { input: PolicyAction; output: PolicyAction }
  /** PolicyPrincipal custom scalar type */
  PolicyPrincipal: { input: PolicyPrincipal; output: PolicyPrincipal }
  /** PolicySubject custom scalar type */
  PolicySubject: { input: PolicySubject; output: PolicySubject }
  /** ValidationFunction custom scalar type */
  ValidationFunction: { input: ValidationFunction; output: ValidationFunction }
  /** ViewConfig custom scalar type */
  ViewConfig: { input: ViewConfig; output: ViewConfig }
  /** ViewQuery custom scalar type */
  ViewQuery: { input: ViewQuery; output: ViewQuery }
  /** ViewQueryResult custom scalar type */
  ViewQueryResult: { input: ViewQueryResult; output: ViewQueryResult }
  /** ViewUI custom scalar type */
  ViewUI: { input: ViewUI; output: ViewUI }
}

export type AddSheetCustomModelPropertyInput = {
  currentViewConfigFieldProperties: Array<NodePropertyNameInput>
  label: Scalars['String']['input']
  meta?: InputMaybe<Scalars['String']['input']>
  nodeName: Scalars['String']['input']
  selectOptions?: InputMaybe<Scalars['JSON']['input']>
  type: Scalars['String']['input']
  viewId: Scalars['String']['input']
}

export type AddSheetCustomModelPropertyResponse = {
  __typename?: 'AddSheetCustomModelPropertyResponse'
  addedProperty: Scalars['CoreModelProperty']['output']
  sheetCustomModel: SheetCustomModel
}

export const AdminExecuteScriptMutationScriptNameEnum = {
  ImportSampleReports: 'importSampleReports',
} as const

export type AdminExecuteScriptMutationScriptNameEnum =
  (typeof AdminExecuteScriptMutationScriptNameEnum)[keyof typeof AdminExecuteScriptMutationScriptNameEnum]
export type AdminExtractCallingMetadataJobSettingInput = {
  concurrency?: InputMaybe<Scalars['Int']['input']>
  executedAt?: InputMaybe<Scalars['DateTime']['input']>
  promptForSpeechToText?: InputMaybe<Scalars['JSON']['input']>
  promptToExtractMetadata?: InputMaybe<Scalars['JSON']['input']>
  promptToRefineText?: InputMaybe<Scalars['JSON']['input']>
  targetEmails?: InputMaybe<Scalars['JSON']['input']>
  version: Scalars['String']['input']
}

export type AdminInvitationCreateInput = {
  email: Scalars['String']['input']
  organizationId: Scalars['String']['input']
  planForSync: UserPlanEnum
  planForVis: UserPlanEnum
  provider: IdentityProviderEnum
  role: Scalars['String']['input']
}

export type AdminInvitationInput = {
  id: Scalars['ID']['input']
  planForSync?: InputMaybe<UserPlanEnum>
  planForVis?: InputMaybe<UserPlanEnum>
  role?: InputMaybe<Scalars['String']['input']>
}

export type AdminOrganization = {
  __typename?: 'AdminOrganization'
  adminMemo: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  currentPlan: SubscriptionPlanEnum
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  invitations: Array<Invitation>
  isEarlyReleaseTarget: Scalars['Boolean']['output']
  jobQueues: Array<JobQueue>
  name: Scalars['String']['output']
  organizationFeatures: Array<OrganizationFeature>
  organizationPlans: Array<OrganizationPlan>
  organizationSetting: OrganizationSetting
  sources: Array<Source>
  status: OrganizationStatusEnum
  subscriptions: Array<SalescoreSubscription>
  updatedAt: Scalars['DateTime']['output']
  users: Array<V2User>
}

export type AdminOrganizationInput = {
  adminMemo: Scalars['String']['input']
  dataExtensionPlan?: InputMaybe<OrganizationPlanTypeEnum>
  name: Scalars['String']['input']
  status: OrganizationStatusEnum
}

export type AdminQueueJobInput = {
  delaySec?: InputMaybe<Scalars['Int']['input']>
  jobArg: Scalars['JSON']['input']
  jobName: Scalars['String']['input']
  organizationId?: InputMaybe<Scalars['String']['input']>
}

export type AdminUserInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  isManager?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
}

export type AllowedIp = {
  __typename?: 'AllowedIp'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  ip: Scalars['String']['output']
}

export type AllowedIpInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  ip: Scalars['String']['input']
}

export type AverageRenderDuration = {
  __typename?: 'AverageRenderDuration'
  averageDuration?: Maybe<Scalars['Int']['output']>
  isRecordsCached?: Maybe<Scalars['Boolean']['output']>
  sampleSize: Scalars['Int']['output']
  viewId: Scalars['String']['output']
}

export type BusinessDay = {
  __typename?: 'BusinessDay'
  date: Scalars['Date']['output']
  id: Scalars['ID']['output']
  isBusinessDay: Scalars['Boolean']['output']
}

export type BusinessDayInput = {
  date: Scalars['Date']['input']
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  isBusinessDay: Scalars['Boolean']['input']
}

export type ConfiguredCatalog = {
  __typename?: 'ConfiguredCatalog'
  streams: Array<Stream>
}

export type Connection = {
  __typename?: 'Connection'
  active: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  eltConfig: EltConfig
  eltModels: Array<EltModel>
  eltType: ConnectionEltTypeEnum
  id: Scalars['ID']['output']
  organizationId: Scalars['String']['output']
  source: Source
  sourceId: Scalars['String']['output']
  streams: Array<Stream>
  updatedAt: Scalars['DateTime']['output']
}

export const ConnectionEltTypeEnum = {
  Elt: 'elt',
  EltWithUserSources: 'eltWithUserSources',
  NoElt: 'noElt',
} as const

export type ConnectionEltTypeEnum = (typeof ConnectionEltTypeEnum)[keyof typeof ConnectionEltTypeEnum]
export type ConnectionForJobQueue = {
  __typename?: 'ConnectionForJobQueue'
  active: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  organizationId: Scalars['String']['output']
  sourceId: Scalars['String']['output']
  sourceProvider: SourceProviderEnum
}

export type ConnectionUpsertInput = {
  active: Scalars['Boolean']['input']
  organizationId: Scalars['ID']['input']
  sourceId: Scalars['ID']['input']
}

export type ConvertSalesforceLeadInput = {
  accountId?: InputMaybe<Scalars['String']['input']>
  accountName?: InputMaybe<Scalars['String']['input']>
  contactFirstName?: InputMaybe<Scalars['String']['input']>
  contactId?: InputMaybe<Scalars['String']['input']>
  contactLastName?: InputMaybe<Scalars['String']['input']>
  convertedStatus: Scalars['String']['input']
  doNotCreateOpportunity?: InputMaybe<Scalars['Boolean']['input']>
  leadId: Scalars['String']['input']
  opportunityName?: InputMaybe<Scalars['String']['input']>
  overwriteLeadSource?: InputMaybe<Scalars['Boolean']['input']>
  ownerId?: InputMaybe<Scalars['String']['input']>
  sendNotificationEmail?: InputMaybe<Scalars['Boolean']['input']>
}

export type ConvertSalesforceLeadResult = {
  __typename?: 'ConvertSalesforceLeadResult'
  accountId?: Maybe<Scalars['String']['output']>
  contactId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Scalars['String']['output']>
  leadId: Scalars['String']['output']
  opportunityId?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type ConvertingSalesforceLead = {
  __typename?: 'ConvertingSalesforceLead'
  accountName?: Maybe<Scalars['String']['output']>
  candidateAccounts: Array<ConvertingSalesforceLeadAccountCandidate>
  candidateContacts: Array<ConvertingSalesforceLeadContactCandidate>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  isConverted: Scalars['Boolean']['output']
  lastName?: Maybe<Scalars['String']['output']>
}

export type ConvertingSalesforceLeadAccountCandidate = {
  __typename?: 'ConvertingSalesforceLeadAccountCandidate'
  billingAddress?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  ownerName?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
}

export type ConvertingSalesforceLeadContactCandidate = {
  __typename?: 'ConvertingSalesforceLeadContactCandidate'
  accountName?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  ownerName?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type ConvertingSalesforceLeadRelated = {
  __typename?: 'ConvertingSalesforceLeadRelated'
  leadStatuses: Array<SalesforceLeadStatus>
  leads: Array<ConvertingSalesforceLead>
}

export type CreateViewInput = {
  config: Scalars['ViewConfig']['input']
  eltModelId?: InputMaybe<Scalars['String']['input']>
  emoji?: InputMaybe<Scalars['String']['input']>
  locked?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  private?: InputMaybe<Scalars['Boolean']['input']>
  viewGroupId?: InputMaybe<Scalars['String']['input']>
}

export type CrossTabulateQueriesResult = {
  __typename?: 'CrossTabulateQueriesResult'
  result: Scalars['JSON']['output']
}

export type Cursor = {
  __typename?: 'Cursor'
  lastSyncedAt?: Maybe<Scalars['String']['output']>
  streamName: Scalars['String']['output']
  transformType?: Maybe<EltModelTransformTypeEnum>
}

export type CustomRolePoliciesInput = {
  actionsWithPermission: Array<CustomRolePolicyInputActionsWithPermission>
  userRole: Scalars['String']['input']
}

export type CustomRolePolicyInputActionsWithPermission = {
  action: Scalars['PolicyAction']['input']
  permission: Scalars['Boolean']['input']
}

export type DashboardItemInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  kpiIds: Array<Scalars['String']['input']>
  name: Scalars['String']['input']
  rank: Scalars['Int']['input']
  shouldOrderByKpiIds: Scalars['Boolean']['input']
  userGroupIds: Array<Scalars['String']['input']>
}

export type DashboardItemV2 = {
  __typename?: 'DashboardItemV2'
  id: Scalars['ID']['output']
  kpis: Array<Kpi>
  name: Scalars['String']['output']
  queryParameterConfigs: Array<QueryParameterConfig>
  rank: Scalars['Int']['output']
  shouldOrderByKpiIds: Scalars['Boolean']['output']
  userGroups: Array<UserGroup>
}

export type DependentStream = {
  __typename?: 'DependentStream'
  sourceName: Scalars['String']['output']
  streamName: Scalars['String']['output']
}

export type DependentStreamInput = {
  sourceName: Scalars['String']['input']
  streamName: Scalars['String']['input']
}

export type Destination = {
  __typename?: 'Destination'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  provider: DestinationProviderEnum
  updatedAt: Scalars['DateTime']['output']
}

export const DestinationProviderEnum = {
  BigQuery: 'big_query',
  Postgres: 'postgres',
} as const

export type DestinationProviderEnum = (typeof DestinationProviderEnum)[keyof typeof DestinationProviderEnum]
export type DimensionGroup = {
  __typename?: 'DimensionGroup'
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  properties: Array<Scalars['DimensionGroupProperty']['output']>
}

export type DimensionGroupCreateInput = {
  label: Scalars['String']['input']
  properties: Scalars['JSON']['input']
}

export type DimensionGroupUpdateInput = {
  id: Scalars['ID']['input']
  label: Scalars['String']['input']
  properties: Scalars['JSON']['input']
}

export type EltConfig = {
  __typename?: 'EltConfig'
  sourceConfigs?: Maybe<Array<EltConfigSourceConfig>>
  sourceNamesWhitelist?: Maybe<Array<Scalars['String']['output']>>
}

export type EltConfigInput = {
  sourceConfigs?: InputMaybe<Array<EltConfigSourceConfigInput>>
  sourceNamesWhitelist?: InputMaybe<Array<Scalars['String']['input']>>
}

export type EltConfigSourceConfig = {
  __typename?: 'EltConfigSourceConfig'
  propertyNamesWhitelist?: Maybe<Array<Scalars['String']['output']>>
  sourceName: Scalars['String']['output']
}

export type EltConfigSourceConfigInput = {
  propertyNamesWhitelist?: InputMaybe<Array<Scalars['String']['input']>>
  sourceName: Scalars['String']['input']
}

export type EltModel = {
  __typename?: 'EltModel'
  connectionId?: Maybe<Scalars['String']['output']>
  eltModelChange?: Maybe<EltModelChange>
  eltModelError?: Maybe<EltModelError>
  extract?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  model: Scalars['CoreModel']['output']
  name: Scalars['String']['output']
  syncInvalidatedAt?: Maybe<Scalars['DateTime']['output']>
  syncedAt?: Maybe<Scalars['DateTime']['output']>
  transform?: Maybe<EltModelTransform>
  transformInvalidatedAt?: Maybe<Scalars['DateTime']['output']>
  transformedAt?: Maybe<Scalars['DateTime']['output']>
  type: EltModelTypeEnum
}

export type EltModelChange = {
  __typename?: 'EltModelChange'
  id: Scalars['ID']['output']
}

export type EltModelCreateInput = {
  connectionId: Scalars['String']['input']
  model: Scalars['CoreModel']['input']
  name: Scalars['String']['input']
  transform: EltModelTransformInput
  type: EltModelTypeEnum
}

export type EltModelError = {
  __typename?: 'EltModelError'
  id: Scalars['ID']['output']
}

export type EltModelTransform = {
  __typename?: 'EltModelTransform'
  sql: Scalars['String']['output']
  sqlToDelete?: Maybe<Scalars['String']['output']>
  type: EltModelTransformTypeEnum
}

export type EltModelTransformInput = {
  sql: Scalars['String']['input']
  type: EltModelTransformTypeEnum
}

export const EltModelTransformTypeEnum = {
  MaterializedView: 'materialized_view',
  Snapshot: 'snapshot',
  SnapshotSequence: 'snapshot_sequence',
  Table: 'table',
  View: 'view',
} as const

export type EltModelTransformTypeEnum = (typeof EltModelTransformTypeEnum)[keyof typeof EltModelTransformTypeEnum]
export const EltModelTypeEnum = {
  Source: 'source',
  System: 'system',
  UserCustomizedDerived: 'userCustomizedDerived',
  UserCustomizedSalescoreSource: 'userCustomizedSalescoreSource',
  UserCustomizedSnapshot: 'userCustomizedSnapshot',
  UserCustomizedSnapshotSequence: 'userCustomizedSnapshotSequence',
} as const

export type EltModelTypeEnum = (typeof EltModelTypeEnum)[keyof typeof EltModelTypeEnum]
export type EltModelUpdateInput = {
  connectionId: Scalars['String']['input']
  id: Scalars['ID']['input']
  model: Scalars['CoreModel']['input']
  transform: EltModelTransformInput
}

export type ExtractCallingMetadataJobSetting = {
  __typename?: 'ExtractCallingMetadataJobSetting'
  concurrency: Scalars['Int']['output']
  executedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  organizationId: Scalars['String']['output']
  promptForSpeechToText?: Maybe<Scalars['JSON']['output']>
  promptToExtractMetadata?: Maybe<Scalars['JSON']['output']>
  promptToRefineText?: Maybe<Scalars['JSON']['output']>
  targetEmails?: Maybe<Scalars['JSON']['output']>
  type: Scalars['String']['output']
  version: Scalars['String']['output']
}

export type Favorite = {
  __typename?: 'Favorite'
  id: Scalars['ID']['output']
  rank: Scalars['Int']['output']
  userId: Scalars['String']['output']
  viewGroupId?: Maybe<Scalars['String']['output']>
  viewId?: Maybe<Scalars['String']['output']>
}

export type FavoriteCreateInput = {
  viewGroupId?: InputMaybe<Scalars['String']['input']>
  viewId?: InputMaybe<Scalars['String']['input']>
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag'
  feature: Scalars['String']['output']
  organizationId: Scalars['ID']['output']
  organizationName: Scalars['String']['output']
}

export type GoalConfig = {
  __typename?: 'GoalConfig'
  config?: Maybe<Scalars['ViewConfig']['output']>
  createdBy?: Maybe<ViewCreatedBy>
  dashboardView?: Maybe<GoalConfigDashboardView>
  dashboardViewId?: Maybe<Scalars['String']['output']>
  goalDimension1?: Maybe<GoalDimension>
  goalDimension1Id?: Maybe<Scalars['String']['output']>
  goalDimension2?: Maybe<GoalDimension>
  goalDimension2Id?: Maybe<Scalars['String']['output']>
  goalDimension3?: Maybe<GoalDimension>
  goalDimension3Id?: Maybe<Scalars['String']['output']>
  goalDimension4?: Maybe<GoalDimension>
  goalDimension4Id?: Maybe<Scalars['String']['output']>
  goalDimension5?: Maybe<GoalDimension>
  goalDimension5Id?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  kpiViews: Array<GoalConfigKpiView>
  name: Scalars['String']['output']
  rank: Scalars['Int']['output']
  timeSpanType: GoalConfigTimeSpanTypeEnum
  userGroupIdsConfig: Array<Scalars['String']['output']>
  userIdsConfig: Array<Scalars['String']['output']>
  userOrUserGroupIds: Array<Scalars['String']['output']>
  userType: GoalConfigUserTypeEnum
}

export type GoalConfigCreateInput = {
  dashboardViewId: Scalars['String']['input']
  goalDimension1Id?: InputMaybe<Scalars['String']['input']>
  goalDimension2Id?: InputMaybe<Scalars['String']['input']>
  goalDimension3Id?: InputMaybe<Scalars['String']['input']>
  goalDimension4Id?: InputMaybe<Scalars['String']['input']>
  goalDimension5Id?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  timeSpanType: GoalConfigTimeSpanTypeEnum
  userGroupIdsConfig: Array<Scalars['String']['input']>
  userIdsConfig: Array<Scalars['String']['input']>
  userType: GoalConfigUserTypeEnum
}

export type GoalConfigDashboardView = {
  __typename?: 'GoalConfigDashboardView'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GoalConfigKpiView = {
  __typename?: 'GoalConfigKpiView'
  aggregationFunction: GoalConfigKpiViewAggregationFunctionType
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export const GoalConfigKpiViewAggregationFunctionType = {
  Avg: 'avg',
  Sum: 'sum',
} as const

export type GoalConfigKpiViewAggregationFunctionType =
  (typeof GoalConfigKpiViewAggregationFunctionType)[keyof typeof GoalConfigKpiViewAggregationFunctionType]
export const GoalConfigTimeSpanTypeEnum = {
  Day: 'day',
  Month: 'month',
  None: 'none',
  Week: 'week',
} as const

export type GoalConfigTimeSpanTypeEnum = (typeof GoalConfigTimeSpanTypeEnum)[keyof typeof GoalConfigTimeSpanTypeEnum]
export type GoalConfigUpdateInput = {
  config?: InputMaybe<Scalars['ViewConfig']['input']>
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  rank: Scalars['Int']['input']
  userGroupIdsConfig: Array<Scalars['String']['input']>
  userIdsConfig: Array<Scalars['String']['input']>
}

export const GoalConfigUserTypeEnum = {
  Group: 'group',
  None: 'none',
  User: 'user',
} as const

export type GoalConfigUserTypeEnum = (typeof GoalConfigUserTypeEnum)[keyof typeof GoalConfigUserTypeEnum]
export type GoalDimension = {
  __typename?: 'GoalDimension'
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  name: Scalars['String']['output']
  selectOptions: Array<Scalars['String']['output']>
  selectOptionsV3: Array<GoalDimensionSelectOption>
}

export type GoalDimensionInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  dimensionGroupIds?: InputMaybe<Array<Scalars['String']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  label: Scalars['String']['input']
  name: Scalars['String']['input']
  selectOptions: Array<GoalDimensionSelectOptionInput>
}

export type GoalDimensionSelectOption = {
  __typename?: 'GoalDimensionSelectOption'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type GoalDimensionSelectOptionInput = {
  label: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type HubRecord = {
  __typename?: 'HubRecord'
  data: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  streamName: Scalars['String']['output']
}

export type I18nSource = {
  __typename?: 'I18nSource'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  lang: LangEnum
  name?: Maybe<Scalars['String']['output']>
  organizationId: Scalars['String']['output']
  provider: SourceProviderEnum
  updatedAt: Scalars['DateTime']['output']
}

export type Identity = {
  __typename?: 'Identity'
  auth0UserId: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  provider: IdentityProviderEnum
  role: IdentityRoleEnum
  updatedAt: Scalars['DateTime']['output']
}

export type IdentityInput = {
  auth0UserId: Scalars['ID']['input']
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  oldId: Scalars['ID']['input']
  provider: Scalars['String']['input']
  role: Scalars['String']['input']
}

export const IdentityProviderEnum = {
  Auth0: 'auth0',
  GoogleOauth2: 'googleOauth2',
  Hubspot: 'hubspot',
  Salesforce: 'salesforce',
  Saml: 'saml',
  Zoho: 'zoho',
} as const

export type IdentityProviderEnum = (typeof IdentityProviderEnum)[keyof typeof IdentityProviderEnum]
export const IdentityRoleEnum = {
  Admin: 'admin',
  Default: 'default',
} as const

export type IdentityRoleEnum = (typeof IdentityRoleEnum)[keyof typeof IdentityRoleEnum]
export type Invitation = {
  __typename?: 'Invitation'
  createdAt: Scalars['DateTime']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  plan: UserPlanEnum
  planForSync: UserPlanEnum
  planForVis: UserPlanEnum
  provider: IdentityProviderEnum
  role: Scalars['String']['output']
}

export type InvitationInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  email: Scalars['String']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  planForSync?: InputMaybe<Scalars['Boolean']['input']>
  planForVis?: InputMaybe<Scalars['Boolean']['input']>
  provider: IdentityProviderEnum
  role: Scalars['String']['input']
}

export type Job = {
  __typename?: 'Job'
  connectionId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  logs?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  status: JobTypeEnum
}

export type JobQueue = {
  __typename?: 'JobQueue'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  jobArg: Scalars['JSON']['output']
  jobName: Scalars['String']['output']
  jobQueueLogs: Array<JobQueueLog>
  jobResult?: Maybe<Scalars['JSON']['output']>
  jobWorkerId?: Maybe<Scalars['String']['output']>
  key: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  organization?: Maybe<AdminOrganization>
  organizationId?: Maybe<Scalars['String']['output']>
  parentJobQueueId?: Maybe<Scalars['String']['output']>
  priority: Scalars['Int']['output']
  processId?: Maybe<Scalars['Int']['output']>
  scheduledAt: Scalars['DateTime']['output']
  startedAt?: Maybe<Scalars['DateTime']['output']>
  status: JobQueueStatusEnum
  stoppedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type JobQueueLog = {
  __typename?: 'JobQueueLog'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  jobQueueId: Scalars['String']['output']
  jobWorkerId: Scalars['String']['output']
  level: JobQueueLogLevelEnum
  message: Scalars['String']['output']
  tags: Array<Scalars['String']['output']>
}

export const JobQueueLogLevelEnum = {
  Debug: 'debug',
  Error: 'error',
  Info: 'info',
  Warn: 'warn',
} as const

export type JobQueueLogLevelEnum = (typeof JobQueueLogLevelEnum)[keyof typeof JobQueueLogLevelEnum]
export const JobQueueStatusEnum = {
  Completed: 'completed',
  Error: 'error',
  Killed: 'killed',
  Running: 'running',
  Waiting: 'waiting',
} as const

export type JobQueueStatusEnum = (typeof JobQueueStatusEnum)[keyof typeof JobQueueStatusEnum]
export const JobTypeEnum = {
  Created: 'created',
  Failed: 'failed',
  Retrying: 'retrying',
  Succeeded: 'succeeded',
} as const

export type JobTypeEnum = (typeof JobTypeEnum)[keyof typeof JobTypeEnum]
export type JobWorker = {
  __typename?: 'JobWorker'
  childProcessIds: Scalars['JSON']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  message?: Maybe<Scalars['String']['output']>
  processId: Scalars['Int']['output']
  status: JobWorkerStatusEnum
  stoppedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export const JobWorkerStatusEnum = {
  Error: 'error',
  Running: 'running',
  Stopped: 'stopped',
} as const

export type JobWorkerStatusEnum = (typeof JobWorkerStatusEnum)[keyof typeof JobWorkerStatusEnum]
export type JoinSlackBotToChannelMutationInput = {
  defaultChannelId?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['String']['input']
}

export type JoinSlackBotToChannelResponse = {
  __typename?: 'JoinSlackBotToChannelResponse'
  status: Scalars['String']['output']
}

export type Kpi = {
  __typename?: 'Kpi'
  abbreviation_type: KpiAbbreviationTypeEnum
  achievement_status_calculation_type: KpiAchievementStatusCalculationTypeEnum
  achievement_status_target_value_type: KpiAchievementStatusTargetValueTypeEnum
  description?: Maybe<Scalars['String']['output']>
  display_format?: Maybe<KpiDisplayFormatEnum>
  goal_type: KpiGoalTypeEnum
  icon?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  kind: KpiKindEnum
  name: Scalars['String']['output']
  rank: Scalars['Int']['output']
  time_dependent: Scalars['Boolean']['output']
  user_dependent: Scalars['Boolean']['output']
}

export const KpiAbbreviationTypeEnum = {
  AbbreviateMillion: 'abbreviate_million',
  AbbreviateTenThousand: 'abbreviate_ten_thousand',
  AbbreviateThousand: 'abbreviate_thousand',
  Automatic: 'automatic',
  NoAbbreviation: 'no_abbreviation',
} as const

export type KpiAbbreviationTypeEnum = (typeof KpiAbbreviationTypeEnum)[keyof typeof KpiAbbreviationTypeEnum]
export const KpiAchievementStatusCalculationTypeEnum = {
  GreaterThanOrEqual: 'greater_than_or_equal',
  LessThanOrEqual: 'less_than_or_equal',
} as const

export type KpiAchievementStatusCalculationTypeEnum =
  (typeof KpiAchievementStatusCalculationTypeEnum)[keyof typeof KpiAchievementStatusCalculationTypeEnum]
export const KpiAchievementStatusTargetValueTypeEnum = {
  AverageProgress: 'average_progress',
  Goal: 'goal',
  Zero: 'zero',
} as const

export type KpiAchievementStatusTargetValueTypeEnum =
  (typeof KpiAchievementStatusTargetValueTypeEnum)[keyof typeof KpiAchievementStatusTargetValueTypeEnum]
export const KpiDisplayFormatEnum = {
  Percent: 'percent',
  Yen: 'yen',
} as const

export type KpiDisplayFormatEnum = (typeof KpiDisplayFormatEnum)[keyof typeof KpiDisplayFormatEnum]
export const KpiGoalTypeEnum = {
  Amount: 'amount',
  Ratio: 'ratio',
} as const

export type KpiGoalTypeEnum = (typeof KpiGoalTypeEnum)[keyof typeof KpiGoalTypeEnum]
export type KpiInput = {
  abbreviation_type: KpiAbbreviationTypeEnum
  achievement_status_calculation_type: KpiAchievementStatusCalculationTypeEnum
  achievement_status_target_value_type: KpiAchievementStatusTargetValueTypeEnum
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  display_format?: InputMaybe<KpiDisplayFormatEnum>
  goal_type: KpiGoalTypeEnum
  icon?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  kind: KpiKindEnum
  name: Scalars['String']['input']
  rank: Scalars['Int']['input']
  report?: InputMaybe<ReportInput>
  time_dependent: Scalars['Boolean']['input']
  user_dependent: Scalars['Boolean']['input']
}

export const KpiKindEnum = {
  Action: 'action',
  Main: 'main',
  Process: 'process',
} as const

export type KpiKindEnum = (typeof KpiKindEnum)[keyof typeof KpiKindEnum]
export type KpiPivot = {
  columns: Scalars['JSON']['input']
  columnsForDrilldownComments?: InputMaybe<Scalars['JSON']['input']>
  dimensionsWithoutRollup?: InputMaybe<Scalars['JSON']['input']>
  rows: Scalars['JSON']['input']
}

export type KpiPivotParameter = {
  dimensionFilterLeafs?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
  goalConfig?: InputMaybe<Scalars['JSON']['input']>
  invisibleKpiIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  pivot: KpiPivot
  queryParameter?: InputMaybe<Scalars['JSON']['input']>
  sorter?: InputMaybe<Scalars['JSON']['input']>
  sorterForDrilldownComments?: InputMaybe<Scalars['JSON']['input']>
}

export const LangEnum = {
  En: 'en',
  Ja: 'ja',
} as const

export type LangEnum = (typeof LangEnum)[keyof typeof LangEnum]
export type MixedUser = {
  __typename?: 'MixedUser'
  id: Scalars['ID']['output']
  invitation?: Maybe<Invitation>
  resourceUser: ResourceUser
  user?: Maybe<V2User>
}

export type ModelProperty = {
  __typename?: 'ModelProperty'
  creatable?: Maybe<Scalars['Boolean']['output']>
  label: Scalars['String']['output']
  meta?: Maybe<PropertyMetaEnum>
  name: Scalars['String']['output']
  referenceTo?: Maybe<Array<PropertyReferenceTo>>
  selectOptions?: Maybe<Array<ModelPropertySelectOption>>
  trackable?: Maybe<Scalars['Boolean']['output']>
  type: PropertyTypeEnum
  updatable?: Maybe<Scalars['Boolean']['output']>
  validations?: Maybe<Array<ValidationRule>>
  write?: Maybe<ModelPropertyWrite>
}

export type ModelPropertySelectOption = {
  __typename?: 'ModelPropertySelectOption'
  color?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Scalars['String']['output']>
  label: Scalars['String']['output']
  rule?: Maybe<Scalars['ValidationFunction']['output']>
  value: Scalars['String']['output']
}

export type ModelPropertyWrite = {
  __typename?: 'ModelPropertyWrite'
  sourcePropertyName: Scalars['String']['output']
}

export type MoveViewGroupInput = {
  id: Scalars['ID']['input']
  private?: InputMaybe<Scalars['Boolean']['input']>
  rank: Scalars['Int']['input']
  viewGroupId?: InputMaybe<Scalars['String']['input']>
  viewGroupIdForPrivateKpis?: InputMaybe<Scalars['String']['input']>
}

export type MoveViewInput = {
  id: Scalars['ID']['input']
  private?: InputMaybe<Scalars['Boolean']['input']>
  rank: Scalars['Int']['input']
  viewGroupId?: InputMaybe<Scalars['String']['input']>
  viewGroupIdForPrivateKpis?: InputMaybe<Scalars['String']['input']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addSheetCustomModelPropertyV2: AddSheetCustomModelPropertyResponse
  adminCancelPgQuery: Scalars['Boolean']['output']
  adminClearJobs?: Maybe<Scalars['String']['output']>
  adminCreateInvitation?: Maybe<Invitation>
  adminCreateOrganization: AdminOrganization
  adminDeleteOrganization?: Maybe<AdminOrganization>
  adminExecuteScript: Scalars['String']['output']
  adminGrantAdminIdentity: Array<Scalars['Int']['output']>
  adminInvalidateEltModel?: Maybe<JobQueue>
  adminKillQueue?: Maybe<JobQueue>
  adminQueueJob?: Maybe<JobQueue>
  adminRestoreDeletedOrganization?: Maybe<AdminOrganization>
  adminRetryQueue?: Maybe<JobQueue>
  adminRevokeAdminIdentity: Array<Scalars['Int']['output']>
  adminUpdateEltModelFilter: Connection
  adminUpdateInvitation: Invitation
  adminUpdateOrganization?: Maybe<AdminOrganization>
  adminUpdateOrganizationFeatures: AdminOrganization
  adminUpdateOrganizationPlan: AdminOrganization
  adminUpdateQueue?: Maybe<JobQueue>
  adminUpdateSubscription?: Maybe<SalescoreSubscription>
  adminUpdateUser?: Maybe<V2User>
  adminUpdateUserLicense: V2User
  adminUpsertExtractCallingMetadataJobSetting: ExtractCallingMetadataJobSetting
  applyEltModelChange: Array<EltModel>
  archiveView?: Maybe<View>
  archiveViewGroup?: Maybe<ViewGroup>
  convertSalesforceLeads: Array<ConvertSalesforceLeadResult>
  copyView: View
  createDimensionGroup?: Maybe<DimensionGroup>
  createEltModel: EltModel
  createFavorite?: Maybe<Favorite>
  createGoalConfig: GoalConfig
  createIdentity?: Maybe<Identity>
  createKintoneUserSource?: Maybe<UserSource>
  createOauthI18nSource?: Maybe<I18nSource>
  createOauthSourceV2?: Maybe<Source>
  createOauthUserSource?: Maybe<UserSource>
  createPolicyUserRole: PolicyUserRole
  createReverseElt?: Maybe<ReverseElt>
  createSalescoreModel: EltModel
  createSharedUrl?: Maybe<SharedUrl>
  createSheetThreadComment: SheetThreadComment
  createSlackOrgIntegration: SlackOrgIntegration
  createSlackUserIntegration: SlackUserIntegration
  createSnapshotModel: EltModel
  createSource?: Maybe<Source>
  createView: View
  createViewGroup?: Maybe<ViewGroup>
  deleteDimensionGroup?: Maybe<DimensionGroup>
  deleteEltModel?: Maybe<EltModel>
  deleteFavorite?: Maybe<Favorite>
  deleteGoalConfig?: Maybe<GoalConfig>
  deleteI18nSource?: Maybe<I18nSource>
  deletePolicyUserRole: PolicyUserRole
  deleteReverseElt?: Maybe<ReverseElt>
  deleteSlackOrgIntegration: SlackOrgIntegration
  deleteSlackUserIntegration: SlackUserIntegration
  deleteSnapshotModel: EltModel
  deleteSource?: Maybe<Source>
  deleteUserSource?: Maybe<UserSource>
  deleteView?: Maybe<View>
  deleteViewGroup?: Maybe<ViewGroup>
  initializeGoals?: Maybe<GoalConfig>
  invalidateEltModelSync: EltModel
  joinSlackBotToChannel: JoinSlackBotToChannelResponse
  markSheetThreadCommentNotificationsAsRead: Scalars['Boolean']['output']
  moveViewGroups?: Maybe<Array<ViewGroup>>
  moveViews?: Maybe<Array<View>>
  queueImportSourceUsersQueue?: Maybe<JobQueue>
  queueReverseEltJob?: Maybe<JobQueue>
  recordRenderDuration?: Maybe<RenderDuration>
  replaceCustomRolePolicies: Array<Policy>
  replaceSubjectPolicies: Array<Policy>
  replaceViewPermissionUsers: Array<ViewPermissionUser>
  syncConnection?: Maybe<JobQueue>
  syncModels: Array<JobQueue>
  takeSnapshot: SnapshotSequenceModel
  updateDimensionGroup?: Maybe<DimensionGroup>
  updateEltConfig?: Maybe<Connection>
  updateEltModel?: Maybe<EltModel>
  updateGoalConfig?: Maybe<GoalConfig>
  updateMyUser: MyUser
  updateOrganizationV2: V2Organization
  updatePolicyUserRole: PolicyUserRole
  updateReverseElt: ReverseElt
  updateSalescoreModel: EltModel
  updateSheetCustomModelProperty: SheetCustomModel
  updateSheetThreadCommentNotification: SheetThreadCommentNotification
  updateSlackOrgIntegration: SlackOrgIntegration
  updateSlackUserIntegration: SlackUserIntegration
  updateSource?: Maybe<Source>
  updateUserGroupByCsv?: Maybe<UpdateUserGroupByCsvResponse>
  updateUserGroupIdsByCsv?: Maybe<UpdateUserGroupIdByCsvResponse>
  updateView: View
  updateViewGroups?: Maybe<Array<ViewGroup>>
  updateViews?: Maybe<Array<View>>
  upsertAllowedIps: Array<Maybe<AllowedIp>>
  upsertBusinessDay?: Maybe<BusinessDay>
  upsertConnection?: Maybe<Connection>
  upsertGoalDimension: Array<GoalDimension>
  upsertGoalsByCsvV2?: Maybe<UpsertGoalsByCsvV2Response>
  upsertInvitations: Array<Invitation>
  upsertUserGroups: Array<UserGroup>
  upsertUsersV2: Array<V2User>
  upsertViewGroups?: Maybe<Array<ViewGroup>>
  writeEltChangesChunk: Array<Scalars['EltChangeResultsChunk']['output']>
}

export type MutationAddSheetCustomModelPropertyV2Args = {
  input: AddSheetCustomModelPropertyInput
  organizationId: Scalars['ID']['input']
}

export type MutationAdminCancelPgQueryArgs = {
  pid: Scalars['Int']['input']
}

export type MutationAdminCreateInvitationArgs = {
  invitation: AdminInvitationCreateInput
}

export type MutationAdminCreateOrganizationArgs = {
  organization: AdminOrganizationInput
}

export type MutationAdminDeleteOrganizationArgs = {
  organizationId: Scalars['ID']['input']
}

export type MutationAdminExecuteScriptArgs = {
  organizationId: Scalars['ID']['input']
  scriptName: AdminExecuteScriptMutationScriptNameEnum
}

export type MutationAdminGrantAdminIdentityArgs = {
  identityId: Scalars['ID']['input']
}

export type MutationAdminInvalidateEltModelArgs = {
  id: Scalars['ID']['input']
  type: Scalars['String']['input']
}

export type MutationAdminKillQueueArgs = {
  id: Scalars['ID']['input']
}

export type MutationAdminQueueJobArgs = {
  data: AdminQueueJobInput
}

export type MutationAdminRestoreDeletedOrganizationArgs = {
  organizationId: Scalars['ID']['input']
}

export type MutationAdminRetryQueueArgs = {
  id: Scalars['ID']['input']
}

export type MutationAdminRevokeAdminIdentityArgs = {
  identityId: Scalars['ID']['input']
}

export type MutationAdminUpdateEltModelFilterArgs = {
  filters: Array<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type MutationAdminUpdateInvitationArgs = {
  invitation: AdminInvitationInput
}

export type MutationAdminUpdateOrganizationArgs = {
  organization: AdminOrganizationInput
  organizationId: Scalars['ID']['input']
}

export type MutationAdminUpdateOrganizationFeaturesArgs = {
  features: Array<Scalars['String']['input']>
  organizationId: Scalars['ID']['input']
}

export type MutationAdminUpdateOrganizationPlanArgs = {
  license: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
  plan?: InputMaybe<OrganizationPlanTypeEnum>
}

export type MutationAdminUpdateQueueArgs = {
  id: Scalars['ID']['input']
  priority?: InputMaybe<Scalars['Int']['input']>
}

export type MutationAdminUpdateSubscriptionArgs = {
  organizationId: Scalars['ID']['input']
  subscription: SubscriptionInput
}

export type MutationAdminUpdateUserArgs = {
  user: AdminUserInput
}

export type MutationAdminUpdateUserLicenseArgs = {
  license: UserLicenseTypeEnum
  plan?: InputMaybe<UserPlanEnum>
  userId: Scalars['ID']['input']
}

export type MutationAdminUpsertExtractCallingMetadataJobSettingArgs = {
  organizationId: Scalars['ID']['input']
  setting: AdminExtractCallingMetadataJobSettingInput
}

export type MutationApplyEltModelChangeArgs = {
  eltModelChangeIds: Array<Scalars['ID']['input']>
  organizationId: Scalars['ID']['input']
}

export type MutationArchiveViewArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationArchiveViewGroupArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationConvertSalesforceLeadsArgs = {
  inputs: Array<ConvertSalesforceLeadInput>
  organizationId: Scalars['ID']['input']
}

export type MutationCopyViewArgs = {
  id: Scalars['ID']['input']
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['ID']['input']
  viewGroupId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationCreateDimensionGroupArgs = {
  dimensionGroup: DimensionGroupCreateInput
  organizationId: Scalars['ID']['input']
}

export type MutationCreateEltModelArgs = {
  eltModel: EltModelCreateInput
  organizationId: Scalars['ID']['input']
}

export type MutationCreateFavoriteArgs = {
  favorite: FavoriteCreateInput
  organizationId: Scalars['ID']['input']
}

export type MutationCreateGoalConfigArgs = {
  goalConfig: GoalConfigCreateInput
  organizationId: Scalars['ID']['input']
}

export type MutationCreateKintoneUserSourceArgs = {
  kintoneId: Scalars['String']['input']
  kintonePassword: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
  sourceId: Scalars['ID']['input']
}

export type MutationCreateOauthI18nSourceArgs = {
  data: OauthI18nSourceInput
}

export type MutationCreateOauthSourceV2Args = {
  data: OauthSourceInput
}

export type MutationCreateOauthUserSourceArgs = {
  data: OauthUserSourceInput
}

export type MutationCreatePolicyUserRoleArgs = {
  organizationId: Scalars['ID']['input']
  policyUserRole: PolicyUserRoleCreateInput
}

export type MutationCreateReverseEltArgs = {
  organizationId: Scalars['ID']['input']
  reverseElt: ReverseEltCreateInput
}

export type MutationCreateSalescoreModelArgs = {
  modelName: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationCreateSharedUrlArgs = {
  organizationId: Scalars['ID']['input']
  sharedUrl: SharedUrlCreateInput
}

export type MutationCreateSheetThreadCommentArgs = {
  data: SheetThreadCommentCreateInput
}

export type MutationCreateSlackOrgIntegrationArgs = {
  data: SlackOrgIntegrationCreateInput
}

export type MutationCreateSlackUserIntegrationArgs = {
  data: SlackUserIntegrationCreateInput
}

export type MutationCreateSnapshotModelArgs = {
  organizationId: Scalars['ID']['input']
  snapshotModel: SnapshotModelCreateInput
}

export type MutationCreateSourceArgs = {
  data: SourceCreateInput
}

export type MutationCreateViewArgs = {
  input: CreateViewInput
  organizationId: Scalars['ID']['input']
}

export type MutationCreateViewGroupArgs = {
  input: ViewGroupCreateInput
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteDimensionGroupArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteEltModelArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteFavoriteArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteGoalConfigArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteI18nSourceArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationDeletePolicyUserRoleArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteReverseEltArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteSlackOrgIntegrationArgs = {
  data: SlackOrgIntegrationDeleteInput
}

export type MutationDeleteSlackUserIntegrationArgs = {
  data: SlackUserIntegrationDeleteInput
}

export type MutationDeleteSnapshotModelArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteSourceArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteUserSourceArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteViewArgs = {
  id: Scalars['ID']['input']
  ignoreLocked?: InputMaybe<Scalars['Boolean']['input']>
  organizationId: Scalars['ID']['input']
}

export type MutationDeleteViewGroupArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationInitializeGoalsArgs = {
  dates: Array<Scalars['Date']['input']>
  goalConfigId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationInvalidateEltModelSyncArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationJoinSlackBotToChannelArgs = {
  data: JoinSlackBotToChannelMutationInput
}

export type MutationMarkSheetThreadCommentNotificationsAsReadArgs = {
  commentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  organizationId: Scalars['ID']['input']
  recordId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationMoveViewGroupsArgs = {
  inputs: Array<MoveViewGroupInput>
  organizationId: Scalars['ID']['input']
}

export type MutationMoveViewsArgs = {
  inputs: Array<MoveViewInput>
  organizationId: Scalars['ID']['input']
}

export type MutationQueueImportSourceUsersQueueArgs = {
  organizationId: Scalars['ID']['input']
}

export type MutationQueueReverseEltJobArgs = {
  organizationId: Scalars['ID']['input']
  reverseEltId: Scalars['ID']['input']
  syncMode: SyncModeEnum
}

export type MutationRecordRenderDurationArgs = {
  organizationId: Scalars['ID']['input']
  renderDurationInput: RenderDurationInput
}

export type MutationReplaceCustomRolePoliciesArgs = {
  customRolePolicies: CustomRolePoliciesInput
  organizationId: Scalars['ID']['input']
}

export type MutationReplaceSubjectPoliciesArgs = {
  organizationId: Scalars['ID']['input']
  subjectPolicies: Array<SubjectPoliciesReplaceInput>
}

export type MutationReplaceViewPermissionUsersArgs = {
  userIds: Array<Scalars['ID']['input']>
  viewId: Scalars['ID']['input']
}

export type MutationSyncConnectionArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  syncContext: SyncContextEnum
}

export type MutationSyncModelsArgs = {
  connectionModels: Array<SyncModelsInput>
  organizationId: Scalars['ID']['input']
  syncContext: SyncContextEnum
}

export type MutationTakeSnapshotArgs = {
  organizationId: Scalars['ID']['input']
  snapshotModelId: Scalars['ID']['input']
  timestamp?: InputMaybe<Scalars['DateTime']['input']>
}

export type MutationUpdateDimensionGroupArgs = {
  dimensionGroup: DimensionGroupUpdateInput
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateEltConfigArgs = {
  eltConfig: EltConfigInput
  organizationId: Scalars['ID']['input']
  sourceId: Scalars['ID']['input']
}

export type MutationUpdateEltModelArgs = {
  eltModel: EltModelUpdateInput
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateGoalConfigArgs = {
  goalConfig: GoalConfigUpdateInput
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateMyUserArgs = {
  myUser: MyUserInput
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateOrganizationV2Args = {
  organization: OrganizationInput
  organizationId: Scalars['ID']['input']
}

export type MutationUpdatePolicyUserRoleArgs = {
  organizationId: Scalars['ID']['input']
  policyUserRole: PolicyUserRoleUpdateInput
}

export type MutationUpdateReverseEltArgs = {
  organizationId: Scalars['ID']['input']
  reverseElt: ReverseEltUpdateInput
}

export type MutationUpdateSalescoreModelArgs = {
  column: Scalars['JSON']['input']
  modelName: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateSheetCustomModelPropertyArgs = {
  input: UpdateSheetCustomModelPropertyInput
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateSheetThreadCommentNotificationArgs = {
  data: SheetThreadCommentNotificationUpdateInput
}

export type MutationUpdateSlackOrgIntegrationArgs = {
  data: SlackOrgIntegrationUpdateInput
}

export type MutationUpdateSlackUserIntegrationArgs = {
  data: SlackUserIntegrationUpdateInput
}

export type MutationUpdateSourceArgs = {
  config?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateUserGroupByCsvArgs = {
  csv: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateUserGroupIdsByCsvArgs = {
  csv: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateViewArgs = {
  input: ViewUpdateInputSingle
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateViewGroupsArgs = {
  inputs: Array<ViewGroupUpdateInput>
  organizationId: Scalars['ID']['input']
}

export type MutationUpdateViewsArgs = {
  inputs: Array<ViewUpdateInput>
  organizationId: Scalars['ID']['input']
}

export type MutationUpsertAllowedIpsArgs = {
  allowedIps: Array<AllowedIpInput>
  organizationId: Scalars['ID']['input']
}

export type MutationUpsertBusinessDayArgs = {
  businessDay: BusinessDayInput
  organizationId: Scalars['ID']['input']
}

export type MutationUpsertConnectionArgs = {
  data: ConnectionUpsertInput
}

export type MutationUpsertGoalDimensionArgs = {
  goalDimensions: Array<GoalDimensionInput>
  organizationId: Scalars['ID']['input']
}

export type MutationUpsertGoalsByCsvV2Args = {
  csv: Scalars['String']['input']
  goalConfigId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type MutationUpsertInvitationsArgs = {
  invitations: Array<InvitationInput>
  organizationId: Scalars['ID']['input']
}

export type MutationUpsertUserGroupsArgs = {
  organizationId: Scalars['ID']['input']
  userGroups: Array<UserGroupInput>
}

export type MutationUpsertUsersV2Args = {
  organizationId: Scalars['ID']['input']
  users: Array<UserInputV2>
}

export type MutationUpsertViewGroupsArgs = {
  organizationId: Scalars['ID']['input']
  viewGroups: Array<ViewGroupInput>
}

export type MutationWriteEltChangesChunkArgs = {
  changesChunks: Array<Scalars['EltChangesChunk']['input']>
  organizationId: Scalars['ID']['input']
  viewId?: InputMaybe<Scalars['ID']['input']>
}

export type MyActionInput = {
  body?: InputMaybe<Scalars['String']['input']>
  chance_count: Scalars['Int']['input']
  count: Scalars['Int']['input']
  deleted_at?: InputMaybe<Scalars['Date']['input']>
  end_at: Scalars['Date']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  kpi_id: Scalars['String']['input']
  name: Scalars['String']['input']
  review?: InputMaybe<Scalars['String']['input']>
  start_at: Scalars['Date']['input']
  status: Scalars['String']['input']
}

export const MyActionStatusEnumV2 = {
  Doing: 'doing',
  Failure: 'failure',
  NoEffect: 'no_effect',
  Review: 'review',
  Success: 'success',
} as const

export type MyActionStatusEnumV2 = (typeof MyActionStatusEnumV2)[keyof typeof MyActionStatusEnumV2]
export type MyActionV2 = {
  __typename?: 'MyActionV2'
  body?: Maybe<Scalars['String']['output']>
  chance_count: Scalars['Int']['output']
  count: Scalars['Int']['output']
  end_at: Scalars['Date']['output']
  id: Scalars['ID']['output']
  kpi?: Maybe<Kpi>
  kpi_id?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  review?: Maybe<Scalars['String']['output']>
  start_at: Scalars['Date']['output']
  status: MyActionStatusEnumV2
  user?: Maybe<ResourceUser>
  user_id?: Maybe<Scalars['String']['output']>
}

export type MyIdentity = {
  __typename?: 'MyIdentity'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  users: Array<MyUser>
}

export type MyUser = {
  __typename?: 'MyUser'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  identity: MyIdentity
  identityId: Scalars['String']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  lang?: Maybe<LangEnum>
  name: Scalars['String']['output']
  organization: V2Organization
  plan: UserPlanEnum
  resourceUser?: Maybe<ResourceUser>
  role: Scalars['String']['output']
  userLicenses: Array<UserLicense>
}

export type MyUserInput = {
  imageUrl?: InputMaybe<Scalars['String']['input']>
  lang?: InputMaybe<LangEnum>
  name?: InputMaybe<Scalars['String']['input']>
}

export type NodePropertyNameInput = {
  modelName: Scalars['String']['input']
  nodeName: Scalars['String']['input']
  propertyName: Scalars['String']['input']
}

export const NotificationStatusEnum = {
  NotRead: 'notRead',
  Read: 'read',
} as const

export type NotificationStatusEnum = (typeof NotificationStatusEnum)[keyof typeof NotificationStatusEnum]
export const NotificationSubscriptionModeEnum = {
  All: 'all',
  Mention: 'mention',
  Off: 'off',
} as const

export type NotificationSubscriptionModeEnum =
  (typeof NotificationSubscriptionModeEnum)[keyof typeof NotificationSubscriptionModeEnum]
export type OauthI18nSourceInput = {
  code: Scalars['String']['input']
  lang: LangEnum
  name?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['String']['input']
  sourceId: Scalars['String']['input']
  state: Scalars['String']['input']
}

export type OauthSourceInput = {
  clientId?: InputMaybe<Scalars['String']['input']>
  clientSecret?: InputMaybe<Scalars['String']['input']>
  code: Scalars['String']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['String']['input']
  provider: SourceProviderEnum
  sourceConfig?: InputMaybe<Scalars['JSON']['input']>
  state: Scalars['String']['input']
  tokenUrl?: InputMaybe<Scalars['String']['input']>
}

export type OauthUserSourceInput = {
  clientId?: InputMaybe<Scalars['String']['input']>
  clientSecret?: InputMaybe<Scalars['String']['input']>
  code: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['String']['input']
  provider: SourceProviderEnum
  sourceConfig?: InputMaybe<Scalars['JSON']['input']>
  sourceId: Scalars['String']['input']
  state: Scalars['String']['input']
  tokenUrl?: InputMaybe<Scalars['String']['input']>
}

export type OrganizationCreateInput = {
  id: Scalars['ID']['input']
  imageUrl?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type OrganizationFeature = {
  __typename?: 'OrganizationFeature'
  feature: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type OrganizationInput = {
  imageUrl?: InputMaybe<Scalars['String']['input']>
  setting?: InputMaybe<OrganizationSettingInput>
}

export type OrganizationPlan = {
  __typename?: 'OrganizationPlan'
  license: Scalars['String']['output']
  organizationId: Scalars['ID']['output']
  plan: OrganizationPlanTypeEnum
}

export const OrganizationPlanTypeEnum = {
  Free: 'free',
  Standard: 'standard',
} as const

export type OrganizationPlanTypeEnum = (typeof OrganizationPlanTypeEnum)[keyof typeof OrganizationPlanTypeEnum]
export type OrganizationSetting = {
  __typename?: 'OrganizationSetting'
  accountClosingMonth: Scalars['Int']['output']
  csvDownloadEnabledForMember: Scalars['Boolean']['output']
  defaultOpenViewIds: Array<Scalars['String']['output']>
  disableEditUsername: Scalars['Boolean']['output']
  disableOldVisualize: Scalars['Boolean']['output']
  i18nEnabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  miitelAccessKey?: Maybe<Scalars['String']['output']>
  miitelCompanyId?: Maybe<Scalars['String']['output']>
  monthBeginningDay: Scalars['Int']['output']
  shouldCreateSalesforceOpportunityWhenConvertingLead: Scalars['Boolean']['output']
  shouldIgnoreInactiveSourceUser: Scalars['Boolean']['output']
  shouldJoinRecordsInApplication: Scalars['Boolean']['output']
  shouldWarmUpCache: Scalars['Boolean']['output']
  userGroupCategoryNames: Array<Scalars['String']['output']>
  viewSiderVisibility: Scalars['Boolean']['output']
}

export type OrganizationSettingInput = {
  accountClosingMonth?: InputMaybe<Scalars['Int']['input']>
  csvDownloadEnabledForMember?: InputMaybe<Scalars['Boolean']['input']>
  defaultOpenViewIds?: InputMaybe<Array<Scalars['String']['input']>>
  disableEditUsername?: InputMaybe<Scalars['Boolean']['input']>
  i18nEnabled?: InputMaybe<Scalars['Boolean']['input']>
  monthBeginningDay?: InputMaybe<Scalars['Int']['input']>
  shouldCreateSalesforceOpportunityWhenConvertingLead?: InputMaybe<Scalars['Boolean']['input']>
  shouldIgnoreInactiveSourceUser?: InputMaybe<Scalars['Boolean']['input']>
  shouldJoinRecordsInApplication?: InputMaybe<Scalars['Boolean']['input']>
  shouldWarmUpCache?: InputMaybe<Scalars['Boolean']['input']>
  userGroupCategoryNames?: InputMaybe<Array<Scalars['String']['input']>>
  viewSiderVisibility?: InputMaybe<Scalars['Boolean']['input']>
}

export const OrganizationStatusEnum = {
  Demo: 'demo',
  Free: 'free',
  Onboarding: 'onboarding',
  Suspended: 'suspended',
  Trial: 'trial',
  Using: 'using',
} as const

export type OrganizationStatusEnum = (typeof OrganizationStatusEnum)[keyof typeof OrganizationStatusEnum]
export type Period = {
  __typename?: 'Period'
  endAt?: Maybe<Scalars['Date']['output']>
  name: Scalars['String']['output']
  startAt?: Maybe<Scalars['Date']['output']>
}

export type PeriodInput = {
  endAt?: InputMaybe<Scalars['Date']['input']>
  name: Scalars['String']['input']
  startAt?: InputMaybe<Scalars['Date']['input']>
}

export const PermissionActionEnum = {
  Full: 'full',
  None: 'none',
  Read: 'read',
} as const

export type PermissionActionEnum = (typeof PermissionActionEnum)[keyof typeof PermissionActionEnum]
export type Policy = {
  __typename?: 'Policy'
  action: Scalars['PolicyAction']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  permission: Scalars['Boolean']['output']
  principal: Scalars['PolicyPrincipal']['output']
  subject?: Maybe<Scalars['PolicySubject']['output']>
}

export type PolicyUserRole = {
  __typename?: 'PolicyUserRole'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  organizationId?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  updatedById?: Maybe<Scalars['String']['output']>
}

export type PolicyUserRoleCreateInput = {
  description: Scalars['String']['input']
  label: Scalars['String']['input']
}

export type PolicyUserRoleUpdateInput = {
  description: Scalars['String']['input']
  id: Scalars['String']['input']
  label: Scalars['String']['input']
}

export type PostgresQueryResult = {
  __typename?: 'PostgresQueryResult'
  fields?: Maybe<Array<PostgresQueryResultField>>
  rowCount?: Maybe<Scalars['Int']['output']>
  rows?: Maybe<Scalars['JSON']['output']>
}

export type PostgresQueryResultField = {
  __typename?: 'PostgresQueryResultField'
  name: Scalars['String']['output']
}

export type PostgresStats = {
  __typename?: 'PostgresStats'
  application_name?: Maybe<Scalars['String']['output']>
  backend_start?: Maybe<Scalars['String']['output']>
  backend_type?: Maybe<Scalars['String']['output']>
  backend_xid?: Maybe<Scalars['String']['output']>
  backend_xmin?: Maybe<Scalars['String']['output']>
  client_addr?: Maybe<Scalars['String']['output']>
  client_hostname?: Maybe<Scalars['String']['output']>
  client_port?: Maybe<Scalars['Int']['output']>
  datid?: Maybe<Scalars['String']['output']>
  datname?: Maybe<Scalars['String']['output']>
  leader_pid?: Maybe<Scalars['Int']['output']>
  pid: Scalars['Int']['output']
  query?: Maybe<Scalars['String']['output']>
  query_start?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  state_change?: Maybe<Scalars['String']['output']>
  usename?: Maybe<Scalars['String']['output']>
  usesysid?: Maybe<Scalars['String']['output']>
  wait_event?: Maybe<Scalars['String']['output']>
  wait_event_type?: Maybe<Scalars['String']['output']>
  xact_start?: Maybe<Scalars['String']['output']>
}

export type PostgresStatus = {
  __typename?: 'PostgresStatus'
  datetime: Scalars['DateTime']['output']
  stats: Array<PostgresStats>
}

export type PostgresTableColumn = {
  __typename?: 'PostgresTableColumn'
  column_label?: Maybe<Scalars['String']['output']>
  column_name: Scalars['String']['output']
  data_type: Scalars['String']['output']
  table_label?: Maybe<Scalars['String']['output']>
  table_name: Scalars['String']['output']
  table_schema: Scalars['String']['output']
}

export type Property = {
  __typename?: 'Property'
  customized?: Maybe<Scalars['Boolean']['output']>
  destination: PropertyDestination
  meta: PropertyMeta
  name: Scalars['String']['output']
  source?: Maybe<PropertySource>
}

export type PropertyDestination = {
  __typename?: 'PropertyDestination'
  name: Scalars['String']['output']
  sql: Scalars['String']['output']
  type: PropertyTypeEnum
}

export type PropertyDestinationInput = {
  name: Scalars['String']['input']
  sql: Scalars['String']['input']
  type: PropertyTypeEnum
}

export type PropertyInput = {
  customized?: InputMaybe<Scalars['Boolean']['input']>
  destination: PropertyDestinationInput
  meta: PropertyMetaInput
  name: Scalars['String']['input']
  source?: InputMaybe<PropertySourceInput>
}

export type PropertyMeta = {
  __typename?: 'PropertyMeta'
  creatable?: Maybe<Scalars['Boolean']['output']>
  label: Scalars['String']['output']
  meta?: Maybe<PropertyMetaEnum>
  referenceTo?: Maybe<Array<Scalars['String']['output']>>
  selectOptions?: Maybe<Array<PropertySelectOption>>
  typeForMigration?: Maybe<PropertyTypeForMigrationEnum>
  updatable?: Maybe<Scalars['Boolean']['output']>
  validations?: Maybe<Array<ValidationRule>>
}

export const PropertyMetaEnum = {
  Address: 'address',
  Currency: 'currency',
  Email: 'email',
  Html: 'html',
  Id: 'id',
  MultiSelect: 'multi_select',
  Name: 'name',
  Percent: 'percent',
  PhoneNumber: 'phone_number',
  PolymorphicType: 'polymorphic_type',
  RecordUrl: 'record_url',
  Relation: 'relation',
  Select: 'select',
  Text: 'text',
  Url: 'url',
} as const

export type PropertyMetaEnum = (typeof PropertyMetaEnum)[keyof typeof PropertyMetaEnum]
export type PropertyMetaInput = {
  creatable?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  label: Scalars['String']['input']
  meta?: InputMaybe<PropertyMetaEnum>
  referenceTo?: InputMaybe<Array<Scalars['String']['input']>>
  selectOptions?: InputMaybe<Array<PropertySelectOptionInput>>
  trackable?: InputMaybe<Scalars['Boolean']['input']>
  typeForMigration?: InputMaybe<PropertyTypeForMigrationEnum>
  updatable?: InputMaybe<Scalars['Boolean']['input']>
  validations?: InputMaybe<Scalars['JSON']['input']>
}

export type PropertyNameWithStreamName = {
  __typename?: 'PropertyNameWithStreamName'
  propertyName: Scalars['String']['output']
  streamName: Scalars['String']['output']
}

export type PropertyReferenceTo = {
  __typename?: 'PropertyReferenceTo'
  key?: Maybe<Scalars['String']['output']>
  modelName: Scalars['String']['output']
}

export type PropertySelectOption = {
  __typename?: 'PropertySelectOption'
  color?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Scalars['String']['output']>
  label: Scalars['String']['output']
  rule?: Maybe<Scalars['ValidationFunction']['output']>
  value: Scalars['String']['output']
}

export type PropertySelectOptionInput = {
  color?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<Scalars['String']['input']>
  label: Scalars['String']['input']
  rule?: InputMaybe<Scalars['JSON']['input']>
  value: Scalars['JSON']['input']
}

export type PropertySource = {
  __typename?: 'PropertySource'
  meta?: Maybe<PropertySourceMeta>
  name: Scalars['String']['output']
}

export type PropertySourceInput = {
  meta?: InputMaybe<PropertySourceMetaInput>
  name: Scalars['String']['input']
}

export type PropertySourceMeta = {
  __typename?: 'PropertySourceMeta'
  meta?: Maybe<Scalars['String']['output']>
  referenceTo?: Maybe<Array<Scalars['String']['output']>>
  type?: Maybe<Scalars['String']['output']>
}

export type PropertySourceMetaInput = {
  meta?: InputMaybe<Scalars['String']['input']>
  referenceTo?: InputMaybe<Array<Scalars['String']['input']>>
  type?: InputMaybe<Scalars['String']['input']>
}

export const PropertyTypeEnum = {
  Array: 'array',
  Boolean: 'boolean',
  Date: 'date',
  Datetime: 'datetime',
  Integer: 'integer',
  Numeric: 'numeric',
  Object: 'object',
  String: 'string',
  Time: 'time',
} as const

export type PropertyTypeEnum = (typeof PropertyTypeEnum)[keyof typeof PropertyTypeEnum]
export const PropertyTypeForMigrationEnum = {
  SelectTypeWithConvertedValue: 'selectTypeWithConvertedValue',
  SelectTypeWithRawValue: 'selectTypeWithRawValue',
} as const

export type PropertyTypeForMigrationEnum =
  (typeof PropertyTypeForMigrationEnum)[keyof typeof PropertyTypeForMigrationEnum]
export type Query = {
  __typename?: 'Query'
  adminConnections: Array<ConnectionForJobQueue>
  adminEltModels: Array<EltModel>
  adminExtractCallingMetadataJobSetting?: Maybe<ExtractCallingMetadataJobSetting>
  adminFeatureFlags: Array<FeatureFlag>
  adminIdentities: Array<Identity>
  adminIdentityCandidates: Array<Identity>
  adminJobQueue: JobQueue
  adminJobQueues: Array<JobQueue>
  adminJobWorkers: Array<JobWorker>
  adminOrganization?: Maybe<AdminOrganization>
  adminOrganizations: Array<AdminOrganization>
  adminPostgresStatus: PostgresStatus
  adminSoqlResult: Array<Scalars['JSON']['output']>
  adminSpeechToTextResult: SpeechToTextResult
  adminSqlResult: PostgresQueryResult
  adminTableColumns: Array<PostgresTableColumn>
  allowedIps: Array<AllowedIp>
  averageRenderDuration: Array<AverageRenderDuration>
  businessDays: Array<BusinessDay>
  connections: Array<Connection>
  convertingSalesforceLeads: ConvertingSalesforceLeadRelated
  destinations: Array<Destination>
  dimensionGroups: Array<DimensionGroup>
  eltModel?: Maybe<EltModel>
  eltModels: Array<EltModel>
  favorites: Array<Favorite>
  goalConfigs: Array<GoalConfig>
  goalDimensions: Array<GoalDimension>
  i18nSources: Array<I18nSource>
  invitations: Array<Invitation>
  jobProcessingQueues: Array<JobQueue>
  jobQueue: JobQueue
  jobQueues: Array<JobQueue>
  jobStatuses: Array<JobQueue>
  mixedUsers: Array<MixedUser>
  models: Array<Scalars['CoreModel']['output']>
  myIdentity?: Maybe<MyIdentity>
  myUser: MyUser
  oauthAuthorizationUrl: Scalars['String']['output']
  organization: V2Organization
  policies: Array<Policy>
  policyUserRoles: Array<PolicyUserRole>
  propertiesBoilerplate: Array<Scalars['CoreModelProperty']['output']>
  queryResults: Array<QueryResult>
  recordChangeHistories: Array<RecordChangeHistory>
  resourceUsersWithGroup: Array<ResourceUserWithGroup>
  reverseElts: Array<ReverseElt>
  s3SignedUrl: Scalars['String']['output']
  salescoreConnection: Connection
  salescoreJwt: Scalars['String']['output']
  samlConfigs: Array<SamlConfig>
  sharedUrl?: Maybe<SharedUrl>
  sheetCustomModels: Array<SheetCustomModel>
  sheetThreadCommentNotifications: Array<SheetThreadCommentNotification>
  sheetThreads: Array<SheetThread>
  slackBotJoinedChannel: SlackBotJoinedChannel
  slackChannels: Array<SlackChannel>
  slackOrgIntegration?: Maybe<SlackOrgIntegration>
  slackOrgIntegrationUrl: Scalars['String']['output']
  slackUserIntegration?: Maybe<SlackUserIntegration>
  slackUserIntegrationUrl: Scalars['String']['output']
  source: Source
  sourceEntityNames: Array<SourceEntityName>
  sourceEntitySchemas: Array<SourceEntitySchema>
  sources: Array<Source>
  sourcesTableRecords: Array<SourcesTableRecord>
  subscriptionStatus: Array<SubscriptionStatus>
  syncConnectionResults: Array<Maybe<SyncConnectionResult>>
  syncFailedConnections: Array<Maybe<SyncFailedConnection>>
  syncModelResults: Array<Maybe<SyncModelResult>>
  syncSuccessfulModels: Array<Scalars['String']['output']>
  userActivities: Array<UserActivity>
  userGroups: Array<UserGroup>
  userSources: Array<UserSource>
  users: Array<V2User>
  viewConnections: Array<Connection>
  viewGroups: Array<ViewGroup>
  viewHasHubspot: Scalars['Boolean']['output']
  viewOldestSyncTime?: Maybe<Scalars['DateTime']['output']>
  viewPermissionUsers: Array<ViewPermissionUser>
  viewQueryResult: Scalars['ViewQueryResult']['output']
  viewQueryResultByStream: Scalars['ViewQueryResult']['output']
  viewQueryResults: Array<Maybe<Scalars['ViewQueryResult']['output']>>
  viewRelatedModels: Array<ViewRelatedModel>
  viewResultAsCsv: ViewResultAsCsvResponse
  viewSearchSqlResult: PostgresQueryResult
  viewSyncResult: ViewSyncResult
  views: Array<View>
  viewsForSider: Array<ViewForSider>
}

export type QueryAdminEltModelsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryAdminExtractCallingMetadataJobSettingArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryAdminFeatureFlagsArgs = {
  features: Array<Scalars['String']['input']>
}

export type QueryAdminJobQueueArgs = {
  id: Scalars['ID']['input']
}

export type QueryAdminJobQueuesArgs = {
  pastHours: Scalars['Int']['input']
}

export type QueryAdminOrganizationArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryAdminOrganizationsArgs = {
  shouldFetchDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAdminSoqlResultArgs = {
  enableQueryAll: Scalars['Boolean']['input']
  organizationId: Scalars['ID']['input']
  query: Scalars['String']['input']
}

export type QueryAdminSpeechToTextResultArgs = {
  prompt: Scalars['String']['input']
  s3ObjectPath: Scalars['String']['input']
}

export type QueryAdminSqlResultArgs = {
  destinationId?: InputMaybe<Scalars['ID']['input']>
  organizationId: Scalars['ID']['input']
  sql: Scalars['String']['input']
}

export type QueryAdminTableColumnsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryAllowedIpsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryAverageRenderDurationArgs = {
  organizationId: Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}

export type QueryBusinessDaysArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryConnectionsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryConvertingSalesforceLeadsArgs = {
  leadIds: Array<Scalars['String']['input']>
  organizationId: Scalars['ID']['input']
}

export type QueryDestinationsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryDimensionGroupsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryEltModelArgs = {
  name: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type QueryEltModelsArgs = {
  lang?: InputMaybe<LangEnum>
  organizationId: Scalars['ID']['input']
}

export type QueryFavoritesArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryGoalConfigsArgs = {
  organizationId: Scalars['ID']['input']
  viewId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryGoalDimensionsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryI18nSourcesArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryInvitationsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryJobProcessingQueuesArgs = {
  ids: Array<Scalars['ID']['input']>
  organizationId: Scalars['ID']['input']
}

export type QueryJobQueueArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type QueryJobQueuesArgs = {
  jobName?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['ID']['input']
}

export type QueryJobStatusesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  jobName?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['ID']['input']
}

export type QueryMixedUsersArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryModelsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryMyUserArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryOauthAuthorizationUrlArgs = {
  additionalScope?: InputMaybe<Scalars['String']['input']>
  authorizeUrl?: InputMaybe<Scalars['String']['input']>
  clientId?: InputMaybe<Scalars['String']['input']>
  organizationId?: InputMaybe<Scalars['ID']['input']>
  provider: Scalars['String']['input']
  sourceId?: InputMaybe<Scalars['String']['input']>
}

export type QueryOrganizationArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryPoliciesArgs = {
  action?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['ID']['input']
  subjectId?: InputMaybe<Scalars['String']['input']>
  subjectType?: InputMaybe<Scalars['String']['input']>
}

export type QueryPolicyUserRolesArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryPropertiesBoilerplateArgs = {
  organizationId: Scalars['ID']['input']
  shouldResolveId: Scalars['Boolean']['input']
  sql: Scalars['String']['input']
}

export type QueryQueryResultsArgs = {
  organizationId: Scalars['ID']['input']
  queries: Scalars['JSON']['input']
}

export type QueryRecordChangeHistoriesArgs = {
  modelName: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
  propertyNames: Array<Scalars['String']['input']>
  recordId: Scalars['String']['input']
}

export type QueryResourceUsersWithGroupArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryReverseEltsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryS3SignedUrlArgs = {
  organizationId: Scalars['ID']['input']
  s3ObjectPath: Scalars['String']['input']
}

export type QuerySalescoreConnectionArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySalescoreJwtArgs = {
  instanceUrl?: InputMaybe<Scalars['String']['input']>
  oauthCode?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  sessionToken?: InputMaybe<Scalars['String']['input']>
  type: Scalars['String']['input']
}

export type QuerySamlConfigsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySharedUrlArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type QuerySheetCustomModelsArgs = {
  organizationId: Scalars['ID']['input']
  viewId: Scalars['String']['input']
}

export type QuerySheetThreadCommentNotificationsArgs = {
  organizationId: Scalars['ID']['input']
  page?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySheetThreadsArgs = {
  organizationId: Scalars['ID']['input']
  recordIds: Array<Scalars['ID']['input']>
}

export type QuerySlackBotJoinedChannelArgs = {
  channelId?: InputMaybe<Scalars['ID']['input']>
  organizationId: Scalars['ID']['input']
}

export type QuerySlackChannelsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySlackOrgIntegrationArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySlackOrgIntegrationUrlArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySlackUserIntegrationArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySlackUserIntegrationUrlArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySourceArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type QuerySourceEntityNamesArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type QuerySourceEntitySchemasArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  targetEntityNames: Array<Scalars['String']['input']>
}

export type QuerySourcesArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySourcesTableRecordsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySubscriptionStatusArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySyncConnectionResultsArgs = {
  connectionId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type QuerySyncFailedConnectionsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QuerySyncModelResultsArgs = {
  jobQueueId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type QuerySyncSuccessfulModelsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryUserActivitiesArgs = {
  endAt: Scalars['Date']['input']
  organizationId: Scalars['ID']['input']
  startAt: Scalars['Date']['input']
}

export type QueryUserGroupsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryUserSourcesArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryUsersArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryViewConnectionsArgs = {
  organizationId: Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}

export type QueryViewGroupsArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryViewHasHubspotArgs = {
  organizationId: Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}

export type QueryViewOldestSyncTimeArgs = {
  organizationId: Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}

export type QueryViewPermissionUsersArgs = {
  viewId: Scalars['ID']['input']
}

export type QueryViewQueryResultArgs = {
  cursor?: InputMaybe<ViewQueryResultCursorInput>
  dateForDiffHighlight?: InputMaybe<Scalars['Date']['input']>
  mustacheParameter: Scalars['JSON']['input']
  organizationId: Scalars['ID']['input']
  shouldJoinRecordsInApplication?: InputMaybe<Scalars['Boolean']['input']>
  unlimitedRowsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  viewId: Scalars['ID']['input']
  viewQuery: Scalars['ViewQuery']['input']
}

export type QueryViewQueryResultByStreamArgs = {
  organizationId: Scalars['ID']['input']
  streamId: Scalars['String']['input']
  unlimitedRowsEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryViewQueryResultsArgs = {
  cursor?: InputMaybe<ViewQueryResultCursorInput>
  dateForDiffHighlight?: InputMaybe<Scalars['Date']['input']>
  mustacheParameter: Scalars['JSON']['input']
  organizationId: Scalars['ID']['input']
  viewId?: InputMaybe<Scalars['ID']['input']>
  viewQueries: Array<Scalars['ViewQuery']['input']>
}

export type QueryViewRelatedModelsArgs = {
  organizationId: Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}

export type QueryViewResultAsCsvArgs = {
  mustacheParameter: Scalars['JSON']['input']
  organizationId: Scalars['ID']['input']
  viewId?: InputMaybe<Scalars['ID']['input']>
  viewQuery: Scalars['ViewQuery']['input']
  viewUi: Scalars['ViewUI']['input']
}

export type QueryViewSearchSqlResultArgs = {
  organizationId: Scalars['ID']['input']
  sql: Scalars['String']['input']
}

export type QueryViewSyncResultArgs = {
  organizationId: Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}

export type QueryViewsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
  modelName?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['ID']['input']
  type?: InputMaybe<ViewConfigTypeEnum>
  withDependency?: InputMaybe<Scalars['Boolean']['input']>
  withReverseDependency?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryViewsForSiderArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryParameterConfig = {
  __typename?: 'QueryParameterConfig'
  columns: Array<Scalars['String']['output']>
  dashboardItemId: Scalars['String']['output']
  id: Scalars['ID']['output']
  invisibleDimensions: Array<Scalars['String']['output']>
  name: Scalars['String']['output']
  period?: Maybe<Period>
  rank: Scalars['Int']['output']
  rows: Array<Scalars['String']['output']>
}

export type QueryParameterConfigInput = {
  columns: Array<Scalars['String']['input']>
  dashboardItemId: Scalars['String']['input']
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  invisibleDimensions: Array<Scalars['String']['input']>
  name: Scalars['String']['input']
  period?: InputMaybe<PeriodInput>
  rank: Scalars['Int']['input']
  rows: Array<Scalars['String']['input']>
}

export type QueryResult = {
  __typename?: 'QueryResult'
  error?: Maybe<Scalars['String']['output']>
  fieldLabels?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  query: Scalars['JSON']['output']
  result?: Maybe<PostgresQueryResult>
  sql?: Maybe<Scalars['String']['output']>
}

export type RecordChangeHistory = {
  __typename?: 'RecordChangeHistory'
  changedAt: Scalars['DateTime']['output']
  changedById: Scalars['String']['output']
  changedByName?: Maybe<Scalars['String']['output']>
  newValue?: Maybe<Scalars['String']['output']>
  oldValue?: Maybe<Scalars['String']['output']>
  propertyName: Scalars['String']['output']
}

export type RenderDuration = {
  __typename?: 'RenderDuration'
  createdAt: Scalars['DateTime']['output']
  duration: Scalars['Int']['output']
  id: Scalars['ID']['output']
  isRecordsCached?: Maybe<Scalars['Boolean']['output']>
  organizationId: Scalars['String']['output']
  trigger: RenderTriggerEnum
  updatedAt: Scalars['DateTime']['output']
  viewId: Scalars['String']['output']
  viewType: ViewConfigTypeEnum
}

export type RenderDurationInput = {
  duration: Scalars['Int']['input']
  isRecordsCached?: InputMaybe<Scalars['Boolean']['input']>
  trigger: RenderTriggerEnum
  viewId: Scalars['String']['input']
  viewType: ViewConfigTypeEnum
}

export const RenderTriggerEnum = {
  DrillDownModalOpened: 'drillDownModalOpened',
  KpiPreviewUpdated: 'kpiPreviewUpdated',
  TabChanged: 'tabChanged',
} as const

export type RenderTriggerEnum = (typeof RenderTriggerEnum)[keyof typeof RenderTriggerEnum]
export type Report = {
  __typename?: 'Report'
  id: Scalars['ID']['output']
  kpi?: Maybe<Kpi>
  kpiId?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  queryModel: Scalars['JSON']['output']
}

export type ReportInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  kpiId?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  queryModel: Scalars['JSON']['input']
}

export type ResourceUser = {
  __typename?: 'ResourceUser'
  big_query_user_id?: Maybe<Scalars['String']['output']>
  comdesk_user_id?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  google_sheets_user_id?: Maybe<Scalars['String']['output']>
  hubspot_owner_id?: Maybe<Scalars['String']['output']>
  hubspot_user_id?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image_url?: Maybe<Scalars['String']['output']>
  kintone_user_id?: Maybe<Scalars['String']['output']>
  microsoft_dynamics_user_id?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  next_sfa_user_id?: Maybe<Scalars['String']['output']>
  rank: Scalars['Int']['output']
  salescore_invitation_id?: Maybe<Scalars['String']['output']>
  salescore_user_id?: Maybe<Scalars['String']['output']>
  salesforce_user_id?: Maybe<Scalars['String']['output']>
  senses_user_id?: Maybe<Scalars['String']['output']>
  user_group_id?: Maybe<Scalars['String']['output']>
  visibility: Scalars['Boolean']['output']
  zoho_user_id?: Maybe<Scalars['String']['output']>
}

export type ResourceUserInput = {
  id: Scalars['ID']['input']
  imageUrl?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  visibility?: InputMaybe<Scalars['Boolean']['input']>
}

export type ResourceUserWithGroup = {
  __typename?: 'ResourceUserWithGroup'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  image_url?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  rank: Scalars['Int']['output']
  user_group_d1_id?: Maybe<Scalars['String']['output']>
  user_group_d1_name?: Maybe<Scalars['String']['output']>
  user_group_d2_id?: Maybe<Scalars['String']['output']>
  user_group_d2_name?: Maybe<Scalars['String']['output']>
  user_group_d3_id?: Maybe<Scalars['String']['output']>
  user_group_d3_name?: Maybe<Scalars['String']['output']>
  user_group_d4_id?: Maybe<Scalars['String']['output']>
  user_group_d4_name?: Maybe<Scalars['String']['output']>
  user_group_d5_id?: Maybe<Scalars['String']['output']>
  user_group_d5_name?: Maybe<Scalars['String']['output']>
  user_group_depth?: Maybe<Scalars['Int']['output']>
  user_group_id?: Maybe<Scalars['String']['output']>
  visibility: Scalars['Boolean']['output']
}

export type ReverseElt = {
  __typename?: 'ReverseElt'
  connectionId: Scalars['String']['output']
  destination: Destination
  destinationId: Scalars['String']['output']
  eltModel: EltModel
  eltModelId: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  sql: Scalars['String']['output']
  syncedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ReverseEltCreateInput = {
  destinationId: Scalars['String']['input']
  eltModelId: Scalars['String']['input']
  name: Scalars['String']['input']
  sql: Scalars['String']['input']
}

export type ReverseEltUpdateInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  sql: Scalars['String']['input']
}

export type RoleRecordPermission = {
  __typename?: 'RoleRecordPermission'
  action: PermissionActionEnum
  id: Scalars['ID']['output']
  role: Scalars['String']['output']
  viewGroupId?: Maybe<Scalars['String']['output']>
  viewId?: Maybe<Scalars['String']['output']>
}

export type RoleRecordPermissionInput = {
  action: PermissionActionEnum
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  permissionableDefaultPermission?: InputMaybe<PermissionActionEnum>
  role: Scalars['String']['input']
  viewGroupId?: InputMaybe<Scalars['String']['input']>
  viewId?: InputMaybe<Scalars['String']['input']>
}

export type SalescoreSubscription = {
  __typename?: 'SalescoreSubscription'
  canceledAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  note?: Maybe<Scalars['String']['output']>
  numberOfSeats: Scalars['Int']['output']
  organizationId: Scalars['String']['output']
  plan: SubscriptionPlanEnum
  pricePerSeat: Scalars['Int']['output']
}

export type SalesforceLeadStatus = {
  __typename?: 'SalesforceLeadStatus'
  id: Scalars['String']['output']
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type SamlConfig = {
  __typename?: 'SamlConfig'
  icon?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  organizationId: Scalars['String']['output']
}

export type SharedUrl = {
  __typename?: 'SharedUrl'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<ViewCreatedBy>
  createdById?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  organizationId?: Maybe<Scalars['String']['output']>
  parameter: Scalars['JSON']['output']
  sheetThread?: Maybe<SheetThreadWithoutCommentsAndSharedUrl>
  sheetThreadComment?: Maybe<SheetThreadComment>
  type?: Maybe<ViewConfigTypeEnum>
  viewId?: Maybe<Scalars['String']['output']>
}

export type SharedUrlCreateInput = {
  parameter: Scalars['JSON']['input']
  type: ViewConfigTypeEnum
  viewId: Scalars['String']['input']
}

export type SheetCustomModel = {
  __typename?: 'SheetCustomModel'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nodeName: Scalars['String']['output']
  properties: Array<Scalars['CoreModelProperty']['output']>
  viewId: Scalars['String']['output']
}

export type SheetThread = {
  __typename?: 'SheetThread'
  comments: Array<Maybe<SheetThreadComment>>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  organizationId: Scalars['String']['output']
  recordId: Scalars['String']['output']
  recordName?: Maybe<Scalars['String']['output']>
  sharedUrl?: Maybe<SharedUrl>
  updatedAt: Scalars['DateTime']['output']
}

export type SheetThreadComment = {
  __typename?: 'SheetThreadComment'
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<V2User>
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
  sharedUrl?: Maybe<SharedUrl>
  sheetThread: SheetThreadWithoutComments
  slackSheetThreadComment?: Maybe<SlackSheetThreadComment>
  updatedAt: Scalars['DateTime']['output']
}

export type SheetThreadCommentCreateInput = {
  additionalConfigFilterLeafs?: InputMaybe<Scalars['JSON']['input']>
  kpiPivotParameter?: InputMaybe<KpiPivotParameter>
  kpiPivotPresetName?: InputMaybe<Scalars['String']['input']>
  message: Scalars['String']['input']
  modelName: Scalars['String']['input']
  organizationId: Scalars['String']['input']
  recordId: Scalars['String']['input']
  recordName?: InputMaybe<Scalars['String']['input']>
  sheetPresetName?: InputMaybe<Scalars['String']['input']>
  sheetViewId?: InputMaybe<Scalars['String']['input']>
  threadId?: InputMaybe<Scalars['String']['input']>
  viewId: Scalars['String']['input']
}

export type SheetThreadCommentNotification = {
  __typename?: 'SheetThreadCommentNotification'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  sheetThreadComment: SheetThreadComment
  status: NotificationStatusEnum
  updatedAt: Scalars['DateTime']['output']
  user: V2User
}

export type SheetThreadCommentNotificationUpdateInput = {
  organizationId: Scalars['String']['input']
  sheetThreadCommentNotificationId: Scalars['String']['input']
  status: NotificationStatusEnum
}

export type SheetThreadCreateInput = {
  modelName: Scalars['String']['input']
  organizationId: Scalars['String']['input']
  recordId: Scalars['String']['input']
  recordName?: InputMaybe<Scalars['String']['input']>
  viewId: Scalars['String']['input']
}

export type SheetThreadWithoutComments = {
  __typename?: 'SheetThreadWithoutComments'
  id: Scalars['ID']['output']
  modelLabel?: Maybe<Scalars['String']['output']>
  modelName?: Maybe<Scalars['String']['output']>
  organizationId: Scalars['String']['output']
  recordId: Scalars['String']['output']
  recordName?: Maybe<Scalars['String']['output']>
  sharedUrl?: Maybe<SharedUrl>
}

export type SheetThreadWithoutCommentsAndSharedUrl = {
  __typename?: 'SheetThreadWithoutCommentsAndSharedUrl'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  organizationId: Scalars['String']['output']
  recordId: Scalars['String']['output']
  recordName?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type SlackBotJoinedChannel = {
  __typename?: 'SlackBotJoinedChannel'
  status: Scalars['Boolean']['output']
}

export type SlackChannel = {
  __typename?: 'SlackChannel'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  numMembers: Scalars['Int']['output']
  purpose?: Maybe<Scalars['String']['output']>
  topic?: Maybe<Scalars['String']['output']>
}

export type SlackOrgIntegration = {
  __typename?: 'SlackOrgIntegration'
  accessToken: Scalars['String']['output']
  accessTokenExpiresAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  defaultChannelId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  integratedBy: V2User
  refreshToken?: Maybe<Scalars['String']['output']>
  slackUserId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SlackOrgIntegrationCreateInput = {
  authCode: Scalars['String']['input']
  organizationId: Scalars['String']['input']
}

export type SlackOrgIntegrationDeleteInput = {
  organizationId: Scalars['String']['input']
}

export type SlackOrgIntegrationUpdateInput = {
  defaultChannelId?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['String']['input']
}

export type SlackSheetThreadComment = {
  __typename?: 'SlackSheetThreadComment'
  createdBySlackUserName: Scalars['String']['output']
}

export type SlackUserIntegration = {
  __typename?: 'SlackUserIntegration'
  accessToken: Scalars['String']['output']
  accessTokenExpiresAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  defaultChannelId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  refreshToken?: Maybe<Scalars['String']['output']>
  slackUserId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  user: V2User
}

export type SlackUserIntegrationCreateInput = {
  authCode: Scalars['String']['input']
  organizationId: Scalars['String']['input']
}

export type SlackUserIntegrationDeleteInput = {
  organizationId: Scalars['String']['input']
}

export type SlackUserIntegrationUpdateInput = {
  defaultChannelId?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['String']['input']
}

export type SnapshotModelCreateInput = {
  model: Scalars['CoreModel']['input']
  name: Scalars['String']['input']
  transform: EltModelTransformInput
}

export type SnapshotSequenceModel = {
  __typename?: 'SnapshotSequenceModel'
  createdAt: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type Source = {
  __typename?: 'Source'
  config?: Maybe<Scalars['JSON']['output']>
  connection?: Maybe<Connection>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isAvailable?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  organizationId: Scalars['String']['output']
  provider: SourceProviderEnum
  updatedAt: Scalars['DateTime']['output']
  writableWithoutUserSource: Scalars['Boolean']['output']
}

export type SourceConfig = {
  __typename?: 'SourceConfig'
  meta?: Maybe<SourceConfigMeta>
  spreadsheetId?: Maybe<Scalars['String']['output']>
}

export type SourceConfigMeta = {
  __typename?: 'SourceConfigMeta'
  display_name?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
}

export type SourceCreateInput = {
  config: Scalars['JSON']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  organizationId: Scalars['String']['input']
  provider: SourceProviderEnum
}

export type SourceEntityName = {
  __typename?: 'SourceEntityName'
  forcePick?: Maybe<Scalars['Boolean']['output']>
  label: Scalars['String']['output']
  name: Scalars['ID']['output']
}

export type SourceEntityPropertySchema = {
  __typename?: 'SourceEntityPropertySchema'
  forcePick?: Maybe<Scalars['Boolean']['output']>
  label: Scalars['String']['output']
  meta?: Maybe<PropertyMetaEnum>
  name: Scalars['ID']['output']
  referenceTo?: Maybe<Array<Scalars['String']['output']>>
  selectOptions?: Maybe<Array<ModelPropertySelectOption>>
  type?: Maybe<PropertyTypeEnum>
}

export type SourceEntitySchema = {
  __typename?: 'SourceEntitySchema'
  forcePick?: Maybe<Scalars['Boolean']['output']>
  label: Scalars['String']['output']
  name: Scalars['ID']['output']
  properties: Array<SourceEntityPropertySchema>
}

export const SourceProviderEnum = {
  BigQuery: 'big_query',
  Comdesk: 'comdesk',
  FileMaker: 'file_maker',
  Gmail: 'gmail',
  Google: 'google',
  GoogleCalendar: 'google_calendar',
  GoogleSheets: 'google_sheets',
  Hubspot: 'hubspot',
  Kintone: 'kintone',
  MicrosoftDynamics: 'microsoft_dynamics',
  NextSfa: 'next_sfa',
  Postgres: 'postgres',
  Salescore: 'salescore',
  Salesforce: 'salesforce',
  Senses: 'senses',
  Zoho: 'zoho',
  Zoom: 'zoom',
} as const

export type SourceProviderEnum = (typeof SourceProviderEnum)[keyof typeof SourceProviderEnum]
export type SourcesTableRecord = {
  __typename?: 'SourcesTableRecord'
  connection: Connection
  hasError: Scalars['Boolean']['output']
  lastSyncedAt?: Maybe<Scalars['DateTime']['output']>
}

export type SpeechToTextResult = {
  __typename?: 'SpeechToTextResult'
  s3SignedUrl: Scalars['String']['output']
  transcript: Scalars['String']['output']
}

export type Stream = {
  __typename?: 'Stream'
  connectionId?: Maybe<Scalars['String']['output']>
  customized?: Maybe<Scalars['Boolean']['output']>
  destination: StreamDestination
  filtered?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['ID']['output']>
  meta: StreamMeta
  name: Scalars['String']['output']
  properties: Array<Property>
  source?: Maybe<StreamSource>
}

export type StreamDestination = {
  __typename?: 'StreamDestination'
  derivedTableDefinition?: Maybe<StreamDestinationDerivedTableDefinition>
  filters?: Maybe<Array<Scalars['String']['output']>>
  indices?: Maybe<Array<StreamDestinationIndex>>
  materialized?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  viewDefinitionSQL?: Maybe<Scalars['String']['output']>
}

export type StreamDestinationDerivedTableDefinition = {
  __typename?: 'StreamDestinationDerivedTableDefinition'
  deleteKey: Scalars['String']['output']
  deleteSql: Scalars['String']['output']
  dependentStreams: Array<DependentStream>
  primaryKey?: Maybe<Scalars['String']['output']>
  sql: Scalars['String']['output']
}

export type StreamDestinationDerivedTableDefinitionInput = {
  deleteKey: Scalars['String']['input']
  deleteSql: Scalars['String']['input']
  dependentStreams: Array<DependentStreamInput>
  primaryKey?: InputMaybe<Scalars['String']['input']>
  sql: Scalars['String']['input']
}

export type StreamDestinationIndex = {
  __typename?: 'StreamDestinationIndex'
  name: Scalars['String']['output']
  propertyNames: Array<Scalars['String']['output']>
}

export type StreamDestinationIndexInput = {
  name: Scalars['String']['input']
  propertyNames: Array<Scalars['String']['input']>
}

export type StreamDestinationInput = {
  derivedTableDefinition?: InputMaybe<StreamDestinationDerivedTableDefinitionInput>
  filters?: InputMaybe<Array<Scalars['String']['input']>>
  indices?: InputMaybe<Array<StreamDestinationIndexInput>>
  materialized?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  viewDefinitionSQL?: InputMaybe<Scalars['String']['input']>
}

export type StreamInput = {
  customized?: InputMaybe<Scalars['Boolean']['input']>
  destination: StreamDestinationInput
  filtered?: InputMaybe<Scalars['Boolean']['input']>
  meta: StreamMetaInput
  name: Scalars['String']['input']
  properties: Array<PropertyInput>
  source?: InputMaybe<StreamSourceInput>
}

export type StreamMeta = {
  __typename?: 'StreamMeta'
  creatable?: Maybe<Scalars['Boolean']['output']>
  deletable?: Maybe<Scalars['Boolean']['output']>
  label: Scalars['String']['output']
  updatable?: Maybe<Scalars['Boolean']['output']>
}

export type StreamMetaInput = {
  creatable?: InputMaybe<Scalars['Boolean']['input']>
  deletable?: InputMaybe<Scalars['Boolean']['input']>
  label: Scalars['String']['input']
  updatable?: InputMaybe<Scalars['Boolean']['input']>
}

export type StreamSource = {
  __typename?: 'StreamSource'
  filters?: Maybe<Array<Scalars['String']['output']>>
  name: Scalars['String']['output']
}

export type StreamSourceInput = {
  filters?: InputMaybe<Array<Scalars['String']['input']>>
  name: Scalars['String']['input']
}

export type StreamSourceRecordsInput = {
  after?: InputMaybe<Scalars['JSON']['input']>
  before?: InputMaybe<Scalars['JSON']['input']>
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  recordNameLabel?: InputMaybe<Scalars['String']['input']>
  streamName: Scalars['String']['input']
}

export type StreamSourceWriteResult = {
  __typename?: 'StreamSourceWriteResult'
  errors: Array<WriteResultError>
}

export type SubjectPoliciesReplaceInput = {
  action: Scalars['PolicyAction']['input']
  principalsWithPermission: Array<SubjectPoliciesReplaceInputPrincipalsWithPermission>
  subject: Scalars['PolicySubject']['input']
}

export type SubjectPoliciesReplaceInputPrincipalsWithPermission = {
  permission: Scalars['Boolean']['input']
  principal: Scalars['PolicyPrincipal']['input']
}

export type Subscription = {
  __typename?: 'Subscription'
  jobQueue: JobQueue
  timestamp: Scalars['String']['output']
}

export type SubscriptionJobQueueArgs = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type SubscriptionInput = {
  note?: InputMaybe<Scalars['String']['input']>
  numberOfSeats: Scalars['Int']['input']
  plan: SubscriptionPlanEnum
  pricePerSeat: Scalars['Int']['input']
}

export const SubscriptionPlanEnum = {
  Full: 'full',
  Ri: 'ri',
  Sheet: 'sheet',
  Standard: 'standard',
  Sync: 'sync',
  Visualize: 'visualize',
} as const

export type SubscriptionPlanEnum = (typeof SubscriptionPlanEnum)[keyof typeof SubscriptionPlanEnum]
export type SubscriptionStatus = {
  __typename?: 'SubscriptionStatus'
  isInvitationCreatable: Scalars['Boolean']['output']
  isInvitationFreeOrganization: Scalars['Boolean']['output']
  numberOfFreeInvitations: Scalars['Int']['output']
  numberOfFreeUsers: Scalars['Int']['output']
  numberOfInvitations: Scalars['Int']['output']
  numberOfRestSeats: Scalars['Int']['output']
  numberOfSeats: Scalars['Int']['output']
  numberOfUsers: Scalars['Int']['output']
  subscription: SalescoreSubscription
}

export type SyncConnectionResult = {
  __typename?: 'SyncConnectionResult'
  checkError?: Maybe<Scalars['String']['output']>
  checkSuccess: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  jobQueueId: Scalars['ID']['output']
  syncSuccess: Scalars['Boolean']['output']
}

export const SyncContextEnum = {
  ManualBulk: 'MANUAL_BULK',
  ManualBulkSoftDelete: 'MANUAL_BULK_SOFT_DELETE',
  ManualIncremental: 'MANUAL_INCREMENTAL',
} as const

export type SyncContextEnum = (typeof SyncContextEnum)[keyof typeof SyncContextEnum]
export type SyncFailedConnection = {
  __typename?: 'SyncFailedConnection'
  connectionId: Scalars['ID']['output']
  jobQueueId: Scalars['ID']['output']
}

export const SyncModeEnum = {
  Bulk: 'BULK',
  Incremental: 'INCREMENTAL',
} as const

export type SyncModeEnum = (typeof SyncModeEnum)[keyof typeof SyncModeEnum]
export type SyncModelResult = {
  __typename?: 'SyncModelResult'
  error?: Maybe<Scalars['String']['output']>
  label: Scalars['String']['output']
  name: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  timestamp: Scalars['DateTime']['output']
}

export type SyncModelsInput = {
  connectionId: Scalars['ID']['input']
  eltModelIds: Array<Scalars['ID']['input']>
}

export type UpdateSheetCustomModelPropertyInput = {
  id: Scalars['ID']['input']
  label: Scalars['String']['input']
  propertyName: Scalars['String']['input']
  selectOptions?: InputMaybe<Scalars['JSON']['input']>
}

export const UpdateViewModeEnum = {
  KpiTimeSeriesUseExistingGoalConfigFragment: 'kpiTimeSeriesUseExistingGoalConfigFragment',
} as const

export type UpdateViewModeEnum = (typeof UpdateViewModeEnum)[keyof typeof UpdateViewModeEnum]
export type UserActivity = {
  __typename?: 'UserActivity'
  lastSignedInAt?: Maybe<Scalars['DateTime']['output']>
  loginCount: Scalars['Int']['output']
  session: Scalars['Int']['output']
  user: V2User
  userId: Scalars['ID']['output']
  viewKpiDaysCount: Scalars['Int']['output']
  viewKpiOrKpiPivotDaysCount: Scalars['Int']['output']
  viewKpiPivotDaysCount: Scalars['Int']['output']
  viewSheetDaysCount: Scalars['Int']['output']
}

export type UserGroup = {
  __typename?: 'UserGroup'
  depth: Scalars['Int']['output']
  id: Scalars['ID']['output']
  image_url?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  rank: Scalars['Int']['output']
  user_group_id?: Maybe<Scalars['String']['output']>
  users: Array<ResourceUser>
}

export type UserGroupInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  depth: Scalars['Int']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  image_url?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  rank: Scalars['Int']['input']
  userIds: Array<Scalars['String']['input']>
  user_group_id?: InputMaybe<Scalars['String']['input']>
}

export type UserInput = {
  identityOldId: Scalars['ID']['input']
  imageUrl?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  oldId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  role: Scalars['String']['input']
}

export type UserInputV2 = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  imageUrl?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
}

export type UserLicense = {
  __typename?: 'UserLicense'
  license: Scalars['String']['output']
  plan: UserPlanEnum
}

export const UserLicenseTypeEnum = {
  Ri: 'ri',
  Sync: 'sync',
  Visualize: 'visualize',
} as const

export type UserLicenseTypeEnum = (typeof UserLicenseTypeEnum)[keyof typeof UserLicenseTypeEnum]
export const UserPlanEnum = {
  Free: 'free',
  None: 'none',
  Standard: 'standard',
} as const

export type UserPlanEnum = (typeof UserPlanEnum)[keyof typeof UserPlanEnum]
export const UserRoleEnumV2 = {
  Admin: 'admin',
  Guest: 'guest',
  Manager: 'manager',
  Member: 'member',
  Minimal: 'minimal',
  NxMember: 'nxMember',
  PlayingManager: 'playingManager',
} as const

export type UserRoleEnumV2 = (typeof UserRoleEnumV2)[keyof typeof UserRoleEnumV2]
export type UserSource = {
  __typename?: 'UserSource'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isAvailable?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  provider: SourceProviderEnum
  updatedAt: Scalars['DateTime']['output']
}

export type V2Organization = {
  __typename?: 'V2Organization'
  businessDays: Array<BusinessDay>
  createdAt: Scalars['DateTime']['output']
  currentPlan: SubscriptionPlanEnum
  dimensionGroups: Array<DimensionGroup>
  id: Scalars['ID']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  isEarlyReleaseTarget: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  organizationFeatures: Array<OrganizationFeature>
  organizationPlans: Array<OrganizationPlan>
  policies: Array<Policy>
  setting: OrganizationSetting
  status: OrganizationStatusEnum
  updatedAt: Scalars['DateTime']['output']
}

export type V2User = {
  __typename?: 'V2User'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  identity: Identity
  imageUrl?: Maybe<Scalars['String']['output']>
  isManager: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  plan: UserPlanEnum
  role: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  userLicenses: Array<UserLicense>
}

export type ValidationRule = {
  __typename?: 'ValidationRule'
  feedbackProperties: Array<PropertyNameWithStreamName>
  function: Scalars['ValidationFunction']['output']
  message: Scalars['String']['output']
}

export type View = {
  __typename?: 'View'
  archived: Scalars['Boolean']['output']
  archivedAt?: Maybe<Scalars['DateTime']['output']>
  config: Scalars['ViewConfig']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<ViewCreatedBy>
  createdById?: Maybe<Scalars['String']['output']>
  defaultPermission: PermissionActionEnum
  eltModelId?: Maybe<Scalars['String']['output']>
  emoji?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  importedSourceId?: Maybe<Scalars['String']['output']>
  locked: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  private: Scalars['Boolean']['output']
  queries: Array<Scalars['ViewQuery']['output']>
  query?: Maybe<Scalars['ViewQuery']['output']>
  rank: Scalars['Int']['output']
  roleRecordPermissions: Array<RoleRecordPermission>
  type: ViewConfigTypeEnum
  ui: Scalars['ViewUI']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  updatedBy?: Maybe<ViewUpdatedBy>
  updatedById?: Maybe<Scalars['String']['output']>
  viewGroupId?: Maybe<Scalars['String']['output']>
  viewGroupIdBeforeArchived?: Maybe<Scalars['String']['output']>
}

export const ViewConfigTypeEnum = {
  Form: 'form',
  Kpi: 'kpi',
  KpiPivot: 'kpiPivot',
  KpiTimeSeries: 'kpiTimeSeries',
  Sheet: 'sheet',
} as const

export type ViewConfigTypeEnum = (typeof ViewConfigTypeEnum)[keyof typeof ViewConfigTypeEnum]
export type ViewCreatedBy = {
  __typename?: 'ViewCreatedBy'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ViewForSider = {
  __typename?: 'ViewForSider'
  archived: Scalars['Boolean']['output']
  archivedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<ViewCreatedBy>
  createdById?: Maybe<Scalars['String']['output']>
  defaultPermission: PermissionActionEnum
  eltModelId?: Maybe<Scalars['String']['output']>
  emoji?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  importedSourceId?: Maybe<Scalars['String']['output']>
  locked: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  private: Scalars['Boolean']['output']
  rank: Scalars['Int']['output']
  roleRecordPermissions: Array<RoleRecordPermission>
  type: ViewConfigTypeEnum
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  updatedBy?: Maybe<ViewUpdatedBy>
  updatedById?: Maybe<Scalars['String']['output']>
  viewGroupId?: Maybe<Scalars['String']['output']>
  viewGroupIdBeforeArchived?: Maybe<Scalars['String']['output']>
}

export type ViewGroup = {
  __typename?: 'ViewGroup'
  archived: Scalars['Boolean']['output']
  archivedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<ViewCreatedBy>
  createdById?: Maybe<Scalars['String']['output']>
  emoji?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  importedSourceId?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  private: Scalars['Boolean']['output']
  rank: Scalars['Int']['output']
  streamName: Scalars['String']['output']
  type: ViewGroupTypeEnum
  updatedAt: Scalars['DateTime']['output']
  updatedBy?: Maybe<ViewUpdatedBy>
  updatedById?: Maybe<Scalars['String']['output']>
  viewGroupId?: Maybe<Scalars['String']['output']>
  viewGroupIdBeforeArchived?: Maybe<Scalars['String']['output']>
}

export type ViewGroupCreateInput = {
  emoji?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  private?: InputMaybe<Scalars['Boolean']['input']>
  type?: InputMaybe<ViewGroupTypeEnum>
  viewGroupId?: InputMaybe<Scalars['String']['input']>
}

export type ViewGroupInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  rank: Scalars['Int']['input']
  streamName?: InputMaybe<Scalars['String']['input']>
  viewGroupId?: InputMaybe<Scalars['String']['input']>
}

export const ViewGroupTypeEnum = {
  Kpi: 'kpi',
  KpiPivot: 'kpiPivot',
  KpiTimeSeries: 'kpiTimeSeries',
  Sheet: 'sheet',
} as const

export type ViewGroupTypeEnum = (typeof ViewGroupTypeEnum)[keyof typeof ViewGroupTypeEnum]
export type ViewGroupUpdateInput = {
  emoji?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  rank?: InputMaybe<Scalars['Int']['input']>
  viewGroupId?: InputMaybe<Scalars['String']['input']>
}

export type ViewInput = {
  config?: InputMaybe<Scalars['ViewConfig']['input']>
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  queries?: InputMaybe<Array<Scalars['ViewQuery']['input']>>
  query: Scalars['ViewQuery']['input']
  rank?: InputMaybe<Scalars['Int']['input']>
  ui: Scalars['ViewUI']['input']
  viewGroupId: Scalars['String']['input']
}

export type ViewPermissionUser = {
  __typename?: 'ViewPermissionUser'
  id: Scalars['ID']['output']
  userId: Scalars['String']['output']
  viewId: Scalars['String']['output']
}

export type ViewQueryResultCursorInput = {
  chunkSize: Scalars['Int']['input']
  nextId?: InputMaybe<Scalars['String']['input']>
  page: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
}

export type ViewRelatedModel = {
  __typename?: 'ViewRelatedModel'
  connection: Connection
  eltModels: Array<EltModel>
}

export type ViewSyncResult = {
  __typename?: 'ViewSyncResult'
  failedEltModels: Array<EltModel>
  success: Scalars['Boolean']['output']
}

export type ViewUpdateInput = {
  emoji?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  query?: InputMaybe<Scalars['ViewQuery']['input']>
  rank?: InputMaybe<Scalars['Int']['input']>
  ui?: InputMaybe<Scalars['ViewUI']['input']>
  viewGroupId?: InputMaybe<Scalars['String']['input']>
}

export type ViewUpdateInputSingle = {
  config?: InputMaybe<Scalars['ViewConfig']['input']>
  emoji?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  ignoreLocked?: InputMaybe<Scalars['Boolean']['input']>
  locked?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<UpdateViewModeEnum>
  name?: InputMaybe<Scalars['String']['input']>
}

export type ViewUpdatedBy = {
  __typename?: 'ViewUpdatedBy'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type WriteResultError = {
  __typename?: 'WriteResultError'
  description?: Maybe<Scalars['String']['output']>
  detail?: Maybe<Scalars['JSON']['output']>
  id?: Maybe<Scalars['String']['output']>
  message: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  propertySourceNames?: Maybe<Array<Scalars['String']['output']>>
  streamName?: Maybe<Scalars['String']['output']>
}

export type WriteToSourceInput = {
  connectionId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  records: Scalars['JSON']['input']
}

export type UpdateUserGroupByCsvErrorsResponse = {
  __typename?: 'updateUserGroupByCsvErrorsResponse'
  index: Scalars['Int']['output']
  message: Scalars['String']['output']
  record: Scalars['JSON']['output']
}

export type UpdateUserGroupByCsvResponse = {
  __typename?: 'updateUserGroupByCsvResponse'
  errors: Array<UpdateUserGroupByCsvErrorsResponse>
  success: Array<UpdateUserGroupByCsvSuccessResponse>
  successCount: Scalars['Int']['output']
}

export type UpdateUserGroupByCsvSuccessResponse = {
  __typename?: 'updateUserGroupByCsvSuccessResponse'
  index: Scalars['Int']['output']
  record: Scalars['JSON']['output']
}

export type UpdateUserGroupIdByCsvResponse = {
  __typename?: 'updateUserGroupIdByCsvResponse'
  errors: Array<UpdateUserGroupIdsByCsvErrorsResponse>
  success: Array<UpdateUserGroupIdsByCsvSuccessResponse>
  successCount: Scalars['Int']['output']
}

export type UpdateUserGroupIdsByCsvErrorsResponse = {
  __typename?: 'updateUserGroupIdsByCsvErrorsResponse'
  index: Scalars['Int']['output']
  message: Scalars['String']['output']
  record: Scalars['JSON']['output']
}

export type UpdateUserGroupIdsByCsvSuccessResponse = {
  __typename?: 'updateUserGroupIdsByCsvSuccessResponse'
  index: Scalars['Int']['output']
  record: Scalars['JSON']['output']
}

export type UpsertGoalsByCsvV2ErrorsResponse = {
  __typename?: 'upsertGoalsByCsvV2ErrorsResponse'
  index: Scalars['Int']['output']
  message: Scalars['String']['output']
  record: Scalars['JSON']['output']
}

export type UpsertGoalsByCsvV2Response = {
  __typename?: 'upsertGoalsByCsvV2Response'
  errors: Array<UpsertGoalsByCsvV2ErrorsResponse>
  success: Array<UpsertGoalsByCsvV2SuccessResponse>
  successCount: Scalars['Int']['output']
}

export type UpsertGoalsByCsvV2SuccessResponse = {
  __typename?: 'upsertGoalsByCsvV2SuccessResponse'
  index: Scalars['Int']['output']
  record: Scalars['JSON']['output']
}

export type ViewResultAsCsvResponse = {
  __typename?: 'viewResultAsCsvResponse'
  csv: Scalars['String']['output']
  error?: Maybe<Scalars['String']['output']>
  hasNext: Scalars['Boolean']['output']
}

export type AllowedIpFieldsFragment = { __typename?: 'AllowedIp'; id: string; ip: string; description?: string | null }

export type BusinessDayFieldsFragment = { __typename?: 'BusinessDay'; id: string; date: string; isBusinessDay: boolean }

export type ConnectionFieldsFragment = {
  __typename?: 'Connection'
  id: string
  organizationId: string
  sourceId: string
  active: boolean
  eltType: ConnectionEltTypeEnum
  createdAt: string
  updatedAt: string
  eltConfig: {
    __typename?: 'EltConfig'
    sourceNamesWhitelist?: Array<string> | null
    sourceConfigs?: Array<{
      __typename?: 'EltConfigSourceConfig'
      sourceName: string
      propertyNamesWhitelist?: Array<string> | null
    }> | null
  }
}

export type DashboardItemFieldsFragment = {
  __typename?: 'DashboardItemV2'
  id: string
  name: string
  rank: number
  shouldOrderByKpiIds: boolean
  kpis: Array<{
    __typename?: 'Kpi'
    id: string
    name: string
    rank: number
    description?: string | null
    kind: KpiKindEnum
    goal_type: KpiGoalTypeEnum
    achievement_status_calculation_type: KpiAchievementStatusCalculationTypeEnum
    achievement_status_target_value_type: KpiAchievementStatusTargetValueTypeEnum
    display_format?: KpiDisplayFormatEnum | null
    icon?: string | null
    abbreviation_type: KpiAbbreviationTypeEnum
    time_dependent: boolean
    user_dependent: boolean
  }>
  userGroups: Array<{
    __typename?: 'UserGroup'
    id: string
    name: string
    image_url?: string | null
    rank: number
    depth: number
    user_group_id?: string | null
    users: Array<{
      __typename?: 'ResourceUser'
      id: string
      name: string
      email: string
      user_group_id?: string | null
      image_url?: string | null
      rank: number
      visibility: boolean
      salescore_user_id?: string | null
      salescore_invitation_id?: string | null
      salesforce_user_id?: string | null
      hubspot_owner_id?: string | null
      hubspot_user_id?: string | null
      zoho_user_id?: string | null
      next_sfa_user_id?: string | null
      google_sheets_user_id?: string | null
      comdesk_user_id?: string | null
      senses_user_id?: string | null
      microsoft_dynamics_user_id?: string | null
      big_query_user_id?: string | null
      kintone_user_id?: string | null
    }>
  }>
  queryParameterConfigs: Array<{
    __typename?: 'QueryParameterConfig'
    id: string
    dashboardItemId: string
    rank: number
    name: string
    rows: Array<string>
    columns: Array<string>
    invisibleDimensions: Array<string>
    period?: { __typename?: 'Period'; name: string; startAt?: string | null; endAt?: string | null } | null
  }>
}

export type DestinationFieldsFragment = {
  __typename?: 'Destination'
  id: string
  name?: string | null
  provider: DestinationProviderEnum
}

export type DimensionGroupFieldsFragment = {
  __typename?: 'DimensionGroup'
  id: string
  label: string
  properties: Array<DimensionGroupProperty>
}

export type EltConfigFieldsFragment = {
  __typename?: 'EltConfig'
  sourceNamesWhitelist?: Array<string> | null
  sourceConfigs?: Array<{
    __typename?: 'EltConfigSourceConfig'
    sourceName: string
    propertyNamesWhitelist?: Array<string> | null
  }> | null
}

export type EltModelFieldsFragment = {
  __typename?: 'EltModel'
  id: string
  connectionId?: string | null
  type: EltModelTypeEnum
  name: string
  model: CoreModel
  syncedAt?: string | null
  transformedAt?: string | null
  syncInvalidatedAt?: string | null
  transformInvalidatedAt?: string | null
  transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
}

export type FavoriteFieldsFragment = {
  __typename?: 'Favorite'
  id: string
  viewGroupId?: string | null
  viewId?: string | null
  rank: number
}

export type GoalConfigFieldsFragment = {
  __typename?: 'GoalConfig'
  id: string
  name: string
  timeSpanType: GoalConfigTimeSpanTypeEnum
  userType: GoalConfigUserTypeEnum
  userIdsConfig: Array<string>
  userGroupIdsConfig: Array<string>
  dashboardViewId?: string | null
  goalDimension1Id?: string | null
  goalDimension2Id?: string | null
  goalDimension3Id?: string | null
  goalDimension4Id?: string | null
  goalDimension5Id?: string | null
  rank: number
  config?: ViewConfig | null
  goalDimension1?: {
    __typename?: 'GoalDimension'
    id: string
    name: string
    label: string
    selectOptions: Array<string>
    selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
  } | null
  goalDimension2?: {
    __typename?: 'GoalDimension'
    id: string
    name: string
    label: string
    selectOptions: Array<string>
    selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
  } | null
  goalDimension3?: {
    __typename?: 'GoalDimension'
    id: string
    name: string
    label: string
    selectOptions: Array<string>
    selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
  } | null
  goalDimension4?: {
    __typename?: 'GoalDimension'
    id: string
    name: string
    label: string
    selectOptions: Array<string>
    selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
  } | null
  goalDimension5?: {
    __typename?: 'GoalDimension'
    id: string
    name: string
    label: string
    selectOptions: Array<string>
    selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
  } | null
  dashboardView?: { __typename?: 'GoalConfigDashboardView'; id: string; name: string } | null
  kpiViews: Array<{
    __typename?: 'GoalConfigKpiView'
    id: string
    name: string
    aggregationFunction: GoalConfigKpiViewAggregationFunctionType
  }>
  createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
}

export type GoalDimensionFieldsFragment = {
  __typename?: 'GoalDimension'
  id: string
  name: string
  label: string
  selectOptions: Array<string>
  selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
}

export type I18nSourceFieldsFragment = {
  __typename?: 'I18nSource'
  id: string
  organizationId: string
  provider: SourceProviderEnum
  name?: string | null
  lang: LangEnum
  createdAt: string
  updatedAt: string
}

export type InvitationFieldsFragment = {
  __typename?: 'Invitation'
  id: string
  email: string
  provider: IdentityProviderEnum
  role: string
  planForSync: UserPlanEnum
  planForVis: UserPlanEnum
}

export type KpiFieldsFragment = {
  __typename?: 'Kpi'
  id: string
  name: string
  rank: number
  description?: string | null
  kind: KpiKindEnum
  goal_type: KpiGoalTypeEnum
  achievement_status_calculation_type: KpiAchievementStatusCalculationTypeEnum
  achievement_status_target_value_type: KpiAchievementStatusTargetValueTypeEnum
  display_format?: KpiDisplayFormatEnum | null
  icon?: string | null
  abbreviation_type: KpiAbbreviationTypeEnum
  time_dependent: boolean
  user_dependent: boolean
}

export type MixedUserFieldsFragment = {
  __typename?: 'MixedUser'
  id: string
  resourceUser: {
    __typename?: 'ResourceUser'
    id: string
    name: string
    email: string
    user_group_id?: string | null
    image_url?: string | null
    rank: number
    visibility: boolean
    salescore_user_id?: string | null
    salescore_invitation_id?: string | null
    salesforce_user_id?: string | null
    hubspot_owner_id?: string | null
    hubspot_user_id?: string | null
    zoho_user_id?: string | null
    next_sfa_user_id?: string | null
    google_sheets_user_id?: string | null
    comdesk_user_id?: string | null
    senses_user_id?: string | null
    microsoft_dynamics_user_id?: string | null
    big_query_user_id?: string | null
    kintone_user_id?: string | null
  }
  user?: {
    __typename?: 'V2User'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    plan: UserPlanEnum
    isManager: boolean
    createdAt: string
    updatedAt: string
    identity: {
      __typename?: 'Identity'
      id: string
      email: string
      provider: IdentityProviderEnum
      createdAt: string
      updatedAt: string
      auth0UserId: string
      name: string
      role: IdentityRoleEnum
    }
    userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
  } | null
  invitation?: {
    __typename?: 'Invitation'
    id: string
    email: string
    provider: IdentityProviderEnum
    role: string
    planForSync: UserPlanEnum
    planForVis: UserPlanEnum
  } | null
}

export type ModelPropertyFieldsFragment = {
  __typename?: 'ModelProperty'
  name: string
  type: PropertyTypeEnum
  label: string
  meta?: PropertyMetaEnum | null
  creatable?: boolean | null
  updatable?: boolean | null
  trackable?: boolean | null
  selectOptions?: Array<{
    __typename?: 'ModelPropertySelectOption'
    label: string
    value: string
    color?: string | null
    icon?: string | null
    rule?: ValidationFunction | null
  }> | null
  referenceTo?: Array<{ __typename?: 'PropertyReferenceTo'; modelName: string; key?: string | null }> | null
  validations?: Array<{
    __typename?: 'ValidationRule'
    function: ValidationFunction
    message: string
    feedbackProperties: Array<{ __typename?: 'PropertyNameWithStreamName'; streamName: string; propertyName: string }>
  }> | null
  write?: { __typename?: 'ModelPropertyWrite'; sourcePropertyName: string } | null
}

export type RecordChangeHistoryFieldsFragment = {
  __typename?: 'RecordChangeHistory'
  changedById: string
  changedByName?: string | null
  changedAt: string
  oldValue?: string | null
  newValue?: string | null
  propertyName: string
}

export type MyActionFieldsFragment = {
  __typename?: 'MyActionV2'
  id: string
  name: string
  body?: string | null
  start_at: string
  end_at: string
  count: number
  chance_count: number
  review?: string | null
  status: MyActionStatusEnumV2
  user_id?: string | null
  kpi_id?: string | null
  user?: { __typename?: 'ResourceUser'; id: string; name: string; image_url?: string | null } | null
  kpi?: {
    __typename?: 'Kpi'
    id: string
    name: string
    rank: number
    description?: string | null
    kind: KpiKindEnum
    goal_type: KpiGoalTypeEnum
    achievement_status_calculation_type: KpiAchievementStatusCalculationTypeEnum
    achievement_status_target_value_type: KpiAchievementStatusTargetValueTypeEnum
    display_format?: KpiDisplayFormatEnum | null
    icon?: string | null
    abbreviation_type: KpiAbbreviationTypeEnum
    time_dependent: boolean
    user_dependent: boolean
  } | null
}

export type MyIdentityFieldsFragment = { __typename?: 'MyIdentity'; id: string; name: string; email: string }

export type MyUserFieldsFragment = {
  __typename?: 'MyUser'
  id: string
  name: string
  imageUrl?: string | null
  role: string
  plan: UserPlanEnum
  lang?: LangEnum | null
  createdAt: string
  identity: { __typename?: 'MyIdentity'; id: string; email: string }
  organization: {
    __typename?: 'V2Organization'
    id: string
    name: string
    imageUrl?: string | null
    createdAt: string
    organizationPlans: Array<{ __typename?: 'OrganizationPlan'; license: string; plan: OrganizationPlanTypeEnum }>
  }
  resourceUser?: {
    __typename?: 'ResourceUser'
    id: string
    name: string
    email: string
    user_group_id?: string | null
    image_url?: string | null
    rank: number
    visibility: boolean
    salescore_user_id?: string | null
    salescore_invitation_id?: string | null
    salesforce_user_id?: string | null
    hubspot_owner_id?: string | null
    hubspot_user_id?: string | null
    zoho_user_id?: string | null
    next_sfa_user_id?: string | null
    google_sheets_user_id?: string | null
    comdesk_user_id?: string | null
    senses_user_id?: string | null
    microsoft_dynamics_user_id?: string | null
    big_query_user_id?: string | null
    kintone_user_id?: string | null
  } | null
  userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
}

export type OrganizationFieldsFragment = {
  __typename?: 'V2Organization'
  id: string
  name: string
  status: OrganizationStatusEnum
  imageUrl?: string | null
  createdAt: string
  isEarlyReleaseTarget: boolean
  setting: {
    __typename?: 'OrganizationSetting'
    id: string
    accountClosingMonth: number
    monthBeginningDay: number
    userGroupCategoryNames: Array<string>
    csvDownloadEnabledForMember: boolean
    miitelCompanyId?: string | null
    miitelAccessKey?: string | null
    shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
    i18nEnabled: boolean
    viewSiderVisibility: boolean
    defaultOpenViewIds: Array<string>
    shouldJoinRecordsInApplication: boolean
    shouldIgnoreInactiveSourceUser: boolean
    disableEditUsername: boolean
  }
  dimensionGroups: Array<{
    __typename?: 'DimensionGroup'
    id: string
    label: string
    properties: Array<DimensionGroupProperty>
  }>
  businessDays: Array<{ __typename?: 'BusinessDay'; id: string; date: string; isBusinessDay: boolean }>
  organizationFeatures: Array<{ __typename?: 'OrganizationFeature'; feature: string }>
  organizationPlans: Array<{ __typename?: 'OrganizationPlan'; license: string; plan: OrganizationPlanTypeEnum }>
}

export type OrganizationSettingFieldsFragment = {
  __typename?: 'OrganizationSetting'
  id: string
  accountClosingMonth: number
  monthBeginningDay: number
  userGroupCategoryNames: Array<string>
  csvDownloadEnabledForMember: boolean
  miitelCompanyId?: string | null
  miitelAccessKey?: string | null
  shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
  i18nEnabled: boolean
  viewSiderVisibility: boolean
  defaultOpenViewIds: Array<string>
  shouldJoinRecordsInApplication: boolean
  shouldIgnoreInactiveSourceUser: boolean
  disableEditUsername: boolean
}

export type PolicyFieldsFragment = {
  __typename?: 'Policy'
  id: string
  principal: PolicyPrincipal
  action: PolicyAction
  subject?: PolicySubject | null
  permission: boolean
  createdAt: string
}

export type PolicyUserRoleFieldsFragment = {
  __typename?: 'PolicyUserRole'
  id: string
  organizationId?: string | null
  name: string
  label?: string | null
  description?: string | null
  updatedById?: string | null
  updatedAt?: string | null
  createdAt?: string | null
}

export type PropertyFieldsFragment = {
  __typename?: 'Property'
  name: string
  customized?: boolean | null
  source?: {
    __typename?: 'PropertySource'
    name: string
    meta?: {
      __typename?: 'PropertySourceMeta'
      type?: string | null
      meta?: string | null
      referenceTo?: Array<string> | null
    } | null
  } | null
  destination: { __typename?: 'PropertyDestination'; name: string; sql: string; type: PropertyTypeEnum }
  meta: {
    __typename?: 'PropertyMeta'
    label: string
    meta?: PropertyMetaEnum | null
    typeForMigration?: PropertyTypeForMigrationEnum | null
    referenceTo?: Array<string> | null
    creatable?: boolean | null
    updatable?: boolean | null
    selectOptions?: Array<{
      __typename?: 'PropertySelectOption'
      label: string
      value: string
      color?: string | null
      icon?: string | null
      rule?: ValidationFunction | null
    }> | null
    validations?: Array<{
      __typename?: 'ValidationRule'
      function: ValidationFunction
      message: string
      feedbackProperties: Array<{ __typename?: 'PropertyNameWithStreamName'; streamName: string; propertyName: string }>
    }> | null
  }
}

export type QueryParameterConfigFieldsFragment = {
  __typename?: 'QueryParameterConfig'
  id: string
  dashboardItemId: string
  rank: number
  name: string
  rows: Array<string>
  columns: Array<string>
  invisibleDimensions: Array<string>
  period?: { __typename?: 'Period'; name: string; startAt?: string | null; endAt?: string | null } | null
}

export type ReportFieldsFragment = {
  __typename?: 'Report'
  id: string
  name: string
  queryModel: unknown
  kpiId?: string | null
  kpi?: {
    __typename?: 'Kpi'
    id: string
    name: string
    rank: number
    description?: string | null
    kind: KpiKindEnum
    goal_type: KpiGoalTypeEnum
    achievement_status_calculation_type: KpiAchievementStatusCalculationTypeEnum
    achievement_status_target_value_type: KpiAchievementStatusTargetValueTypeEnum
    display_format?: KpiDisplayFormatEnum | null
    icon?: string | null
    abbreviation_type: KpiAbbreviationTypeEnum
    time_dependent: boolean
    user_dependent: boolean
  } | null
}

export type ResourceUserFieldsFragment = {
  __typename?: 'ResourceUser'
  id: string
  name: string
  email: string
  user_group_id?: string | null
  image_url?: string | null
  rank: number
  visibility: boolean
  salescore_user_id?: string | null
  salescore_invitation_id?: string | null
  salesforce_user_id?: string | null
  hubspot_owner_id?: string | null
  hubspot_user_id?: string | null
  zoho_user_id?: string | null
  next_sfa_user_id?: string | null
  google_sheets_user_id?: string | null
  comdesk_user_id?: string | null
  senses_user_id?: string | null
  microsoft_dynamics_user_id?: string | null
  big_query_user_id?: string | null
  kintone_user_id?: string | null
}

export type ResourceUserWithGroupFieldsFragment = {
  __typename?: 'ResourceUserWithGroup'
  id: string
  name: string
  email: string
  user_group_id?: string | null
  image_url?: string | null
  rank: number
  visibility: boolean
  user_group_d1_id?: string | null
  user_group_d1_name?: string | null
  user_group_d2_id?: string | null
  user_group_d2_name?: string | null
  user_group_d3_id?: string | null
  user_group_d3_name?: string | null
  user_group_d4_id?: string | null
  user_group_d4_name?: string | null
  user_group_d5_id?: string | null
  user_group_d5_name?: string | null
  user_group_depth?: number | null
}

export type ReverseEltFieldsFragment = {
  __typename?: 'ReverseElt'
  id: string
  connectionId: string
  destinationId: string
  eltModelId: string
  name: string
  sql: string
  syncedAt?: string | null
  destination: { __typename?: 'Destination'; id: string; name?: string | null; provider: DestinationProviderEnum }
  eltModel: {
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  }
}

export type RoleRecordPermissionFieldsFragment = {
  __typename?: 'RoleRecordPermission'
  id: string
  viewId?: string | null
  viewGroupId?: string | null
  role: string
  action: PermissionActionEnum
}

export type SharedUrlFieldsFragment = {
  __typename?: 'SharedUrl'
  id: string
  organizationId?: string | null
  viewId?: string | null
  parameter: unknown
  type?: ViewConfigTypeEnum | null
  createdById?: string | null
  createdAt?: string | null
  createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
}

export type SheetCustomModelFieldsFragment = {
  __typename?: 'SheetCustomModel'
  id: string
  viewId: string
  nodeName: string
  name: string
  properties: Array<CoreModelProperty>
}

export type SheetThreadCommentFieldsFragment = {
  __typename?: 'SheetThreadComment'
  id: string
  message: string
  createdAt: string
  updatedAt: string
  sheetThread: {
    __typename?: 'SheetThreadWithoutComments'
    id: string
    organizationId: string
    recordId: string
    recordName?: string | null
    modelName?: string | null
    modelLabel?: string | null
    sharedUrl?: {
      __typename?: 'SharedUrl'
      id: string
      organizationId?: string | null
      viewId?: string | null
      parameter: unknown
      type?: ViewConfigTypeEnum | null
      createdById?: string | null
      createdAt?: string | null
      createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    } | null
  }
  sharedUrl?: {
    __typename?: 'SharedUrl'
    id: string
    organizationId?: string | null
    viewId?: string | null
    parameter: unknown
    type?: ViewConfigTypeEnum | null
    createdById?: string | null
    createdAt?: string | null
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  } | null
  createdBy?: {
    __typename?: 'V2User'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    plan: UserPlanEnum
    isManager: boolean
    createdAt: string
    updatedAt: string
    identity: {
      __typename?: 'Identity'
      id: string
      email: string
      provider: IdentityProviderEnum
      createdAt: string
      updatedAt: string
      auth0UserId: string
      name: string
      role: IdentityRoleEnum
    }
    userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
  } | null
  slackSheetThreadComment?: { __typename?: 'SlackSheetThreadComment'; createdBySlackUserName: string } | null
}

export type SheetThreadCommentNotificationFieldsFragment = {
  __typename?: 'SheetThreadCommentNotification'
  id: string
  status: NotificationStatusEnum
  createdAt: string
  updatedAt: string
  user: {
    __typename?: 'V2User'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    plan: UserPlanEnum
    isManager: boolean
    createdAt: string
    updatedAt: string
    identity: {
      __typename?: 'Identity'
      id: string
      email: string
      provider: IdentityProviderEnum
      createdAt: string
      updatedAt: string
      auth0UserId: string
      name: string
      role: IdentityRoleEnum
    }
    userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
  }
  sheetThreadComment: {
    __typename?: 'SheetThreadComment'
    id: string
    message: string
    createdAt: string
    updatedAt: string
    sheetThread: {
      __typename?: 'SheetThreadWithoutComments'
      id: string
      organizationId: string
      recordId: string
      recordName?: string | null
      modelName?: string | null
      modelLabel?: string | null
      sharedUrl?: {
        __typename?: 'SharedUrl'
        id: string
        organizationId?: string | null
        viewId?: string | null
        parameter: unknown
        type?: ViewConfigTypeEnum | null
        createdById?: string | null
        createdAt?: string | null
        createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
      } | null
    }
    sharedUrl?: {
      __typename?: 'SharedUrl'
      id: string
      organizationId?: string | null
      viewId?: string | null
      parameter: unknown
      type?: ViewConfigTypeEnum | null
      createdById?: string | null
      createdAt?: string | null
      createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    } | null
    createdBy?: {
      __typename?: 'V2User'
      id: string
      name: string
      imageUrl?: string | null
      role: string
      plan: UserPlanEnum
      isManager: boolean
      createdAt: string
      updatedAt: string
      identity: {
        __typename?: 'Identity'
        id: string
        email: string
        provider: IdentityProviderEnum
        createdAt: string
        updatedAt: string
        auth0UserId: string
        name: string
        role: IdentityRoleEnum
      }
      userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
    } | null
    slackSheetThreadComment?: { __typename?: 'SlackSheetThreadComment'; createdBySlackUserName: string } | null
  }
}

export type SheetThreadFieldsFragment = {
  __typename?: 'SheetThread'
  id: string
  organizationId: string
  recordId: string
  recordName?: string | null
  createdAt: string
  updatedAt: string
  sharedUrl?: {
    __typename?: 'SharedUrl'
    id: string
    organizationId?: string | null
    viewId?: string | null
    parameter: unknown
    type?: ViewConfigTypeEnum | null
    createdById?: string | null
    createdAt?: string | null
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  } | null
  comments: Array<{
    __typename?: 'SheetThreadComment'
    id: string
    message: string
    createdAt: string
    updatedAt: string
    sheetThread: {
      __typename?: 'SheetThreadWithoutComments'
      id: string
      organizationId: string
      recordId: string
      recordName?: string | null
      modelName?: string | null
      modelLabel?: string | null
      sharedUrl?: {
        __typename?: 'SharedUrl'
        id: string
        organizationId?: string | null
        viewId?: string | null
        parameter: unknown
        type?: ViewConfigTypeEnum | null
        createdById?: string | null
        createdAt?: string | null
        createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
      } | null
    }
    sharedUrl?: {
      __typename?: 'SharedUrl'
      id: string
      organizationId?: string | null
      viewId?: string | null
      parameter: unknown
      type?: ViewConfigTypeEnum | null
      createdById?: string | null
      createdAt?: string | null
      createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    } | null
    createdBy?: {
      __typename?: 'V2User'
      id: string
      name: string
      imageUrl?: string | null
      role: string
      plan: UserPlanEnum
      isManager: boolean
      createdAt: string
      updatedAt: string
      identity: {
        __typename?: 'Identity'
        id: string
        email: string
        provider: IdentityProviderEnum
        createdAt: string
        updatedAt: string
        auth0UserId: string
        name: string
        role: IdentityRoleEnum
      }
      userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
    } | null
    slackSheetThreadComment?: { __typename?: 'SlackSheetThreadComment'; createdBySlackUserName: string } | null
  } | null>
}

export type SourceEntityPropertySchemaFieldsFragment = {
  __typename?: 'SourceEntityPropertySchema'
  name: string
  label: string
  forcePick?: boolean | null
  type?: PropertyTypeEnum | null
  meta?: PropertyMetaEnum | null
  referenceTo?: Array<string> | null
  selectOptions?: Array<{ __typename?: 'ModelPropertySelectOption'; value: string; label: string }> | null
}

export type SourceEntitySchemaFieldsFragment = {
  __typename?: 'SourceEntitySchema'
  name: string
  label: string
  forcePick?: boolean | null
  properties: Array<{
    __typename?: 'SourceEntityPropertySchema'
    name: string
    label: string
    forcePick?: boolean | null
    type?: PropertyTypeEnum | null
    meta?: PropertyMetaEnum | null
    referenceTo?: Array<string> | null
    selectOptions?: Array<{ __typename?: 'ModelPropertySelectOption'; value: string; label: string }> | null
  }>
}

export type SourceFieldsFragment = {
  __typename?: 'Source'
  id: string
  provider: SourceProviderEnum
  name?: string | null
  config?: unknown | null
  writableWithoutUserSource: boolean
  organizationId: string
  createdAt: string
  updatedAt: string
}

export type StreamFieldsFragment = {
  __typename?: 'Stream'
  id?: string | null
  name: string
  customized?: boolean | null
  filtered?: boolean | null
  source?: { __typename?: 'StreamSource'; name: string; filters?: Array<string> | null } | null
  destination: {
    __typename?: 'StreamDestination'
    name: string
    filters?: Array<string> | null
    viewDefinitionSQL?: string | null
    materialized?: boolean | null
    derivedTableDefinition?: {
      __typename?: 'StreamDestinationDerivedTableDefinition'
      sql: string
      deleteSql: string
      deleteKey: string
      primaryKey?: string | null
      dependentStreams: Array<{ __typename?: 'DependentStream'; sourceName: string; streamName: string }>
    } | null
  }
  meta: {
    __typename?: 'StreamMeta'
    label: string
    creatable?: boolean | null
    updatable?: boolean | null
    deletable?: boolean | null
  }
  properties: Array<{
    __typename?: 'Property'
    name: string
    customized?: boolean | null
    source?: {
      __typename?: 'PropertySource'
      name: string
      meta?: {
        __typename?: 'PropertySourceMeta'
        type?: string | null
        meta?: string | null
        referenceTo?: Array<string> | null
      } | null
    } | null
    destination: { __typename?: 'PropertyDestination'; name: string; sql: string; type: PropertyTypeEnum }
    meta: {
      __typename?: 'PropertyMeta'
      label: string
      meta?: PropertyMetaEnum | null
      typeForMigration?: PropertyTypeForMigrationEnum | null
      referenceTo?: Array<string> | null
      creatable?: boolean | null
      updatable?: boolean | null
      selectOptions?: Array<{
        __typename?: 'PropertySelectOption'
        label: string
        value: string
        color?: string | null
        icon?: string | null
        rule?: ValidationFunction | null
      }> | null
      validations?: Array<{
        __typename?: 'ValidationRule'
        function: ValidationFunction
        message: string
        feedbackProperties: Array<{
          __typename?: 'PropertyNameWithStreamName'
          streamName: string
          propertyName: string
        }>
      }> | null
    }
  }>
}

export type SubscriptionStatusFieldsFragment = {
  __typename?: 'SubscriptionStatus'
  numberOfSeats: number
  numberOfRestSeats: number
  numberOfUsers: number
  numberOfInvitations: number
  numberOfFreeUsers: number
  numberOfFreeInvitations: number
  isInvitationFreeOrganization: boolean
  isInvitationCreatable: boolean
  subscription: { __typename?: 'SalescoreSubscription'; id: string; plan: SubscriptionPlanEnum }
}

export type UserActivityFieldsFragment = {
  __typename?: 'UserActivity'
  loginCount: number
  session: number
  lastSignedInAt?: string | null
  viewSheetDaysCount: number
  viewKpiDaysCount: number
  viewKpiPivotDaysCount: number
  viewKpiOrKpiPivotDaysCount: number
  user: {
    __typename?: 'V2User'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    plan: UserPlanEnum
    isManager: boolean
    createdAt: string
    updatedAt: string
    identity: {
      __typename?: 'Identity'
      id: string
      email: string
      provider: IdentityProviderEnum
      createdAt: string
      updatedAt: string
      auth0UserId: string
      name: string
      role: IdentityRoleEnum
    }
    userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
  }
}

export type UserFieldsFragment = {
  __typename?: 'V2User'
  id: string
  name: string
  imageUrl?: string | null
  role: string
  plan: UserPlanEnum
  isManager: boolean
  createdAt: string
  updatedAt: string
  identity: {
    __typename?: 'Identity'
    id: string
    email: string
    provider: IdentityProviderEnum
    createdAt: string
    updatedAt: string
    auth0UserId: string
    name: string
    role: IdentityRoleEnum
  }
  userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
}

export type UserGroupFieldsFragment = {
  __typename?: 'UserGroup'
  id: string
  name: string
  image_url?: string | null
  rank: number
  depth: number
  user_group_id?: string | null
  users: Array<{
    __typename?: 'ResourceUser'
    id: string
    name: string
    email: string
    user_group_id?: string | null
    image_url?: string | null
    rank: number
    visibility: boolean
    salescore_user_id?: string | null
    salescore_invitation_id?: string | null
    salesforce_user_id?: string | null
    hubspot_owner_id?: string | null
    hubspot_user_id?: string | null
    zoho_user_id?: string | null
    next_sfa_user_id?: string | null
    google_sheets_user_id?: string | null
    comdesk_user_id?: string | null
    senses_user_id?: string | null
    microsoft_dynamics_user_id?: string | null
    big_query_user_id?: string | null
    kintone_user_id?: string | null
  }>
}

export type UserSourceFieldsFragment = {
  __typename?: 'UserSource'
  id: string
  provider: SourceProviderEnum
  name?: string | null
  createdAt: string
  updatedAt: string
}

export type ViewFieldsFragment = {
  __typename?: 'View'
  id: string
  viewGroupId?: string | null
  eltModelId?: string | null
  name: string
  type: ViewConfigTypeEnum
  queries: Array<ViewQuery>
  ui: ViewUI
  config: ViewConfig
  rank: number
  defaultPermission: PermissionActionEnum
  importedSourceId?: string | null
  emoji?: string | null
  createdById?: string | null
  updatedById?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  private: boolean
  archived: boolean
  archivedAt?: string | null
  viewGroupIdBeforeArchived?: string | null
  roleRecordPermissions: Array<{
    __typename?: 'RoleRecordPermission'
    id: string
    viewId?: string | null
    viewGroupId?: string | null
    role: string
    action: PermissionActionEnum
  }>
  createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
}

export type ViewForSiderFieldsFragment = {
  __typename?: 'ViewForSider'
  id: string
  viewGroupId?: string | null
  eltModelId?: string | null
  name: string
  type: ViewConfigTypeEnum
  rank: number
  defaultPermission: PermissionActionEnum
  importedSourceId?: string | null
  emoji?: string | null
  createdById?: string | null
  updatedById?: string | null
  private: boolean
  archived: boolean
  archivedAt?: string | null
  viewGroupIdBeforeArchived?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  roleRecordPermissions: Array<{
    __typename?: 'RoleRecordPermission'
    id: string
    viewId?: string | null
    viewGroupId?: string | null
    role: string
    action: PermissionActionEnum
  }>
}

export type ViewGroupFieldsFragment = {
  __typename?: 'ViewGroup'
  id: string
  name: string
  streamName: string
  rank: number
  type: ViewGroupTypeEnum
  viewGroupId?: string | null
  importedSourceId?: string | null
  emoji?: string | null
  private: boolean
  createdById?: string | null
  updatedById?: string | null
  archived: boolean
  archivedAt?: string | null
  viewGroupIdBeforeArchived?: string | null
  createdAt: string
  updatedAt: string
  createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
}

export type AddSheetCustomModelPropertyMutationVariables = Exact<{
  input: AddSheetCustomModelPropertyInput
  organizationId: Scalars['ID']['input']
}>

export type AddSheetCustomModelPropertyMutation = {
  __typename?: 'Mutation'
  addSheetCustomModelPropertyV2: {
    __typename?: 'AddSheetCustomModelPropertyResponse'
    addedProperty: CoreModelProperty
    sheetCustomModel: {
      __typename?: 'SheetCustomModel'
      id: string
      viewId: string
      nodeName: string
      name: string
      properties: Array<CoreModelProperty>
    }
  }
}

export type ApplyEltModelChangeMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  eltModelChangeIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type ApplyEltModelChangeMutation = {
  __typename?: 'Mutation'
  applyEltModelChange: Array<{
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  }>
}

export type ArchiveViewMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type ArchiveViewMutation = {
  __typename?: 'Mutation'
  archiveView?: {
    __typename?: 'View'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    queries: Array<ViewQuery>
    ui: ViewUI
    config: ViewConfig
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  } | null
}

export type ArchiveViewGroupMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type ArchiveViewGroupMutation = {
  __typename?: 'Mutation'
  archiveViewGroup?: {
    __typename?: 'ViewGroup'
    id: string
    name: string
    streamName: string
    rank: number
    type: ViewGroupTypeEnum
    viewGroupId?: string | null
    importedSourceId?: string | null
    emoji?: string | null
    private: boolean
    createdById?: string | null
    updatedById?: string | null
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt: string
    updatedAt: string
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  } | null
}

export type ConvertSalesforceLeadsMutationVariables = Exact<{
  inputs: Array<ConvertSalesforceLeadInput> | ConvertSalesforceLeadInput
  organizationId: Scalars['ID']['input']
}>

export type ConvertSalesforceLeadsMutation = {
  __typename?: 'Mutation'
  convertSalesforceLeads: Array<{
    __typename?: 'ConvertSalesforceLeadResult'
    success: boolean
    leadId: string
    errors?: string | null
  }>
}

export type CopyViewMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  viewGroupId?: InputMaybe<Scalars['ID']['input']>
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CopyViewMutation = {
  __typename?: 'Mutation'
  copyView: {
    __typename?: 'View'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    queries: Array<ViewQuery>
    ui: ViewUI
    config: ViewConfig
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  }
}

export type CreateDimensionGroupMutationVariables = Exact<{
  dimensionGroup: DimensionGroupCreateInput
  organizationId: Scalars['ID']['input']
}>

export type CreateDimensionGroupMutation = {
  __typename?: 'Mutation'
  createDimensionGroup?: {
    __typename?: 'DimensionGroup'
    id: string
    label: string
    properties: Array<DimensionGroupProperty>
  } | null
}

export type CreateEltModelMutationVariables = Exact<{
  eltModel: EltModelCreateInput
  organizationId: Scalars['ID']['input']
}>

export type CreateEltModelMutation = {
  __typename?: 'Mutation'
  createEltModel: {
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  }
}

export type CreateFavoriteMutationVariables = Exact<{
  favorite: FavoriteCreateInput
  organizationId: Scalars['ID']['input']
}>

export type CreateFavoriteMutation = {
  __typename?: 'Mutation'
  createFavorite?: {
    __typename?: 'Favorite'
    id: string
    viewGroupId?: string | null
    viewId?: string | null
    rank: number
  } | null
}

export type CreateGoalConfigMutationVariables = Exact<{
  goalConfig: GoalConfigCreateInput
  organizationId: Scalars['ID']['input']
}>

export type CreateGoalConfigMutation = {
  __typename?: 'Mutation'
  createGoalConfig: {
    __typename?: 'GoalConfig'
    id: string
    name: string
    timeSpanType: GoalConfigTimeSpanTypeEnum
    userType: GoalConfigUserTypeEnum
    userIdsConfig: Array<string>
    userGroupIdsConfig: Array<string>
    dashboardViewId?: string | null
    goalDimension1Id?: string | null
    goalDimension2Id?: string | null
    goalDimension3Id?: string | null
    goalDimension4Id?: string | null
    goalDimension5Id?: string | null
    rank: number
    config?: ViewConfig | null
    goalDimension1?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension2?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension3?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension4?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension5?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    dashboardView?: { __typename?: 'GoalConfigDashboardView'; id: string; name: string } | null
    kpiViews: Array<{
      __typename?: 'GoalConfigKpiView'
      id: string
      name: string
      aggregationFunction: GoalConfigKpiViewAggregationFunctionType
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  }
}

export type CreateIdentityMutationVariables = Exact<{ [key: string]: never }>

export type CreateIdentityMutation = {
  __typename?: 'Mutation'
  createIdentity?: { __typename?: 'Identity'; id: string; email: string } | null
}

export type CreateKintoneUserSourceMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  sourceId: Scalars['ID']['input']
  kintoneId: Scalars['String']['input']
  kintonePassword: Scalars['String']['input']
}>

export type CreateKintoneUserSourceMutation = {
  __typename?: 'Mutation'
  createKintoneUserSource?: {
    __typename?: 'UserSource'
    id: string
    name?: string | null
    provider: SourceProviderEnum
  } | null
}

export type CreateOauthI18nSourceMutationVariables = Exact<{
  input: OauthI18nSourceInput
}>

export type CreateOauthI18nSourceMutation = {
  __typename?: 'Mutation'
  createOauthI18nSource?: {
    __typename?: 'I18nSource'
    id: string
    name?: string | null
    provider: SourceProviderEnum
  } | null
}

export type CreateOauthSourceMutationVariables = Exact<{
  oauthSourceInput: OauthSourceInput
}>

export type CreateOauthSourceMutation = {
  __typename?: 'Mutation'
  createOauthSourceV2?: { __typename?: 'Source'; id: string; name?: string | null; provider: SourceProviderEnum } | null
}

export type CreateOauthUserSourceMutationVariables = Exact<{
  oauthUserSourceInput: OauthUserSourceInput
}>

export type CreateOauthUserSourceMutation = {
  __typename?: 'Mutation'
  createOauthUserSource?: {
    __typename?: 'UserSource'
    id: string
    name?: string | null
    provider: SourceProviderEnum
  } | null
}

export type CreatePolicyUserRoleMutationVariables = Exact<{
  policyUserRole: PolicyUserRoleCreateInput
  organizationId: Scalars['ID']['input']
}>

export type CreatePolicyUserRoleMutation = {
  __typename?: 'Mutation'
  createPolicyUserRole: {
    __typename?: 'PolicyUserRole'
    id: string
    organizationId?: string | null
    name: string
    label?: string | null
    description?: string | null
    updatedById?: string | null
    updatedAt?: string | null
    createdAt?: string | null
  }
}

export type CreateReverseEltMutationVariables = Exact<{
  reverseElt: ReverseEltCreateInput
  organizationId: Scalars['ID']['input']
}>

export type CreateReverseEltMutation = {
  __typename?: 'Mutation'
  createReverseElt?: {
    __typename?: 'ReverseElt'
    id: string
    connectionId: string
    destinationId: string
    eltModelId: string
    name: string
    sql: string
    syncedAt?: string | null
    destination: { __typename?: 'Destination'; id: string; name?: string | null; provider: DestinationProviderEnum }
    eltModel: {
      __typename?: 'EltModel'
      id: string
      connectionId?: string | null
      type: EltModelTypeEnum
      name: string
      model: CoreModel
      syncedAt?: string | null
      transformedAt?: string | null
      syncInvalidatedAt?: string | null
      transformInvalidatedAt?: string | null
      transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
    }
  } | null
}

export type CreateSalescoreModelMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  modelName: Scalars['String']['input']
}>

export type CreateSalescoreModelMutation = {
  __typename?: 'Mutation'
  createSalescoreModel: {
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  }
}

export type CreateSharedUrlMutationVariables = Exact<{
  sharedUrl: SharedUrlCreateInput
  organizationId: Scalars['ID']['input']
}>

export type CreateSharedUrlMutation = {
  __typename?: 'Mutation'
  createSharedUrl?: {
    __typename?: 'SharedUrl'
    id: string
    organizationId?: string | null
    viewId?: string | null
    parameter: unknown
    type?: ViewConfigTypeEnum | null
    createdById?: string | null
    createdAt?: string | null
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  } | null
}

export type CreateSheetThreadCommentMutationVariables = Exact<{
  sheetThreadCommentCreateInput: SheetThreadCommentCreateInput
}>

export type CreateSheetThreadCommentMutation = {
  __typename?: 'Mutation'
  createSheetThreadComment: {
    __typename?: 'SheetThreadComment'
    id: string
    message: string
    createdAt: string
    createdBy?: { __typename?: 'V2User'; id: string; name: string } | null
  }
}

export type CreateSlackOrgIntegrationMutationVariables = Exact<{
  slackOrgIntegrationCreateInput: SlackOrgIntegrationCreateInput
}>

export type CreateSlackOrgIntegrationMutation = {
  __typename?: 'Mutation'
  createSlackOrgIntegration: {
    __typename?: 'SlackOrgIntegration'
    id: string
    defaultChannelId?: string | null
    slackUserId: string
    accessTokenExpiresAt?: string | null
    createdAt: string
    integratedBy: { __typename?: 'V2User'; id: string; name: string }
  }
}

export type CreateSlackUserIntegrationMutationVariables = Exact<{
  slackUserIntegrationCreateInput: SlackUserIntegrationCreateInput
}>

export type CreateSlackUserIntegrationMutation = {
  __typename?: 'Mutation'
  createSlackUserIntegration: {
    __typename?: 'SlackUserIntegration'
    id: string
    defaultChannelId?: string | null
    slackUserId: string
    accessTokenExpiresAt?: string | null
    createdAt: string
    user: { __typename?: 'V2User'; id: string; name: string }
  }
}

export type CreateSnapshotModelMutationVariables = Exact<{
  snapshotModel: SnapshotModelCreateInput
  organizationId: Scalars['ID']['input']
}>

export type CreateSnapshotModelMutation = {
  __typename?: 'Mutation'
  createSnapshotModel: {
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  }
}

export type CreateSourceMutationVariables = Exact<{
  sourceInput: SourceCreateInput
}>

export type CreateSourceMutation = {
  __typename?: 'Mutation'
  createSource?: { __typename?: 'Source'; id: string; name?: string | null; provider: SourceProviderEnum } | null
}

export type CreateViewMutationVariables = Exact<{
  view: CreateViewInput
  organizationId: Scalars['ID']['input']
}>

export type CreateViewMutation = {
  __typename?: 'Mutation'
  createView: {
    __typename?: 'View'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    queries: Array<ViewQuery>
    ui: ViewUI
    config: ViewConfig
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  }
}

export type CreateViewGroupMutationVariables = Exact<{
  viewGroup: ViewGroupCreateInput
  organizationId: Scalars['ID']['input']
}>

export type CreateViewGroupMutation = {
  __typename?: 'Mutation'
  createViewGroup?: {
    __typename?: 'ViewGroup'
    id: string
    name: string
    streamName: string
    rank: number
    type: ViewGroupTypeEnum
    viewGroupId?: string | null
    importedSourceId?: string | null
    emoji?: string | null
    private: boolean
    createdById?: string | null
    updatedById?: string | null
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt: string
    updatedAt: string
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  } | null
}

export type DeleteDimensionGroupMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type DeleteDimensionGroupMutation = {
  __typename?: 'Mutation'
  deleteDimensionGroup?: {
    __typename?: 'DimensionGroup'
    id: string
    label: string
    properties: Array<DimensionGroupProperty>
  } | null
}

export type DeleteEltModelMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type DeleteEltModelMutation = {
  __typename?: 'Mutation'
  deleteEltModel?: {
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  } | null
}

export type DeleteFavoriteMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type DeleteFavoriteMutation = {
  __typename?: 'Mutation'
  deleteFavorite?: {
    __typename?: 'Favorite'
    id: string
    viewGroupId?: string | null
    viewId?: string | null
    rank: number
  } | null
}

export type DeleteGoalConfigMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type DeleteGoalConfigMutation = {
  __typename?: 'Mutation'
  deleteGoalConfig?: {
    __typename?: 'GoalConfig'
    id: string
    name: string
    timeSpanType: GoalConfigTimeSpanTypeEnum
    userType: GoalConfigUserTypeEnum
    userIdsConfig: Array<string>
    userGroupIdsConfig: Array<string>
    dashboardViewId?: string | null
    goalDimension1Id?: string | null
    goalDimension2Id?: string | null
    goalDimension3Id?: string | null
    goalDimension4Id?: string | null
    goalDimension5Id?: string | null
    rank: number
    config?: ViewConfig | null
    goalDimension1?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension2?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension3?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension4?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension5?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    dashboardView?: { __typename?: 'GoalConfigDashboardView'; id: string; name: string } | null
    kpiViews: Array<{
      __typename?: 'GoalConfigKpiView'
      id: string
      name: string
      aggregationFunction: GoalConfigKpiViewAggregationFunctionType
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  } | null
}

export type DeleteI18nSourceMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}>

export type DeleteI18nSourceMutation = {
  __typename?: 'Mutation'
  deleteI18nSource?: { __typename?: 'I18nSource'; id: string } | null
}

export type DeletePolicyUserRoleMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}>

export type DeletePolicyUserRoleMutation = {
  __typename?: 'Mutation'
  deletePolicyUserRole: {
    __typename?: 'PolicyUserRole'
    id: string
    organizationId?: string | null
    name: string
    label?: string | null
    description?: string | null
    updatedById?: string | null
    updatedAt?: string | null
    createdAt?: string | null
  }
}

export type DeleteReverseEltMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type DeleteReverseEltMutation = {
  __typename?: 'Mutation'
  deleteReverseElt?: {
    __typename?: 'ReverseElt'
    id: string
    connectionId: string
    destinationId: string
    eltModelId: string
    name: string
    sql: string
    syncedAt?: string | null
    destination: { __typename?: 'Destination'; id: string; name?: string | null; provider: DestinationProviderEnum }
    eltModel: {
      __typename?: 'EltModel'
      id: string
      connectionId?: string | null
      type: EltModelTypeEnum
      name: string
      model: CoreModel
      syncedAt?: string | null
      transformedAt?: string | null
      syncInvalidatedAt?: string | null
      transformInvalidatedAt?: string | null
      transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
    }
  } | null
}

export type DeleteSlackOrgIntegrationMutationVariables = Exact<{
  slackOrgIntegrationDeleteInput: SlackOrgIntegrationDeleteInput
}>

export type DeleteSlackOrgIntegrationMutation = {
  __typename?: 'Mutation'
  deleteSlackOrgIntegration: { __typename?: 'SlackOrgIntegration'; id: string }
}

export type DeleteSlackUserIntegrationMutationVariables = Exact<{
  slackUserIntegrationDeleteInput: SlackUserIntegrationDeleteInput
}>

export type DeleteSlackUserIntegrationMutation = {
  __typename?: 'Mutation'
  deleteSlackUserIntegration: { __typename?: 'SlackUserIntegration'; id: string }
}

export type DeleteSnapshotModelMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type DeleteSnapshotModelMutation = {
  __typename?: 'Mutation'
  deleteSnapshotModel: {
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  }
}

export type DeleteHubSourceMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}>

export type DeleteHubSourceMutation = {
  __typename?: 'Mutation'
  deleteSource?: { __typename?: 'Source'; id: string } | null
}

export type DeleteUserSourceMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}>

export type DeleteUserSourceMutation = {
  __typename?: 'Mutation'
  deleteUserSource?: { __typename?: 'UserSource'; id: string } | null
}

export type DeleteViewMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type DeleteViewMutation = {
  __typename?: 'Mutation'
  deleteView?: {
    __typename?: 'View'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    queries: Array<ViewQuery>
    ui: ViewUI
    config: ViewConfig
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  } | null
}

export type DeleteViewGroupMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type DeleteViewGroupMutation = {
  __typename?: 'Mutation'
  deleteViewGroup?: {
    __typename?: 'ViewGroup'
    id: string
    name: string
    streamName: string
    rank: number
    type: ViewGroupTypeEnum
    viewGroupId?: string | null
    importedSourceId?: string | null
    emoji?: string | null
    private: boolean
    createdById?: string | null
    updatedById?: string | null
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt: string
    updatedAt: string
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  } | null
}

export type InitializeGoalsMutationVariables = Exact<{
  goalConfigId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  dates: Array<Scalars['Date']['input']> | Scalars['Date']['input']
}>

export type InitializeGoalsMutation = {
  __typename?: 'Mutation'
  initializeGoals?: {
    __typename?: 'GoalConfig'
    id: string
    name: string
    timeSpanType: GoalConfigTimeSpanTypeEnum
    userType: GoalConfigUserTypeEnum
    userIdsConfig: Array<string>
    userGroupIdsConfig: Array<string>
    dashboardViewId?: string | null
    goalDimension1Id?: string | null
    goalDimension2Id?: string | null
    goalDimension3Id?: string | null
    goalDimension4Id?: string | null
    goalDimension5Id?: string | null
    rank: number
    config?: ViewConfig | null
    goalDimension1?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension2?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension3?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension4?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension5?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    dashboardView?: { __typename?: 'GoalConfigDashboardView'; id: string; name: string } | null
    kpiViews: Array<{
      __typename?: 'GoalConfigKpiView'
      id: string
      name: string
      aggregationFunction: GoalConfigKpiViewAggregationFunctionType
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  } | null
}

export type InvalidateEltModelSyncMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type InvalidateEltModelSyncMutation = {
  __typename?: 'Mutation'
  invalidateEltModelSync: {
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  }
}

export type JoinSlackBotToChannelMutationVariables = Exact<{
  joinSlackBotToChannelMutationInput: JoinSlackBotToChannelMutationInput
}>

export type JoinSlackBotToChannelMutation = {
  __typename?: 'Mutation'
  joinSlackBotToChannel: { __typename?: 'JoinSlackBotToChannelResponse'; status: string }
}

export type MarkSheetThreadCommentNotificationsAsReadMutationVariables = Exact<{
  commentIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type MarkSheetThreadCommentNotificationsAsReadMutation = {
  __typename?: 'Mutation'
  markSheetThreadCommentNotificationsAsRead: boolean
}

export type MoveViewGroupsMutationVariables = Exact<{
  inputs: Array<MoveViewGroupInput> | MoveViewGroupInput
  organizationId: Scalars['ID']['input']
}>

export type MoveViewGroupsMutation = {
  __typename?: 'Mutation'
  moveViewGroups?: Array<{
    __typename?: 'ViewGroup'
    id: string
    name: string
    streamName: string
    rank: number
    type: ViewGroupTypeEnum
    viewGroupId?: string | null
    importedSourceId?: string | null
    emoji?: string | null
    private: boolean
    createdById?: string | null
    updatedById?: string | null
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt: string
    updatedAt: string
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  }> | null
}

export type MoveViewsMutationVariables = Exact<{
  inputs: Array<MoveViewInput> | MoveViewInput
  organizationId: Scalars['ID']['input']
}>

export type MoveViewsMutation = {
  __typename?: 'Mutation'
  moveViews?: Array<{
    __typename?: 'View'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    queries: Array<ViewQuery>
    ui: ViewUI
    config: ViewConfig
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  }> | null
}

export type QueueImportSourceUsersQueueMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type QueueImportSourceUsersQueueMutation = {
  __typename?: 'Mutation'
  queueImportSourceUsersQueue?: { __typename?: 'JobQueue'; id: string; status: JobQueueStatusEnum } | null
}

export type QueueReverseEltJobMutationVariables = Exact<{
  reverseEltId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  syncMode: SyncModeEnum
}>

export type QueueReverseEltJobMutation = {
  __typename?: 'Mutation'
  queueReverseEltJob?: { __typename?: 'JobQueue'; id: string; status: JobQueueStatusEnum } | null
}

export type RecordRenderDurationMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  renderDuration: RenderDurationInput
}>

export type RecordRenderDurationMutation = {
  __typename?: 'Mutation'
  recordRenderDuration?: {
    __typename?: 'RenderDuration'
    organizationId: string
    viewId: string
    viewType: ViewConfigTypeEnum
    duration: number
    trigger: RenderTriggerEnum
    isRecordsCached?: boolean | null
    createdAt: string
    updatedAt: string
  } | null
}

export type ReplaceCustomRolePoliciesMutationVariables = Exact<{
  customRolePolicies: CustomRolePoliciesInput
  organizationId: Scalars['ID']['input']
}>

export type ReplaceCustomRolePoliciesMutation = {
  __typename?: 'Mutation'
  replaceCustomRolePolicies: Array<{
    __typename?: 'Policy'
    id: string
    principal: PolicyPrincipal
    action: PolicyAction
    subject?: PolicySubject | null
    permission: boolean
    createdAt: string
  }>
}

export type SyncHubConnectionMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  syncContext: SyncContextEnum
}>

export type SyncHubConnectionMutation = {
  __typename?: 'Mutation'
  syncConnection?: { __typename?: 'JobQueue'; id: string; status: JobQueueStatusEnum } | null
}

export type SyncModelsMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  connectionModels: Array<SyncModelsInput> | SyncModelsInput
  syncContext: SyncContextEnum
}>

export type SyncModelsMutation = { __typename?: 'Mutation'; syncModels: Array<{ __typename?: 'JobQueue'; id: string }> }

export type TakeSnapshotMutationMutationVariables = Exact<{
  snapshotModelId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  timestamp?: InputMaybe<Scalars['DateTime']['input']>
}>

export type TakeSnapshotMutationMutation = {
  __typename?: 'Mutation'
  takeSnapshot: { __typename?: 'SnapshotSequenceModel'; id: string; createdAt: string }
}

export type UpdateDimensionGroupMutationVariables = Exact<{
  dimensionGroup: DimensionGroupUpdateInput
  organizationId: Scalars['ID']['input']
}>

export type UpdateDimensionGroupMutation = {
  __typename?: 'Mutation'
  updateDimensionGroup?: {
    __typename?: 'DimensionGroup'
    id: string
    label: string
    properties: Array<DimensionGroupProperty>
  } | null
}

export type UpdateEltConfigMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  sourceId: Scalars['ID']['input']
  eltConfig: EltConfigInput
}>

export type UpdateEltConfigMutation = {
  __typename?: 'Mutation'
  updateEltConfig?: {
    __typename?: 'Connection'
    id: string
    organizationId: string
    sourceId: string
    active: boolean
    eltType: ConnectionEltTypeEnum
    createdAt: string
    updatedAt: string
    eltConfig: {
      __typename?: 'EltConfig'
      sourceNamesWhitelist?: Array<string> | null
      sourceConfigs?: Array<{
        __typename?: 'EltConfigSourceConfig'
        sourceName: string
        propertyNamesWhitelist?: Array<string> | null
      }> | null
    }
  } | null
}

export type UpdateEltModelMutationVariables = Exact<{
  eltModel: EltModelUpdateInput
  organizationId: Scalars['ID']['input']
}>

export type UpdateEltModelMutation = {
  __typename?: 'Mutation'
  updateEltModel?: {
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  } | null
}

export type UpdateEntityPolicyMutationVariables = Exact<{
  subjectPolicies: Array<SubjectPoliciesReplaceInput> | SubjectPoliciesReplaceInput
  organizationId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}>

export type UpdateEntityPolicyMutation = {
  __typename?: 'Mutation'
  replaceSubjectPolicies: Array<{
    __typename?: 'Policy'
    id: string
    principal: PolicyPrincipal
    action: PolicyAction
    subject?: PolicySubject | null
    permission: boolean
    createdAt: string
  }>
  replaceViewPermissionUsers: Array<{ __typename?: 'ViewPermissionUser'; id: string; viewId: string; userId: string }>
}

export type UpdateGoalConfigMutationVariables = Exact<{
  goalConfig: GoalConfigUpdateInput
  organizationId: Scalars['ID']['input']
}>

export type UpdateGoalConfigMutation = {
  __typename?: 'Mutation'
  updateGoalConfig?: {
    __typename?: 'GoalConfig'
    id: string
    name: string
    timeSpanType: GoalConfigTimeSpanTypeEnum
    userType: GoalConfigUserTypeEnum
    userIdsConfig: Array<string>
    userGroupIdsConfig: Array<string>
    dashboardViewId?: string | null
    goalDimension1Id?: string | null
    goalDimension2Id?: string | null
    goalDimension3Id?: string | null
    goalDimension4Id?: string | null
    goalDimension5Id?: string | null
    rank: number
    config?: ViewConfig | null
    goalDimension1?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension2?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension3?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension4?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension5?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    dashboardView?: { __typename?: 'GoalConfigDashboardView'; id: string; name: string } | null
    kpiViews: Array<{
      __typename?: 'GoalConfigKpiView'
      id: string
      name: string
      aggregationFunction: GoalConfigKpiViewAggregationFunctionType
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  } | null
}

export type UpdateMyUserMutationVariables = Exact<{
  myUser: MyUserInput
  organizationId: Scalars['ID']['input']
}>

export type UpdateMyUserMutation = {
  __typename?: 'Mutation'
  updateMyUser: { __typename?: 'MyUser'; id: string; name: string; imageUrl?: string | null }
}

export type UpdateOrganizationMutationVariables = Exact<{
  organization: OrganizationInput
  organizationId: Scalars['ID']['input']
}>

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation'
  updateOrganizationV2: {
    __typename?: 'V2Organization'
    id: string
    name: string
    imageUrl?: string | null
    setting: {
      __typename?: 'OrganizationSetting'
      id: string
      monthBeginningDay: number
      accountClosingMonth: number
      userGroupCategoryNames: Array<string>
    }
  }
}

export type UpdatePolicyUserRoleMutationVariables = Exact<{
  policyUserRole: PolicyUserRoleUpdateInput
  organizationId: Scalars['ID']['input']
}>

export type UpdatePolicyUserRoleMutation = {
  __typename?: 'Mutation'
  updatePolicyUserRole: {
    __typename?: 'PolicyUserRole'
    id: string
    organizationId?: string | null
    name: string
    label?: string | null
    description?: string | null
    updatedById?: string | null
    updatedAt?: string | null
    createdAt?: string | null
  }
}

export type UpdateReverseEltMutationVariables = Exact<{
  reverseElt: ReverseEltUpdateInput
  organizationId: Scalars['ID']['input']
}>

export type UpdateReverseEltMutation = {
  __typename?: 'Mutation'
  updateReverseElt: {
    __typename?: 'ReverseElt'
    id: string
    connectionId: string
    destinationId: string
    eltModelId: string
    name: string
    sql: string
    syncedAt?: string | null
    destination: { __typename?: 'Destination'; id: string; name?: string | null; provider: DestinationProviderEnum }
    eltModel: {
      __typename?: 'EltModel'
      id: string
      connectionId?: string | null
      type: EltModelTypeEnum
      name: string
      model: CoreModel
      syncedAt?: string | null
      transformedAt?: string | null
      syncInvalidatedAt?: string | null
      transformInvalidatedAt?: string | null
      transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
    }
  }
}

export type UpdateSalescoreModelMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  modelName: Scalars['String']['input']
  column: Scalars['JSON']['input']
}>

export type UpdateSalescoreModelMutation = {
  __typename?: 'Mutation'
  updateSalescoreModel: {
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  }
}

export type UpdateSheetCustomModelPropertyMutationVariables = Exact<{
  input: UpdateSheetCustomModelPropertyInput
  organizationId: Scalars['ID']['input']
}>

export type UpdateSheetCustomModelPropertyMutation = {
  __typename?: 'Mutation'
  updateSheetCustomModelProperty: {
    __typename?: 'SheetCustomModel'
    id: string
    viewId: string
    nodeName: string
    name: string
    properties: Array<CoreModelProperty>
  }
}

export type UpdateSheetThreadCommentNotificationMutationVariables = Exact<{
  input: SheetThreadCommentNotificationUpdateInput
}>

export type UpdateSheetThreadCommentNotificationMutation = {
  __typename?: 'Mutation'
  updateSheetThreadCommentNotification: {
    __typename?: 'SheetThreadCommentNotification'
    id: string
    status: NotificationStatusEnum
    createdAt: string
    updatedAt: string
    user: {
      __typename?: 'V2User'
      id: string
      name: string
      imageUrl?: string | null
      role: string
      plan: UserPlanEnum
      isManager: boolean
      createdAt: string
      updatedAt: string
      identity: {
        __typename?: 'Identity'
        id: string
        email: string
        provider: IdentityProviderEnum
        createdAt: string
        updatedAt: string
        auth0UserId: string
        name: string
        role: IdentityRoleEnum
      }
      userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
    }
    sheetThreadComment: {
      __typename?: 'SheetThreadComment'
      id: string
      message: string
      createdAt: string
      updatedAt: string
      sheetThread: {
        __typename?: 'SheetThreadWithoutComments'
        id: string
        organizationId: string
        recordId: string
        recordName?: string | null
        modelName?: string | null
        modelLabel?: string | null
        sharedUrl?: {
          __typename?: 'SharedUrl'
          id: string
          organizationId?: string | null
          viewId?: string | null
          parameter: unknown
          type?: ViewConfigTypeEnum | null
          createdById?: string | null
          createdAt?: string | null
          createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
        } | null
      }
      sharedUrl?: {
        __typename?: 'SharedUrl'
        id: string
        organizationId?: string | null
        viewId?: string | null
        parameter: unknown
        type?: ViewConfigTypeEnum | null
        createdById?: string | null
        createdAt?: string | null
        createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
      } | null
      createdBy?: {
        __typename?: 'V2User'
        id: string
        name: string
        imageUrl?: string | null
        role: string
        plan: UserPlanEnum
        isManager: boolean
        createdAt: string
        updatedAt: string
        identity: {
          __typename?: 'Identity'
          id: string
          email: string
          provider: IdentityProviderEnum
          createdAt: string
          updatedAt: string
          auth0UserId: string
          name: string
          role: IdentityRoleEnum
        }
        userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
      } | null
      slackSheetThreadComment?: { __typename?: 'SlackSheetThreadComment'; createdBySlackUserName: string } | null
    }
  }
}

export type UpdateSlackOrgIntegrationMutationVariables = Exact<{
  slackOrgIntegrationUpdateInput: SlackOrgIntegrationUpdateInput
}>

export type UpdateSlackOrgIntegrationMutation = {
  __typename?: 'Mutation'
  updateSlackOrgIntegration: {
    __typename?: 'SlackOrgIntegration'
    id: string
    defaultChannelId?: string | null
    slackUserId: string
    accessTokenExpiresAt?: string | null
    createdAt: string
    integratedBy: { __typename?: 'V2User'; id: string; name: string }
  }
}

export type UpdateSlackUserIntegrationMutationVariables = Exact<{
  slackUserIntegrationUpdateInput: SlackUserIntegrationUpdateInput
}>

export type UpdateSlackUserIntegrationMutation = {
  __typename?: 'Mutation'
  updateSlackUserIntegration: {
    __typename?: 'SlackUserIntegration'
    id: string
    defaultChannelId?: string | null
    slackUserId: string
    accessTokenExpiresAt?: string | null
    createdAt: string
    user: { __typename?: 'V2User'; id: string; name: string }
  }
}

export type UpdateSourceMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  config?: InputMaybe<Scalars['JSON']['input']>
}>

export type UpdateSourceMutation = {
  __typename?: 'Mutation'
  updateSource?: { __typename?: 'Source'; id: string; name?: string | null; config?: unknown | null } | null
}

export type UpdateUserGroupByCsvMutationVariables = Exact<{
  csv: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}>

export type UpdateUserGroupByCsvMutation = {
  __typename?: 'Mutation'
  updateUserGroupByCsv?: {
    __typename?: 'updateUserGroupByCsvResponse'
    successCount: number
    errors: Array<{
      __typename?: 'updateUserGroupByCsvErrorsResponse'
      index: number
      message: string
      record: unknown
    }>
    success: Array<{ __typename?: 'updateUserGroupByCsvSuccessResponse'; index: number; record: unknown }>
  } | null
}

export type UpdateUserGroupIdsByCsvMutationVariables = Exact<{
  csv: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}>

export type UpdateUserGroupIdsByCsvMutation = {
  __typename?: 'Mutation'
  updateUserGroupIdsByCsv?: {
    __typename?: 'updateUserGroupIdByCsvResponse'
    successCount: number
    errors: Array<{
      __typename?: 'updateUserGroupIdsByCsvErrorsResponse'
      index: number
      message: string
      record: unknown
    }>
    success: Array<{ __typename?: 'updateUserGroupIdsByCsvSuccessResponse'; index: number; record: unknown }>
  } | null
}

export type UpdateViewMutationVariables = Exact<{
  view: ViewUpdateInputSingle
  organizationId: Scalars['ID']['input']
}>

export type UpdateViewMutation = {
  __typename?: 'Mutation'
  updateView: {
    __typename?: 'View'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    queries: Array<ViewQuery>
    ui: ViewUI
    config: ViewConfig
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  }
}

export type UpdateViewGroupsMutationVariables = Exact<{
  viewGroups: Array<ViewGroupUpdateInput> | ViewGroupUpdateInput
  organizationId: Scalars['ID']['input']
}>

export type UpdateViewGroupsMutation = {
  __typename?: 'Mutation'
  updateViewGroups?: Array<{
    __typename?: 'ViewGroup'
    id: string
    name: string
    streamName: string
    rank: number
    type: ViewGroupTypeEnum
    viewGroupId?: string | null
    importedSourceId?: string | null
    emoji?: string | null
    private: boolean
    createdById?: string | null
    updatedById?: string | null
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt: string
    updatedAt: string
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  }> | null
}

export type UpsertAllowedIpsMutationVariables = Exact<{
  allowedIps: Array<AllowedIpInput> | AllowedIpInput
  organizationId: Scalars['ID']['input']
}>

export type UpsertAllowedIpsMutation = {
  __typename?: 'Mutation'
  upsertAllowedIps: Array<{ __typename?: 'AllowedIp'; id: string; ip: string; description?: string | null } | null>
}

export type UpsertBusinessDayMutationVariables = Exact<{
  businessDay: BusinessDayInput
  organizationId: Scalars['ID']['input']
}>

export type UpsertBusinessDayMutation = {
  __typename?: 'Mutation'
  upsertBusinessDay?: { __typename?: 'BusinessDay'; id: string; date: string; isBusinessDay: boolean } | null
}

export type UpsertGoalDimensionsMutationVariables = Exact<{
  goalDimensions: Array<GoalDimensionInput> | GoalDimensionInput
  organizationId: Scalars['ID']['input']
}>

export type UpsertGoalDimensionsMutation = {
  __typename?: 'Mutation'
  upsertGoalDimension: Array<{
    __typename?: 'GoalDimension'
    id: string
    name: string
    selectOptions: Array<string>
    selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
  }>
}

export type UpsertGoalsByCsvV2MutationVariables = Exact<{
  csv: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
  goalConfigId: Scalars['ID']['input']
}>

export type UpsertGoalsByCsvV2Mutation = {
  __typename?: 'Mutation'
  upsertGoalsByCsvV2?: {
    __typename?: 'upsertGoalsByCsvV2Response'
    successCount: number
    errors: Array<{ __typename?: 'upsertGoalsByCsvV2ErrorsResponse'; index: number; message: string; record: unknown }>
    success: Array<{ __typename?: 'upsertGoalsByCsvV2SuccessResponse'; index: number; record: unknown }>
  } | null
}

export type UpsertHubConnectionMutationVariables = Exact<{
  input: ConnectionUpsertInput
}>

export type UpsertHubConnectionMutation = {
  __typename?: 'Mutation'
  upsertConnection?: {
    __typename?: 'Connection'
    id: string
    organizationId: string
    sourceId: string
    active: boolean
    eltType: ConnectionEltTypeEnum
    createdAt: string
    updatedAt: string
    eltConfig: {
      __typename?: 'EltConfig'
      sourceNamesWhitelist?: Array<string> | null
      sourceConfigs?: Array<{
        __typename?: 'EltConfigSourceConfig'
        sourceName: string
        propertyNamesWhitelist?: Array<string> | null
      }> | null
    }
  } | null
}

export type UpsertInvitationsMutationVariables = Exact<{
  organizationId: Scalars['ID']['input']
  invitations: Array<InvitationInput> | InvitationInput
}>

export type UpsertInvitationsMutation = {
  __typename?: 'Mutation'
  upsertInvitations: Array<{
    __typename?: 'Invitation'
    id: string
    email: string
    provider: IdentityProviderEnum
    role: string
  }>
}

export type UpsertUserGroupsMutationVariables = Exact<{
  userGroups: Array<UserGroupInput> | UserGroupInput
  organizationId: Scalars['ID']['input']
}>

export type UpsertUserGroupsMutation = {
  __typename?: 'Mutation'
  upsertUserGroups: Array<{
    __typename?: 'UserGroup'
    id: string
    name: string
    image_url?: string | null
    rank: number
    depth: number
    user_group_id?: string | null
    users: Array<{
      __typename?: 'ResourceUser'
      id: string
      name: string
      email: string
      user_group_id?: string | null
      image_url?: string | null
      rank: number
      visibility: boolean
      salescore_user_id?: string | null
      salescore_invitation_id?: string | null
      salesforce_user_id?: string | null
      hubspot_owner_id?: string | null
      hubspot_user_id?: string | null
      zoho_user_id?: string | null
      next_sfa_user_id?: string | null
      google_sheets_user_id?: string | null
      comdesk_user_id?: string | null
      senses_user_id?: string | null
      microsoft_dynamics_user_id?: string | null
      big_query_user_id?: string | null
      kintone_user_id?: string | null
    }>
  }>
}

export type UpsertUsersMutationVariables = Exact<{
  users: Array<UserInputV2> | UserInputV2
  organizationId: Scalars['ID']['input']
}>

export type UpsertUsersMutation = {
  __typename?: 'Mutation'
  upsertUsersV2: Array<{ __typename?: 'V2User'; id: string; role: string }>
}

export type WriteEltChangesChunkMutationVariables = Exact<{
  changesChunks: Array<Scalars['EltChangesChunk']['input']> | Scalars['EltChangesChunk']['input']
  organizationId: Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}>

export type WriteEltChangesChunkMutation = {
  __typename?: 'Mutation'
  writeEltChangesChunk: Array<EltChangeResultsChunk>
}

export type JobQueueFieldsFragment = {
  __typename?: 'JobQueue'
  id: string
  key: string
  jobWorkerId?: string | null
  parentJobQueueId?: string | null
  organizationId?: string | null
  status: JobQueueStatusEnum
  jobName: string
  jobResult?: unknown | null
  jobArg: unknown
  processId?: number | null
  message?: string | null
  priority: number
  createdAt: string
  updatedAt: string
  scheduledAt: string
  startedAt?: string | null
  stoppedAt?: string | null
}

export type JobQueueLogFieldsFragment = {
  __typename?: 'JobQueueLog'
  id: string
  message: string
  tags: Array<string>
  level: JobQueueLogLevelEnum
  jobWorkerId: string
  jobQueueId: string
  createdAt: string
}

export type FetchAllowedIpsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchAllowedIpsQuery = {
  __typename?: 'Query'
  allowedIps: Array<{ __typename?: 'AllowedIp'; id: string; ip: string; description?: string | null }>
}

export type FetchAverageRenderDurationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}>

export type FetchAverageRenderDurationQuery = {
  __typename?: 'Query'
  averageRenderDuration: Array<{
    __typename?: 'AverageRenderDuration'
    viewId: string
    sampleSize: number
    averageDuration?: number | null
    isRecordsCached?: boolean | null
  }>
}

export type FetchBusinessDaysQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchBusinessDaysQuery = {
  __typename?: 'Query'
  businessDays: Array<{ __typename?: 'BusinessDay'; id: string; date: string; isBusinessDay: boolean }>
}

export type FetchConnectionQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  sourceId: Scalars['ID']['input']
}>

export type FetchConnectionQuery = {
  __typename?: 'Query'
  source: {
    __typename?: 'Source'
    id: string
    provider: SourceProviderEnum
    name?: string | null
    config?: unknown | null
    writableWithoutUserSource: boolean
    organizationId: string
    createdAt: string
    updatedAt: string
    connection?: {
      __typename?: 'Connection'
      id: string
      organizationId: string
      sourceId: string
      active: boolean
      eltType: ConnectionEltTypeEnum
      createdAt: string
      updatedAt: string
      eltConfig: {
        __typename?: 'EltConfig'
        sourceNamesWhitelist?: Array<string> | null
        sourceConfigs?: Array<{
          __typename?: 'EltConfigSourceConfig'
          sourceName: string
          propertyNamesWhitelist?: Array<string> | null
        }> | null
      }
    } | null
  }
}

export type FetchConnectionsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchConnectionsQuery = {
  __typename?: 'Query'
  connections: Array<{
    __typename?: 'Connection'
    id: string
    organizationId: string
    sourceId: string
    active: boolean
    eltType: ConnectionEltTypeEnum
    createdAt: string
    updatedAt: string
    source: {
      __typename?: 'Source'
      id: string
      provider: SourceProviderEnum
      name?: string | null
      config?: unknown | null
      writableWithoutUserSource: boolean
      organizationId: string
      createdAt: string
      updatedAt: string
    }
    eltConfig: {
      __typename?: 'EltConfig'
      sourceNamesWhitelist?: Array<string> | null
      sourceConfigs?: Array<{
        __typename?: 'EltConfigSourceConfig'
        sourceName: string
        propertyNamesWhitelist?: Array<string> | null
      }> | null
    }
  }>
}

export type FetchConnectionsWithStreamsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchConnectionsWithStreamsQuery = {
  __typename?: 'Query'
  connections: Array<{
    __typename?: 'Connection'
    id: string
    organizationId: string
    sourceId: string
    active: boolean
    eltType: ConnectionEltTypeEnum
    createdAt: string
    updatedAt: string
    source: {
      __typename?: 'Source'
      id: string
      provider: SourceProviderEnum
      name?: string | null
      config?: unknown | null
      writableWithoutUserSource: boolean
      organizationId: string
      createdAt: string
      updatedAt: string
    }
    streams: Array<{
      __typename?: 'Stream'
      id?: string | null
      name: string
      customized?: boolean | null
      filtered?: boolean | null
      source?: { __typename?: 'StreamSource'; name: string; filters?: Array<string> | null } | null
      destination: {
        __typename?: 'StreamDestination'
        name: string
        filters?: Array<string> | null
        viewDefinitionSQL?: string | null
        materialized?: boolean | null
        derivedTableDefinition?: {
          __typename?: 'StreamDestinationDerivedTableDefinition'
          sql: string
          deleteSql: string
          deleteKey: string
          primaryKey?: string | null
          dependentStreams: Array<{ __typename?: 'DependentStream'; sourceName: string; streamName: string }>
        } | null
      }
      meta: {
        __typename?: 'StreamMeta'
        label: string
        creatable?: boolean | null
        updatable?: boolean | null
        deletable?: boolean | null
      }
      properties: Array<{
        __typename?: 'Property'
        name: string
        customized?: boolean | null
        source?: {
          __typename?: 'PropertySource'
          name: string
          meta?: {
            __typename?: 'PropertySourceMeta'
            type?: string | null
            meta?: string | null
            referenceTo?: Array<string> | null
          } | null
        } | null
        destination: { __typename?: 'PropertyDestination'; name: string; sql: string; type: PropertyTypeEnum }
        meta: {
          __typename?: 'PropertyMeta'
          label: string
          meta?: PropertyMetaEnum | null
          typeForMigration?: PropertyTypeForMigrationEnum | null
          referenceTo?: Array<string> | null
          creatable?: boolean | null
          updatable?: boolean | null
          selectOptions?: Array<{
            __typename?: 'PropertySelectOption'
            label: string
            value: string
            color?: string | null
            icon?: string | null
            rule?: ValidationFunction | null
          }> | null
          validations?: Array<{
            __typename?: 'ValidationRule'
            function: ValidationFunction
            message: string
            feedbackProperties: Array<{
              __typename?: 'PropertyNameWithStreamName'
              streamName: string
              propertyName: string
            }>
          }> | null
        }
      }>
    }>
    eltConfig: {
      __typename?: 'EltConfig'
      sourceNamesWhitelist?: Array<string> | null
      sourceConfigs?: Array<{
        __typename?: 'EltConfigSourceConfig'
        sourceName: string
        propertyNamesWhitelist?: Array<string> | null
      }> | null
    }
  }>
}

export type FetchConvertingSalesforceLeadsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  leadIds: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type FetchConvertingSalesforceLeadsQuery = {
  __typename?: 'Query'
  convertingSalesforceLeads: {
    __typename?: 'ConvertingSalesforceLeadRelated'
    leads: Array<{
      __typename?: 'ConvertingSalesforceLead'
      id: string
      accountName?: string | null
      lastName?: string | null
      firstName?: string | null
      isConverted: boolean
      candidateAccounts: Array<{
        __typename?: 'ConvertingSalesforceLeadAccountCandidate'
        id: string
        name: string
        billingAddress?: string | null
        website?: string | null
        phone?: string | null
        ownerName?: string | null
      }>
      candidateContacts: Array<{
        __typename?: 'ConvertingSalesforceLeadContactCandidate'
        id: string
        firstName: string
        lastName: string
        title?: string | null
        accountName?: string | null
        phone?: string | null
        email?: string | null
        ownerName?: string | null
      }>
    }>
    leadStatuses: Array<{ __typename?: 'SalesforceLeadStatus'; id: string; label: string; value: string }>
  }
}

export type FetchDimensionGroupsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchDimensionGroupsQuery = {
  __typename?: 'Query'
  models: Array<CoreModel>
  dimensionGroups: Array<{
    __typename?: 'DimensionGroup'
    id: string
    label: string
    properties: Array<DimensionGroupProperty>
  }>
  goalConfigs: Array<{
    __typename?: 'GoalConfig'
    id: string
    name: string
    timeSpanType: GoalConfigTimeSpanTypeEnum
    userType: GoalConfigUserTypeEnum
    userIdsConfig: Array<string>
    userGroupIdsConfig: Array<string>
    dashboardViewId?: string | null
    goalDimension1Id?: string | null
    goalDimension2Id?: string | null
    goalDimension3Id?: string | null
    goalDimension4Id?: string | null
    goalDimension5Id?: string | null
    rank: number
    config?: ViewConfig | null
    goalDimension1?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension2?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension3?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension4?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension5?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    dashboardView?: { __typename?: 'GoalConfigDashboardView'; id: string; name: string } | null
    kpiViews: Array<{
      __typename?: 'GoalConfigKpiView'
      id: string
      name: string
      aggregationFunction: GoalConfigKpiViewAggregationFunctionType
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  }>
  goalDimensions: Array<{
    __typename?: 'GoalDimension'
    id: string
    name: string
    label: string
    selectOptions: Array<string>
    selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
  }>
}

export type FetchEltModelQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  name: Scalars['String']['input']
}>

export type FetchEltModelQuery = {
  __typename?: 'Query'
  eltModel?: {
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  } | null
}

export type FetchEltModelsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchEltModelsQuery = {
  __typename?: 'Query'
  eltModels: Array<{
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  }>
}

export type FetchFavoritesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchFavoritesQuery = {
  __typename?: 'Query'
  favorites: Array<{
    __typename?: 'Favorite'
    id: string
    viewGroupId?: string | null
    viewId?: string | null
    rank: number
  }>
}

export type FetchGoalConfigFormRelatedQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchGoalConfigFormRelatedQuery = {
  __typename?: 'Query'
  goalDimensions: Array<{
    __typename?: 'GoalDimension'
    id: string
    name: string
    label: string
    selectOptions: Array<string>
    selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
  }>
  viewsForSider: Array<{
    __typename?: 'ViewForSider'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
  }>
}

export type FetchGoalConfigsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  viewId?: InputMaybe<Scalars['ID']['input']>
}>

export type FetchGoalConfigsQuery = {
  __typename?: 'Query'
  goalConfigs: Array<{
    __typename?: 'GoalConfig'
    id: string
    name: string
    timeSpanType: GoalConfigTimeSpanTypeEnum
    userType: GoalConfigUserTypeEnum
    userIdsConfig: Array<string>
    userGroupIdsConfig: Array<string>
    dashboardViewId?: string | null
    goalDimension1Id?: string | null
    goalDimension2Id?: string | null
    goalDimension3Id?: string | null
    goalDimension4Id?: string | null
    goalDimension5Id?: string | null
    rank: number
    config?: ViewConfig | null
    goalDimension1?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension2?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension3?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension4?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    goalDimension5?: {
      __typename?: 'GoalDimension'
      id: string
      name: string
      label: string
      selectOptions: Array<string>
      selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
    } | null
    dashboardView?: { __typename?: 'GoalConfigDashboardView'; id: string; name: string } | null
    kpiViews: Array<{
      __typename?: 'GoalConfigKpiView'
      id: string
      name: string
      aggregationFunction: GoalConfigKpiViewAggregationFunctionType
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
  }>
  userGroups: Array<{
    __typename?: 'UserGroup'
    id: string
    name: string
    image_url?: string | null
    rank: number
    depth: number
    user_group_id?: string | null
    users: Array<{
      __typename?: 'ResourceUser'
      id: string
      name: string
      email: string
      user_group_id?: string | null
      image_url?: string | null
      rank: number
      visibility: boolean
      salescore_user_id?: string | null
      salescore_invitation_id?: string | null
      salesforce_user_id?: string | null
      hubspot_owner_id?: string | null
      hubspot_user_id?: string | null
      zoho_user_id?: string | null
      next_sfa_user_id?: string | null
      google_sheets_user_id?: string | null
      comdesk_user_id?: string | null
      senses_user_id?: string | null
      microsoft_dynamics_user_id?: string | null
      big_query_user_id?: string | null
      kintone_user_id?: string | null
    }>
  }>
}

export type FetchGoalDimensionsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchGoalDimensionsQuery = {
  __typename?: 'Query'
  goalDimensions: Array<{
    __typename?: 'GoalDimension'
    id: string
    name: string
    label: string
    selectOptions: Array<string>
    selectOptionsV3: Array<{ __typename?: 'GoalDimensionSelectOption'; value: string; label: string }>
  }>
}

export type FetchI18nSourcesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchI18nSourcesQuery = {
  __typename?: 'Query'
  i18nSources: Array<{
    __typename?: 'I18nSource'
    id: string
    organizationId: string
    provider: SourceProviderEnum
    name?: string | null
    lang: LangEnum
    createdAt: string
    updatedAt: string
  }>
}

export type FetchImportButtonParamQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}>

export type FetchImportButtonParamQuery = {
  __typename?: 'Query'
  viewOldestSyncTime?: string | null
  viewHasHubspot: boolean
  viewSyncResult: {
    __typename?: 'ViewSyncResult'
    success: boolean
    failedEltModels: Array<{
      __typename?: 'EltModel'
      id: string
      name: string
      model: CoreModel
      type: EltModelTypeEnum
    }>
  }
  viewConnections: Array<{
    __typename?: 'Connection'
    id: string
    organizationId: string
    sourceId: string
    active: boolean
    eltType: ConnectionEltTypeEnum
    createdAt: string
    updatedAt: string
    source: {
      __typename?: 'Source'
      id: string
      provider: SourceProviderEnum
      name?: string | null
      config?: unknown | null
      writableWithoutUserSource: boolean
      organizationId: string
      createdAt: string
      updatedAt: string
    }
    eltConfig: {
      __typename?: 'EltConfig'
      sourceNamesWhitelist?: Array<string> | null
      sourceConfigs?: Array<{
        __typename?: 'EltConfigSourceConfig'
        sourceName: string
        propertyNamesWhitelist?: Array<string> | null
      }> | null
    }
  }>
  viewRelatedModels: Array<{
    __typename?: 'ViewRelatedModel'
    connection: {
      __typename?: 'Connection'
      id: string
      organizationId: string
      sourceId: string
      active: boolean
      eltType: ConnectionEltTypeEnum
      createdAt: string
      updatedAt: string
      source: {
        __typename?: 'Source'
        id: string
        provider: SourceProviderEnum
        name?: string | null
        config?: unknown | null
        writableWithoutUserSource: boolean
        organizationId: string
        createdAt: string
        updatedAt: string
      }
      eltConfig: {
        __typename?: 'EltConfig'
        sourceNamesWhitelist?: Array<string> | null
        sourceConfigs?: Array<{
          __typename?: 'EltConfigSourceConfig'
          sourceName: string
          propertyNamesWhitelist?: Array<string> | null
        }> | null
      }
    }
    eltModels: Array<{
      __typename?: 'EltModel'
      id: string
      connectionId?: string | null
      type: EltModelTypeEnum
      name: string
      model: CoreModel
      syncedAt?: string | null
      transformedAt?: string | null
      syncInvalidatedAt?: string | null
      transformInvalidatedAt?: string | null
      transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
    }>
  }>
}

export type FetchInvitationsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchInvitationsQuery = {
  __typename?: 'Query'
  invitations: Array<{
    __typename?: 'Invitation'
    id: string
    email: string
    provider: IdentityProviderEnum
    role: string
    planForSync: UserPlanEnum
    planForVis: UserPlanEnum
  }>
  users: Array<{
    __typename?: 'V2User'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    plan: UserPlanEnum
    isManager: boolean
    createdAt: string
    updatedAt: string
    identity: {
      __typename?: 'Identity'
      id: string
      email: string
      provider: IdentityProviderEnum
      createdAt: string
      updatedAt: string
      auth0UserId: string
      name: string
      role: IdentityRoleEnum
    }
    userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
  }>
  subscriptionStatus: Array<{
    __typename?: 'SubscriptionStatus'
    numberOfSeats: number
    numberOfRestSeats: number
    numberOfUsers: number
    numberOfInvitations: number
    numberOfFreeUsers: number
    numberOfFreeInvitations: number
    isInvitationFreeOrganization: boolean
    isInvitationCreatable: boolean
    subscription: { __typename?: 'SalescoreSubscription'; id: string; plan: SubscriptionPlanEnum }
  }>
  organization: {
    __typename?: 'V2Organization'
    id: string
    name: string
    status: OrganizationStatusEnum
    imageUrl?: string | null
    createdAt: string
    isEarlyReleaseTarget: boolean
    setting: {
      __typename?: 'OrganizationSetting'
      id: string
      accountClosingMonth: number
      monthBeginningDay: number
      userGroupCategoryNames: Array<string>
      csvDownloadEnabledForMember: boolean
      miitelCompanyId?: string | null
      miitelAccessKey?: string | null
      shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
      i18nEnabled: boolean
      viewSiderVisibility: boolean
      defaultOpenViewIds: Array<string>
      shouldJoinRecordsInApplication: boolean
      shouldIgnoreInactiveSourceUser: boolean
      disableEditUsername: boolean
    }
    dimensionGroups: Array<{
      __typename?: 'DimensionGroup'
      id: string
      label: string
      properties: Array<DimensionGroupProperty>
    }>
    businessDays: Array<{ __typename?: 'BusinessDay'; id: string; date: string; isBusinessDay: boolean }>
    organizationFeatures: Array<{ __typename?: 'OrganizationFeature'; feature: string }>
    organizationPlans: Array<{ __typename?: 'OrganizationPlan'; license: string; plan: OrganizationPlanTypeEnum }>
  }
}

export type FetchJobProcessingQueuesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type FetchJobProcessingQueuesQuery = {
  __typename?: 'Query'
  jobProcessingQueues: Array<{ __typename?: 'JobQueue'; id: string }>
}

export type FetchJobQueueQueryVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type FetchJobQueueQuery = {
  __typename?: 'Query'
  jobQueue: {
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobResult?: unknown | null
    jobArg: unknown
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
    jobQueueLogs: Array<{
      __typename?: 'JobQueueLog'
      id: string
      message: string
      tags: Array<string>
      level: JobQueueLogLevelEnum
      jobWorkerId: string
      jobQueueId: string
      createdAt: string
    }>
  }
}

export type FetchJobQueuesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  jobName?: InputMaybe<Scalars['String']['input']>
}>

export type FetchJobQueuesQuery = {
  __typename?: 'Query'
  jobQueues: Array<{
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobResult?: unknown | null
    jobArg: unknown
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
  }>
}

export type FetchJobStatusesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  jobName?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}>

export type FetchJobStatusesQuery = {
  __typename?: 'Query'
  jobStatuses: Array<{
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobResult?: unknown | null
    jobArg: unknown
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
  }>
}

export type FetchMeQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchMeQuery = {
  __typename?: 'Query'
  myUser: {
    __typename?: 'MyUser'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    plan: UserPlanEnum
    lang?: LangEnum | null
    createdAt: string
    identity: {
      __typename?: 'MyIdentity'
      id: string
      name: string
      email: string
      users: Array<{
        __typename?: 'MyUser'
        id: string
        name: string
        organization: {
          __typename?: 'V2Organization'
          id: string
          name: string
          imageUrl?: string | null
          createdAt: string
        }
      }>
    }
    userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
    organization: {
      __typename?: 'V2Organization'
      id: string
      name: string
      status: OrganizationStatusEnum
      imageUrl?: string | null
      createdAt: string
      isEarlyReleaseTarget: boolean
      setting: {
        __typename?: 'OrganizationSetting'
        id: string
        accountClosingMonth: number
        monthBeginningDay: number
        userGroupCategoryNames: Array<string>
        csvDownloadEnabledForMember: boolean
        miitelCompanyId?: string | null
        miitelAccessKey?: string | null
        shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
        i18nEnabled: boolean
        viewSiderVisibility: boolean
        defaultOpenViewIds: Array<string>
        shouldJoinRecordsInApplication: boolean
        shouldIgnoreInactiveSourceUser: boolean
        disableEditUsername: boolean
      }
      dimensionGroups: Array<{
        __typename?: 'DimensionGroup'
        id: string
        label: string
        properties: Array<DimensionGroupProperty>
      }>
      businessDays: Array<{ __typename?: 'BusinessDay'; id: string; date: string; isBusinessDay: boolean }>
      policies: Array<{
        __typename?: 'Policy'
        id: string
        principal: PolicyPrincipal
        action: PolicyAction
        subject?: PolicySubject | null
        permission: boolean
        createdAt: string
      }>
      organizationFeatures: Array<{ __typename?: 'OrganizationFeature'; feature: string }>
      organizationPlans: Array<{ __typename?: 'OrganizationPlan'; license: string; plan: OrganizationPlanTypeEnum }>
    }
    resourceUser?: {
      __typename?: 'ResourceUser'
      id: string
      name: string
      email: string
      user_group_id?: string | null
      image_url?: string | null
      rank: number
      visibility: boolean
      salescore_user_id?: string | null
      salescore_invitation_id?: string | null
      salesforce_user_id?: string | null
      hubspot_owner_id?: string | null
      hubspot_user_id?: string | null
      zoho_user_id?: string | null
      next_sfa_user_id?: string | null
      google_sheets_user_id?: string | null
      comdesk_user_id?: string | null
      senses_user_id?: string | null
      microsoft_dynamics_user_id?: string | null
      big_query_user_id?: string | null
      kintone_user_id?: string | null
    } | null
  }
}

export type FetchMixedUsersQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchMixedUsersQuery = {
  __typename?: 'Query'
  mixedUsers: Array<{
    __typename?: 'MixedUser'
    id: string
    resourceUser: {
      __typename?: 'ResourceUser'
      id: string
      name: string
      email: string
      user_group_id?: string | null
      image_url?: string | null
      rank: number
      visibility: boolean
      salescore_user_id?: string | null
      salescore_invitation_id?: string | null
      salesforce_user_id?: string | null
      hubspot_owner_id?: string | null
      hubspot_user_id?: string | null
      zoho_user_id?: string | null
      next_sfa_user_id?: string | null
      google_sheets_user_id?: string | null
      comdesk_user_id?: string | null
      senses_user_id?: string | null
      microsoft_dynamics_user_id?: string | null
      big_query_user_id?: string | null
      kintone_user_id?: string | null
    }
    user?: {
      __typename?: 'V2User'
      id: string
      name: string
      imageUrl?: string | null
      role: string
      plan: UserPlanEnum
      isManager: boolean
      createdAt: string
      updatedAt: string
      identity: {
        __typename?: 'Identity'
        id: string
        email: string
        provider: IdentityProviderEnum
        createdAt: string
        updatedAt: string
        auth0UserId: string
        name: string
        role: IdentityRoleEnum
      }
      userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
    } | null
    invitation?: {
      __typename?: 'Invitation'
      id: string
      email: string
      provider: IdentityProviderEnum
      role: string
      planForSync: UserPlanEnum
      planForVis: UserPlanEnum
    } | null
  }>
}

export type FetchModelsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchModelsQuery = { __typename?: 'Query'; models: Array<CoreModel> }

export type FetchMyIdentityQueryVariables = Exact<{ [key: string]: never }>

export type FetchMyIdentityQuery = {
  __typename?: 'Query'
  myIdentity?: {
    __typename?: 'MyIdentity'
    id: string
    name: string
    email: string
    users: Array<{
      __typename?: 'MyUser'
      id: string
      name: string
      role: string
      organization: {
        __typename?: 'V2Organization'
        id: string
        name: string
        createdAt: string
        imageUrl?: string | null
      }
    }>
  } | null
}

export type FetchOauthAuthorizationUrlQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['ID']['input']>
  provider: Scalars['String']['input']
  authorizeUrl?: InputMaybe<Scalars['String']['input']>
  clientId?: InputMaybe<Scalars['String']['input']>
  sourceId?: InputMaybe<Scalars['String']['input']>
  additionalScope?: InputMaybe<Scalars['String']['input']>
}>

export type FetchOauthAuthorizationUrlQuery = { __typename?: 'Query'; oauthAuthorizationUrl: string }

export type FetchOrganizationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchOrganizationQuery = {
  __typename?: 'Query'
  organization: {
    __typename?: 'V2Organization'
    id: string
    name: string
    status: OrganizationStatusEnum
    imageUrl?: string | null
    createdAt: string
    isEarlyReleaseTarget: boolean
    setting: {
      __typename?: 'OrganizationSetting'
      id: string
      accountClosingMonth: number
      monthBeginningDay: number
      userGroupCategoryNames: Array<string>
      csvDownloadEnabledForMember: boolean
      miitelCompanyId?: string | null
      miitelAccessKey?: string | null
      shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
      i18nEnabled: boolean
      viewSiderVisibility: boolean
      defaultOpenViewIds: Array<string>
      shouldJoinRecordsInApplication: boolean
      shouldIgnoreInactiveSourceUser: boolean
      disableEditUsername: boolean
    }
    dimensionGroups: Array<{
      __typename?: 'DimensionGroup'
      id: string
      label: string
      properties: Array<DimensionGroupProperty>
    }>
    businessDays: Array<{ __typename?: 'BusinessDay'; id: string; date: string; isBusinessDay: boolean }>
    organizationFeatures: Array<{ __typename?: 'OrganizationFeature'; feature: string }>
    organizationPlans: Array<{ __typename?: 'OrganizationPlan'; license: string; plan: OrganizationPlanTypeEnum }>
  }
  subscriptionStatus: Array<{
    __typename?: 'SubscriptionStatus'
    numberOfSeats: number
    numberOfRestSeats: number
    numberOfUsers: number
    numberOfInvitations: number
    numberOfFreeUsers: number
    numberOfFreeInvitations: number
    isInvitationFreeOrganization: boolean
    isInvitationCreatable: boolean
    subscription: { __typename?: 'SalescoreSubscription'; id: string; plan: SubscriptionPlanEnum }
  }>
}

export type FetchOrganizationSettingQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchOrganizationSettingQuery = {
  __typename?: 'Query'
  organization: {
    __typename?: 'V2Organization'
    id: string
    setting: {
      __typename?: 'OrganizationSetting'
      id: string
      accountClosingMonth: number
      monthBeginningDay: number
      userGroupCategoryNames: Array<string>
      csvDownloadEnabledForMember: boolean
      miitelCompanyId?: string | null
      miitelAccessKey?: string | null
      shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
      i18nEnabled: boolean
      viewSiderVisibility: boolean
      defaultOpenViewIds: Array<string>
      shouldJoinRecordsInApplication: boolean
      shouldIgnoreInactiveSourceUser: boolean
      disableEditUsername: boolean
    }
  }
}

export type FetchPoliciesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  subjectId?: InputMaybe<Scalars['String']['input']>
  subjectType?: InputMaybe<Scalars['String']['input']>
  action?: InputMaybe<Scalars['String']['input']>
}>

export type FetchPoliciesQuery = {
  __typename?: 'Query'
  policies: Array<{
    __typename?: 'Policy'
    id: string
    principal: PolicyPrincipal
    action: PolicyAction
    subject?: PolicySubject | null
    permission: boolean
    createdAt: string
  }>
}

export type FetchPolicyUserRolesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchPolicyUserRolesQuery = {
  __typename?: 'Query'
  policyUserRoles: Array<{
    __typename?: 'PolicyUserRole'
    id: string
    organizationId?: string | null
    name: string
    label?: string | null
    description?: string | null
    updatedById?: string | null
    updatedAt?: string | null
    createdAt?: string | null
  }>
}

export type FetchPropertiesBoilerplateQueryVariables = Exact<{
  sql: Scalars['String']['input']
  shouldResolveId: Scalars['Boolean']['input']
  organizationId: Scalars['ID']['input']
}>

export type FetchPropertiesBoilerplateQuery = { __typename?: 'Query'; propertiesBoilerplate: Array<CoreModelProperty> }

export type FetchQueryResultsQueryVariables = Exact<{
  queries: Scalars['JSON']['input']
  organizationId: Scalars['ID']['input']
}>

export type FetchQueryResultsQuery = {
  __typename?: 'Query'
  queryResults: Array<{
    __typename?: 'QueryResult'
    query: unknown
    sql?: string | null
    fieldLabels?: Array<string | null> | null
    error?: string | null
    result?: {
      __typename?: 'PostgresQueryResult'
      rowCount?: number | null
      rows?: unknown | null
      fields?: Array<{ __typename?: 'PostgresQueryResultField'; name: string }> | null
    } | null
  }>
}

export type FetchRecordChangeHistoriesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  recordId: Scalars['String']['input']
  modelName: Scalars['String']['input']
  propertyNames: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type FetchRecordChangeHistoriesQuery = {
  __typename?: 'Query'
  recordChangeHistories: Array<{
    __typename?: 'RecordChangeHistory'
    changedById: string
    changedByName?: string | null
    changedAt: string
    oldValue?: string | null
    newValue?: string | null
    propertyName: string
  }>
}

export type FetchResourceUsersWithGroupQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchResourceUsersWithGroupQuery = {
  __typename?: 'Query'
  resourceUsersWithGroup: Array<{
    __typename?: 'ResourceUserWithGroup'
    id: string
    name: string
    email: string
    user_group_id?: string | null
    image_url?: string | null
    rank: number
    visibility: boolean
    user_group_d1_id?: string | null
    user_group_d1_name?: string | null
    user_group_d2_id?: string | null
    user_group_d2_name?: string | null
    user_group_d3_id?: string | null
    user_group_d3_name?: string | null
    user_group_d4_id?: string | null
    user_group_d4_name?: string | null
    user_group_d5_id?: string | null
    user_group_d5_name?: string | null
    user_group_depth?: number | null
  }>
}

export type FetchReverseEltsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchReverseEltsQuery = {
  __typename?: 'Query'
  reverseElts: Array<{
    __typename?: 'ReverseElt'
    id: string
    connectionId: string
    destinationId: string
    eltModelId: string
    name: string
    sql: string
    syncedAt?: string | null
    destination: { __typename?: 'Destination'; id: string; name?: string | null; provider: DestinationProviderEnum }
    eltModel: {
      __typename?: 'EltModel'
      id: string
      connectionId?: string | null
      type: EltModelTypeEnum
      name: string
      model: CoreModel
      syncedAt?: string | null
      transformedAt?: string | null
      syncInvalidatedAt?: string | null
      transformInvalidatedAt?: string | null
      transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
    }
  }>
  destinations: Array<{
    __typename?: 'Destination'
    id: string
    name?: string | null
    provider: DestinationProviderEnum
  }>
  eltModels: Array<{
    __typename?: 'EltModel'
    id: string
    connectionId?: string | null
    type: EltModelTypeEnum
    name: string
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: { __typename?: 'EltModelTransform'; sql: string; type: EltModelTransformTypeEnum } | null
  }>
}

export type FetchS3SignedUrlQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  s3ObjectPath: Scalars['String']['input']
}>

export type FetchS3SignedUrlQuery = { __typename?: 'Query'; s3SignedUrl: string }

export type FetchSalescoreConnectionQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchSalescoreConnectionQuery = {
  __typename?: 'Query'
  salescoreConnection: {
    __typename?: 'Connection'
    id: string
    organizationId: string
    sourceId: string
    active: boolean
    eltType: ConnectionEltTypeEnum
    createdAt: string
    updatedAt: string
    source: {
      __typename?: 'Source'
      id: string
      provider: SourceProviderEnum
      name?: string | null
      config?: unknown | null
      writableWithoutUserSource: boolean
      organizationId: string
      createdAt: string
      updatedAt: string
    }
    eltConfig: {
      __typename?: 'EltConfig'
      sourceNamesWhitelist?: Array<string> | null
      sourceConfigs?: Array<{
        __typename?: 'EltConfigSourceConfig'
        sourceName: string
        propertyNamesWhitelist?: Array<string> | null
      }> | null
    }
  }
}

export type FetchSalescoreJwtQueryVariables = Exact<{
  instanceUrl?: InputMaybe<Scalars['String']['input']>
  sessionToken?: InputMaybe<Scalars['String']['input']>
  type: Scalars['String']['input']
  oauthCode?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
}>

export type FetchSalescoreJwtQuery = { __typename?: 'Query'; salescoreJwt: string }

export type FetchSamlConfigsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchSamlConfigsQuery = {
  __typename?: 'Query'
  samlConfigs: Array<{
    __typename?: 'SamlConfig'
    id: string
    organizationId: string
    label: string
    icon?: string | null
  }>
}

export type FetchSharedUrlQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}>

export type FetchSharedUrlQuery = {
  __typename?: 'Query'
  sharedUrl?: {
    __typename?: 'SharedUrl'
    id: string
    viewId?: string | null
    type?: ViewConfigTypeEnum | null
    parameter: unknown
    sheetThreadComment?: {
      __typename?: 'SheetThreadComment'
      id: string
      createdAt: string
      message: string
      updatedAt: string
      sheetThread: {
        __typename?: 'SheetThreadWithoutComments'
        id: string
        organizationId: string
        recordId: string
        recordName?: string | null
      }
    } | null
  } | null
}

export type FetchSheetCustomModelsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  viewId: Scalars['String']['input']
}>

export type FetchSheetCustomModelsQuery = {
  __typename?: 'Query'
  sheetCustomModels: Array<{
    __typename?: 'SheetCustomModel'
    id: string
    viewId: string
    nodeName: string
    name: string
    properties: Array<CoreModelProperty>
  }>
}

export type SheetThreadCommentNotificationsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type SheetThreadCommentNotificationsQuery = {
  __typename?: 'Query'
  sheetThreadCommentNotifications: Array<{
    __typename?: 'SheetThreadCommentNotification'
    id: string
    status: NotificationStatusEnum
    createdAt: string
    updatedAt: string
    user: {
      __typename?: 'V2User'
      id: string
      name: string
      imageUrl?: string | null
      role: string
      plan: UserPlanEnum
      isManager: boolean
      createdAt: string
      updatedAt: string
      identity: {
        __typename?: 'Identity'
        id: string
        email: string
        provider: IdentityProviderEnum
        createdAt: string
        updatedAt: string
        auth0UserId: string
        name: string
        role: IdentityRoleEnum
      }
      userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
    }
    sheetThreadComment: {
      __typename?: 'SheetThreadComment'
      id: string
      message: string
      createdAt: string
      updatedAt: string
      sheetThread: {
        __typename?: 'SheetThreadWithoutComments'
        id: string
        organizationId: string
        recordId: string
        recordName?: string | null
        modelName?: string | null
        modelLabel?: string | null
        sharedUrl?: {
          __typename?: 'SharedUrl'
          id: string
          organizationId?: string | null
          viewId?: string | null
          parameter: unknown
          type?: ViewConfigTypeEnum | null
          createdById?: string | null
          createdAt?: string | null
          createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
        } | null
      }
      sharedUrl?: {
        __typename?: 'SharedUrl'
        id: string
        organizationId?: string | null
        viewId?: string | null
        parameter: unknown
        type?: ViewConfigTypeEnum | null
        createdById?: string | null
        createdAt?: string | null
        createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
      } | null
      createdBy?: {
        __typename?: 'V2User'
        id: string
        name: string
        imageUrl?: string | null
        role: string
        plan: UserPlanEnum
        isManager: boolean
        createdAt: string
        updatedAt: string
        identity: {
          __typename?: 'Identity'
          id: string
          email: string
          provider: IdentityProviderEnum
          createdAt: string
          updatedAt: string
          auth0UserId: string
          name: string
          role: IdentityRoleEnum
        }
        userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
      } | null
      slackSheetThreadComment?: { __typename?: 'SlackSheetThreadComment'; createdBySlackUserName: string } | null
    }
  }>
}

export type SheetThreadsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  recordIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type SheetThreadsQuery = {
  __typename?: 'Query'
  sheetThreads: Array<{
    __typename?: 'SheetThread'
    id: string
    organizationId: string
    recordId: string
    recordName?: string | null
    createdAt: string
    updatedAt: string
    comments: Array<{
      __typename?: 'SheetThreadComment'
      id: string
      message: string
      createdAt: string
      updatedAt: string
      sheetThread: {
        __typename?: 'SheetThreadWithoutComments'
        id: string
        organizationId: string
        recordId: string
        recordName?: string | null
        modelName?: string | null
        modelLabel?: string | null
        sharedUrl?: {
          __typename?: 'SharedUrl'
          id: string
          organizationId?: string | null
          viewId?: string | null
          parameter: unknown
          type?: ViewConfigTypeEnum | null
          createdById?: string | null
          createdAt?: string | null
          createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
        } | null
      }
      sharedUrl?: {
        __typename?: 'SharedUrl'
        id: string
        organizationId?: string | null
        viewId?: string | null
        parameter: unknown
        type?: ViewConfigTypeEnum | null
        createdById?: string | null
        createdAt?: string | null
        createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
      } | null
      createdBy?: {
        __typename?: 'V2User'
        id: string
        name: string
        imageUrl?: string | null
        role: string
        plan: UserPlanEnum
        isManager: boolean
        createdAt: string
        updatedAt: string
        identity: {
          __typename?: 'Identity'
          id: string
          email: string
          provider: IdentityProviderEnum
          createdAt: string
          updatedAt: string
          auth0UserId: string
          name: string
          role: IdentityRoleEnum
        }
        userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
      } | null
      slackSheetThreadComment?: { __typename?: 'SlackSheetThreadComment'; createdBySlackUserName: string } | null
    } | null>
    sharedUrl?: {
      __typename?: 'SharedUrl'
      id: string
      organizationId?: string | null
      viewId?: string | null
      parameter: unknown
      type?: ViewConfigTypeEnum | null
      createdById?: string | null
      createdAt?: string | null
      createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    } | null
  }>
}

export type FetchSlackBotJoinedChannelQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  channelId?: InputMaybe<Scalars['ID']['input']>
}>

export type FetchSlackBotJoinedChannelQuery = {
  __typename?: 'Query'
  slackBotJoinedChannel: { __typename?: 'SlackBotJoinedChannel'; status: boolean }
}

export type FetchSlackChannelsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchSlackChannelsQuery = {
  __typename?: 'Query'
  slackChannels: Array<{
    __typename?: 'SlackChannel'
    id: string
    name: string
    numMembers: number
    topic?: string | null
    purpose?: string | null
  }>
}

export type FetchSlackOrgIntegrationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchSlackOrgIntegrationQuery = {
  __typename?: 'Query'
  slackOrgIntegration?: {
    __typename?: 'SlackOrgIntegration'
    id: string
    slackUserId: string
    defaultChannelId?: string | null
  } | null
}

export type FetchSlackOrgIntegrationUrlQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchSlackOrgIntegrationUrlQuery = { __typename?: 'Query'; slackOrgIntegrationUrl: string }

export type FetchSlackUserIntegrationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchSlackUserIntegrationQuery = {
  __typename?: 'Query'
  slackUserIntegration?: {
    __typename?: 'SlackUserIntegration'
    id: string
    slackUserId: string
    defaultChannelId?: string | null
  } | null
}

export type FetchSlackUserIntegrationUrlQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchSlackUserIntegrationUrlQuery = { __typename?: 'Query'; slackUserIntegrationUrl: string }

export type FetchSourceQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}>

export type FetchSourceQuery = {
  __typename?: 'Query'
  source: {
    __typename?: 'Source'
    id: string
    provider: SourceProviderEnum
    name?: string | null
    config?: unknown | null
    writableWithoutUserSource: boolean
    organizationId: string
    createdAt: string
    updatedAt: string
  }
}

export type FetchSourceEntityNamesQueryVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}>

export type FetchSourceEntityNamesQuery = {
  __typename?: 'Query'
  sourceEntityNames: Array<{ __typename?: 'SourceEntityName'; name: string; label: string; forcePick?: boolean | null }>
}

export type FetchSourceEntitySchemasQueryVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  targetEntityNames: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type FetchSourceEntitySchemasQuery = {
  __typename?: 'Query'
  sourceEntitySchemas: Array<{
    __typename?: 'SourceEntitySchema'
    name: string
    label: string
    forcePick?: boolean | null
    properties: Array<{
      __typename?: 'SourceEntityPropertySchema'
      name: string
      label: string
      forcePick?: boolean | null
      type?: PropertyTypeEnum | null
      meta?: PropertyMetaEnum | null
      referenceTo?: Array<string> | null
      selectOptions?: Array<{ __typename?: 'ModelPropertySelectOption'; value: string; label: string }> | null
    }>
  }>
}

export type FetchSourcesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchSourcesQuery = {
  __typename?: 'Query'
  sources: Array<{
    __typename?: 'Source'
    isAvailable?: boolean | null
    id: string
    provider: SourceProviderEnum
    name?: string | null
    config?: unknown | null
    writableWithoutUserSource: boolean
    organizationId: string
    createdAt: string
    updatedAt: string
  }>
}

export type FetchSourcesTableRecordsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchSourcesTableRecordsQuery = {
  __typename?: 'Query'
  sourcesTableRecords: Array<{
    __typename?: 'SourcesTableRecord'
    lastSyncedAt?: string | null
    hasError: boolean
    connection: {
      __typename?: 'Connection'
      id: string
      active: boolean
      sourceId: string
      organizationId: string
      source: {
        __typename?: 'Source'
        id: string
        organizationId: string
        provider: SourceProviderEnum
        name?: string | null
        config?: unknown | null
      }
      eltModels: Array<{
        __typename?: 'EltModel'
        id: string
        name: string
        model: CoreModel
        eltModelChange?: { __typename?: 'EltModelChange'; id: string } | null
        eltModelError?: { __typename?: 'EltModelError'; id: string } | null
      }>
    }
  }>
}

export type FetchSqlResultQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  sql: Scalars['String']['input']
  destinationId?: InputMaybe<Scalars['ID']['input']>
}>

export type FetchSqlResultQuery = {
  __typename?: 'Query'
  adminSqlResult: {
    __typename?: 'PostgresQueryResult'
    rows?: unknown | null
    rowCount?: number | null
    fields?: Array<{ __typename?: 'PostgresQueryResultField'; name: string }> | null
  }
}

export type FetchSyncSuccessfulModelsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchSyncSuccessfulModelsQuery = { __typename?: 'Query'; syncSuccessfulModels: Array<string> }

export type FetchSyncConnectionResultsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  connectionId: Scalars['ID']['input']
}>

export type FetchSyncConnectionResultsQuery = {
  __typename?: 'Query'
  syncConnectionResults: Array<{
    __typename?: 'SyncConnectionResult'
    jobQueueId: string
    createdAt: string
    checkSuccess: boolean
    checkError?: string | null
    syncSuccess: boolean
  } | null>
}

export type FetchSyncModelResultsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  jobQueueId: Scalars['ID']['input']
}>

export type FetchSyncModelResultsQuery = {
  __typename?: 'Query'
  syncModelResults: Array<{
    __typename?: 'SyncModelResult'
    success: boolean
    label: string
    name: string
    timestamp: string
    error?: string | null
  } | null>
}

export type FetchUserActiviesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  startAt: Scalars['Date']['input']
  endAt: Scalars['Date']['input']
}>

export type FetchUserActiviesQuery = {
  __typename?: 'Query'
  userActivities: Array<{
    __typename?: 'UserActivity'
    loginCount: number
    session: number
    lastSignedInAt?: string | null
    viewSheetDaysCount: number
    viewKpiDaysCount: number
    viewKpiPivotDaysCount: number
    viewKpiOrKpiPivotDaysCount: number
    user: {
      __typename?: 'V2User'
      id: string
      name: string
      imageUrl?: string | null
      role: string
      plan: UserPlanEnum
      isManager: boolean
      createdAt: string
      updatedAt: string
      identity: {
        __typename?: 'Identity'
        id: string
        email: string
        provider: IdentityProviderEnum
        createdAt: string
        updatedAt: string
        auth0UserId: string
        name: string
        role: IdentityRoleEnum
      }
      userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
    }
  }>
}

export type FetchUserGroupsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchUserGroupsQuery = {
  __typename?: 'Query'
  userGroups: Array<{
    __typename?: 'UserGroup'
    id: string
    name: string
    image_url?: string | null
    rank: number
    depth: number
    user_group_id?: string | null
    users: Array<{
      __typename?: 'ResourceUser'
      id: string
      name: string
      email: string
      user_group_id?: string | null
      image_url?: string | null
      rank: number
      visibility: boolean
      salescore_user_id?: string | null
      salescore_invitation_id?: string | null
      salesforce_user_id?: string | null
      hubspot_owner_id?: string | null
      hubspot_user_id?: string | null
      zoho_user_id?: string | null
      next_sfa_user_id?: string | null
      google_sheets_user_id?: string | null
      comdesk_user_id?: string | null
      senses_user_id?: string | null
      microsoft_dynamics_user_id?: string | null
      big_query_user_id?: string | null
      kintone_user_id?: string | null
    }>
  }>
  organization: {
    __typename?: 'V2Organization'
    setting: {
      __typename?: 'OrganizationSetting'
      id: string
      accountClosingMonth: number
      monthBeginningDay: number
      userGroupCategoryNames: Array<string>
      csvDownloadEnabledForMember: boolean
      miitelCompanyId?: string | null
      miitelAccessKey?: string | null
      shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
      i18nEnabled: boolean
      viewSiderVisibility: boolean
      defaultOpenViewIds: Array<string>
      shouldJoinRecordsInApplication: boolean
      shouldIgnoreInactiveSourceUser: boolean
      disableEditUsername: boolean
    }
  }
}

export type FetchUserSourcesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchUserSourcesQuery = {
  __typename?: 'Query'
  userSources: Array<{
    __typename?: 'UserSource'
    isAvailable?: boolean | null
    id: string
    provider: SourceProviderEnum
    name?: string | null
    createdAt: string
    updatedAt: string
  }>
  sources: Array<{
    __typename?: 'Source'
    id: string
    provider: SourceProviderEnum
    name?: string | null
    config?: unknown | null
    writableWithoutUserSource: boolean
    organizationId: string
    createdAt: string
    updatedAt: string
  }>
}

export type FetchUsersQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchUsersQuery = {
  __typename?: 'Query'
  users: Array<{
    __typename?: 'V2User'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    plan: UserPlanEnum
    isManager: boolean
    createdAt: string
    updatedAt: string
    identity: {
      __typename?: 'Identity'
      id: string
      email: string
      provider: IdentityProviderEnum
      createdAt: string
      updatedAt: string
      auth0UserId: string
      name: string
      role: IdentityRoleEnum
    }
    userLicenses: Array<{ __typename?: 'UserLicense'; license: string; plan: UserPlanEnum }>
  }>
}

export type FetchViewGroupsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchViewGroupsQuery = {
  __typename?: 'Query'
  viewGroups: Array<{
    __typename?: 'ViewGroup'
    id: string
    name: string
    streamName: string
    rank: number
    type: ViewGroupTypeEnum
    viewGroupId?: string | null
    importedSourceId?: string | null
    emoji?: string | null
    private: boolean
    createdById?: string | null
    updatedById?: string | null
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt: string
    updatedAt: string
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  }>
  viewsForSider: Array<{
    __typename?: 'ViewForSider'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
  }>
}

export type FetchViewPermissionUsersQueryVariables = Exact<{
  viewId: Scalars['ID']['input']
}>

export type FetchViewPermissionUsersQuery = {
  __typename?: 'Query'
  viewPermissionUsers: Array<{ __typename?: 'ViewPermissionUser'; viewId: string; userId: string }>
}

export type FetchViewPickerRelatedQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchViewPickerRelatedQuery = {
  __typename?: 'Query'
  viewsForSider: Array<{
    __typename?: 'ViewForSider'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
  }>
  viewGroups: Array<{
    __typename?: 'ViewGroup'
    id: string
    name: string
    streamName: string
    rank: number
    type: ViewGroupTypeEnum
    viewGroupId?: string | null
    importedSourceId?: string | null
    emoji?: string | null
    private: boolean
    createdById?: string | null
    updatedById?: string | null
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt: string
    updatedAt: string
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  }>
}

export type FetchViewQueryResultQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  viewQuery: Scalars['ViewQuery']['input']
  mustacheParameter: Scalars['JSON']['input']
  cursor?: InputMaybe<ViewQueryResultCursorInput>
  dateForDiffHighlight?: InputMaybe<Scalars['Date']['input']>
  shouldJoinRecordsInApplication?: InputMaybe<Scalars['Boolean']['input']>
  unlimitedRowsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  viewId: Scalars['ID']['input']
}>

export type FetchViewQueryResultQuery = { __typename?: 'Query'; viewQueryResult: ViewQueryResult }

export type FetchViewQueryResultAsCsvQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  viewQuery: Scalars['ViewQuery']['input']
  viewUi: Scalars['ViewUI']['input']
  mustacheParameter: Scalars['JSON']['input']
  viewId: Scalars['ID']['input']
}>

export type FetchViewQueryResultAsCsvQuery = {
  __typename?: 'Query'
  viewResultAsCsv: { __typename?: 'viewResultAsCsvResponse'; csv: string; hasNext: boolean; error?: string | null }
}

export type FetchViewQueryResultByStreamQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  streamId: Scalars['String']['input']
  unlimitedRowsEnabled?: InputMaybe<Scalars['Boolean']['input']>
}>

export type FetchViewQueryResultByStreamQuery = { __typename?: 'Query'; viewQueryResultByStream: ViewQueryResult }

export type FetchViewQueryResultsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  viewQueries: Array<Scalars['ViewQuery']['input']> | Scalars['ViewQuery']['input']
  mustacheParameter: Scalars['JSON']['input']
  cursor?: InputMaybe<ViewQueryResultCursorInput>
  dateForDiffHighlight?: InputMaybe<Scalars['Date']['input']>
  viewId: Scalars['ID']['input']
}>

export type FetchViewQueryResultsQuery = { __typename?: 'Query'; viewQueryResults: Array<ViewQueryResult | null> }

export type FetchViewSearchSqlResultQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  sql: Scalars['String']['input']
}>

export type FetchViewSearchSqlResultQuery = {
  __typename?: 'Query'
  viewSearchSqlResult: {
    __typename?: 'PostgresQueryResult'
    rows?: unknown | null
    rowCount?: number | null
    fields?: Array<{ __typename?: 'PostgresQueryResultField'; name: string }> | null
  }
}

export type FetchViewSyncTimeQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  viewId: Scalars['ID']['input']
}>

export type FetchViewSyncTimeQuery = { __typename?: 'Query'; viewOldestSyncTime?: string | null }

export type FetchViewsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  withDependency?: InputMaybe<Scalars['Boolean']['input']>
  withReverseDependency?: InputMaybe<Scalars['Boolean']['input']>
  type?: InputMaybe<ViewConfigTypeEnum>
  modelName?: InputMaybe<Scalars['String']['input']>
}>

export type FetchViewsQuery = {
  __typename?: 'Query'
  views: Array<{
    __typename?: 'View'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    queries: Array<ViewQuery>
    ui: ViewUI
    config: ViewConfig
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
  }>
}

export type FetchViewsForSiderQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type FetchViewsForSiderQuery = {
  __typename?: 'Query'
  viewsForSider: Array<{
    __typename?: 'ViewForSider'
    id: string
    viewGroupId?: string | null
    eltModelId?: string | null
    name: string
    type: ViewConfigTypeEnum
    rank: number
    defaultPermission: PermissionActionEnum
    importedSourceId?: string | null
    emoji?: string | null
    createdById?: string | null
    updatedById?: string | null
    private: boolean
    archived: boolean
    archivedAt?: string | null
    viewGroupIdBeforeArchived?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    createdBy?: { __typename?: 'ViewCreatedBy'; id: string; name: string } | null
    updatedBy?: { __typename?: 'ViewUpdatedBy'; id: string; name: string } | null
    roleRecordPermissions: Array<{
      __typename?: 'RoleRecordPermission'
      id: string
      viewId?: string | null
      viewGroupId?: string | null
      role: string
      action: PermissionActionEnum
    }>
  }>
}

export type SubscribeJobQueueSubscriptionVariables = Exact<{
  organizationId: Scalars['ID']['input']
  jobId: Scalars['ID']['input']
}>

export type SubscribeJobQueueSubscription = {
  __typename?: 'Subscription'
  jobQueue: {
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobResult?: unknown | null
    jobArg: unknown
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
  }
}

export const AllowedIpFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllowedIpFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllowedIp' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllowedIpFieldsFragment, unknown>
export const EltConfigFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sourceNamesWhitelist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyNamesWhitelist' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EltConfigFieldsFragment, unknown>
export const ConnectionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Connection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltConfigFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sourceNamesWhitelist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyNamesWhitelist' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConnectionFieldsFragment, unknown>
export const KpiFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KpiFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kpi' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goal_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'achievement_status_calculation_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'achievement_status_target_value_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time_dependent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_dependent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KpiFieldsFragment, unknown>
export const ResourceUserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comdesk_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senses_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoft_dynamics_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'big_query_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kintone_user_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceUserFieldsFragment, unknown>
export const UserGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comdesk_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senses_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoft_dynamics_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'big_query_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kintone_user_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserGroupFieldsFragment, unknown>
export const QueryParameterConfigFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QueryParameterConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QueryParameterConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardItemId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rows' } },
          { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invisibleDimensions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'period' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryParameterConfigFieldsFragment, unknown>
export const DashboardItemFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DashboardItemV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldOrderByKpiIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpis' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'KpiFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserGroupFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'queryParameterConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'QueryParameterConfigFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comdesk_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senses_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoft_dynamics_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'big_query_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kintone_user_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KpiFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kpi' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goal_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'achievement_status_calculation_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'achievement_status_target_value_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time_dependent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_dependent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QueryParameterConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QueryParameterConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardItemId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rows' } },
          { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invisibleDimensions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'period' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardItemFieldsFragment, unknown>
export const FavoriteFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Favorite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteFieldsFragment, unknown>
export const GoalDimensionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDimensionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimension' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptionsV3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalDimensionFieldsFragment, unknown>
export const GoalConfigFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSpanType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardViewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension1Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension2Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension3Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension4Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension5Id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpiViews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aggregationFunction' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDimensionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimension' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptionsV3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalConfigFieldsFragment, unknown>
export const I18nSourceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'I18nSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'I18nSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<I18nSourceFieldsFragment, unknown>
export const UserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFieldsFragment, unknown>
export const InvitationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvitationFieldsFragment, unknown>
export const MixedUserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MixedUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MixedUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comdesk_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senses_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoft_dynamics_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'big_query_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kintone_user_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MixedUserFieldsFragment, unknown>
export const ModelPropertyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModelPropertyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelProperty' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modelName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'function' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feedbackProperties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'write' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sourcePropertyName' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'trackable' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelPropertyFieldsFragment, unknown>
export const RecordChangeHistoryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecordChangeHistoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecordChangeHistory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'changedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oldValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecordChangeHistoryFieldsFragment, unknown>
export const MyActionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyActionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MyActionV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chance_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'review' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'kpi_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'KpiFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KpiFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kpi' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goal_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'achievement_status_calculation_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'achievement_status_target_value_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time_dependent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_dependent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyActionFieldsFragment, unknown>
export const MyIdentityFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyIdentityFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MyIdentity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyIdentityFieldsFragment, unknown>
export const MyUserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MyUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizationPlans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comdesk_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senses_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoft_dynamics_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'big_query_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kintone_user_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyUserFieldsFragment, unknown>
export const OrganizationSettingFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationSettingFieldsFragment, unknown>
export const DimensionGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DimensionGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DimensionGroupFieldsFragment, unknown>
export const BusinessDayFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BusinessDayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessDay' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBusinessDay' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BusinessDayFieldsFragment, unknown>
export const OrganizationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEarlyReleaseTarget' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSettingFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dimensionGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DimensionGroupFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessDays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BusinessDayFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'feature' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DimensionGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BusinessDayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessDay' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBusinessDay' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationFieldsFragment, unknown>
export const PolicyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Policy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'principal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'permission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PolicyFieldsFragment, unknown>
export const PolicyUserRoleFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyUserRoleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyUserRole' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PolicyUserRoleFieldsFragment, unknown>
export const ReportFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Report' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'queryModel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kpiId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpi' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'KpiFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KpiFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kpi' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goal_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'achievement_status_calculation_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'achievement_status_target_value_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time_dependent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_dependent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportFieldsFragment, unknown>
export const ResourceUserWithGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserWithGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUserWithGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d1_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d1_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d2_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d2_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d3_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d3_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d4_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d4_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d5_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d5_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_depth' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceUserWithGroupFieldsFragment, unknown>
export const DestinationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DestinationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Destination' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DestinationFieldsFragment, unknown>
export const EltModelFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EltModelFieldsFragment, unknown>
export const ReverseEltFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReverseEltFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReverseElt' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destinationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DestinationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DestinationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Destination' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReverseEltFieldsFragment, unknown>
export const SheetCustomModelFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetCustomModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetCustomModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nodeName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SheetCustomModelFieldsFragment, unknown>
export const SharedUrlFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedUrlFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedUrl' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SharedUrlFieldsFragment, unknown>
export const SheetThreadCommentFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadCommentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadComment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedUrl' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackSheetThreadComment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createdBySlackUserName' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedUrlFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedUrl' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SheetThreadCommentFieldsFragment, unknown>
export const SheetThreadCommentNotificationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadCommentNotificationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadCommentNotification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThreadComment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetThreadCommentFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedUrlFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedUrl' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadCommentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadComment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedUrl' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackSheetThreadComment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createdBySlackUserName' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SheetThreadCommentNotificationFieldsFragment, unknown>
export const SheetThreadFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetThreadCommentFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedUrlFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedUrl' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadCommentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadComment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedUrl' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackSheetThreadComment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createdBySlackUserName' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SheetThreadFieldsFragment, unknown>
export const SourceEntityPropertySchemaFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceEntityPropertySchemaFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SourceEntityPropertySchema' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'forcePick' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SourceEntityPropertySchemaFieldsFragment, unknown>
export const SourceEntitySchemaFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceEntitySchemaFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SourceEntitySchema' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'forcePick' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceEntityPropertySchemaFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceEntityPropertySchemaFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SourceEntityPropertySchema' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'forcePick' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SourceEntitySchemaFieldsFragment, unknown>
export const SourceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writableWithoutUserSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SourceFieldsFragment, unknown>
export const PropertyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Property' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeForMigration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'function' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feedbackProperties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'propertyName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatable' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyFieldsFragment, unknown>
export const StreamFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StreamFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stream' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filtered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filters' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewDefinitionSQL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'materialized' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'derivedTableDefinition' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deleteSql' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deleteKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primaryKey' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependentStreams' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletable' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PropertyFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Property' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeForMigration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'function' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feedbackProperties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'propertyName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatable' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StreamFieldsFragment, unknown>
export const SubscriptionStatusFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRestSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfFreeUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfFreeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInvitationFreeOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInvitationCreatable' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionStatusFieldsFragment, unknown>
export const UserActivityFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserActivityFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'loginCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'session' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSignedInAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSheetDaysCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewKpiDaysCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewKpiPivotDaysCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewKpiOrKpiPivotDaysCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserActivityFieldsFragment, unknown>
export const UserSourceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSourceFieldsFragment, unknown>
export const RoleRecordPermissionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoleRecordPermissionFieldsFragment, unknown>
export const ViewFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'View' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'queries' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ui' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewFieldsFragment, unknown>
export const ViewForSiderFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewForSiderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewForSider' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewForSiderFieldsFragment, unknown>
export const ViewGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewGroupFieldsFragment, unknown>
export const JobQueueFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobQueueFieldsFragment, unknown>
export const JobQueueLogFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueLogFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueueLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobQueueLogFieldsFragment, unknown>
export const AddSheetCustomModelPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addSheetCustomModelProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddSheetCustomModelPropertyInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSheetCustomModelPropertyV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sheetCustomModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetCustomModelFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'addedProperty' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetCustomModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetCustomModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nodeName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddSheetCustomModelPropertyMutation, AddSheetCustomModelPropertyMutationVariables>
export const ApplyEltModelChangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'applyEltModelChange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eltModelChangeIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyEltModelChange' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eltModelChangeIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'eltModelChangeIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplyEltModelChangeMutation, ApplyEltModelChangeMutationVariables>
export const ArchiveViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'archiveView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'View' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'queries' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ui' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveViewMutation, ArchiveViewMutationVariables>
export const ArchiveViewGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'archiveViewGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveViewGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveViewGroupMutation, ArchiveViewGroupMutationVariables>
export const ConvertSalesforceLeadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'convertSalesforceLeads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConvertSalesforceLeadInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'convertSalesforceLeads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConvertSalesforceLeadsMutation, ConvertSalesforceLeadsMutationVariables>
export const CopyViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'copyView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewGroupId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPrivate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewGroupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPrivate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isPrivate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'View' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'queries' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ui' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CopyViewMutation, CopyViewMutationVariables>
export const CreateDimensionGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDimensionGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dimensionGroup' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroupCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDimensionGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dimensionGroup' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dimensionGroup' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DimensionGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DimensionGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDimensionGroupMutation, CreateDimensionGroupMutationVariables>
export const CreateEltModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEltModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eltModel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModelCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEltModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eltModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'eltModel' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEltModelMutation, CreateEltModelMutationVariables>
export const CreateFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'favorite' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FavoriteCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFavorite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'favorite' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'favorite' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Favorite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFavoriteMutation, CreateFavoriteMutationVariables>
export const CreateGoalConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createGoalConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'goalConfig' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalConfigCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGoalConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'goalConfig' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'goalConfig' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalConfigFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDimensionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimension' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptionsV3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSpanType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardViewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension1Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension2Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension3Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension4Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension5Id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpiViews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aggregationFunction' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGoalConfigMutation, CreateGoalConfigMutationVariables>
export const CreateIdentityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createIdentity' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIdentity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateIdentityMutation, CreateIdentityMutationVariables>
export const CreateKintoneUserSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createKintoneUserSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kintoneId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kintonePassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createKintoneUserSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'kintoneId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'kintoneId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'kintonePassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'kintonePassword' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateKintoneUserSourceMutation, CreateKintoneUserSourceMutationVariables>
export const CreateOauthI18nSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOauthI18nSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OauthI18nSourceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOauthI18nSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOauthI18nSourceMutation, CreateOauthI18nSourceMutationVariables>
export const CreateOauthSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOauthSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oauthSourceInput' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'OauthSourceInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOauthSourceV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'oauthSourceInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOauthSourceMutation, CreateOauthSourceMutationVariables>
export const CreateOauthUserSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOauthUserSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oauthUserSourceInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OauthUserSourceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOauthUserSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'oauthUserSourceInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOauthUserSourceMutation, CreateOauthUserSourceMutationVariables>
export const CreatePolicyUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPolicyUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyUserRole' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyUserRoleCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPolicyUserRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyUserRole' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyUserRole' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PolicyUserRoleFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyUserRoleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyUserRole' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePolicyUserRoleMutation, CreatePolicyUserRoleMutationVariables>
export const CreateReverseEltDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createReverseElt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reverseElt' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReverseEltCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReverseElt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reverseElt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reverseElt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReverseEltFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DestinationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Destination' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReverseEltFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReverseElt' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destinationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DestinationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateReverseEltMutation, CreateReverseEltMutationVariables>
export const CreateSalescoreModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSalescoreModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSalescoreModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSalescoreModelMutation, CreateSalescoreModelMutationVariables>
export const CreateSharedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSharedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sharedUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedUrlCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSharedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sharedUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sharedUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedUrlFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedUrl' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSharedUrlMutation, CreateSharedUrlMutationVariables>
export const CreateSheetThreadCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSheetThreadComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sheetThreadCommentCreateInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadCommentCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSheetThreadComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sheetThreadCommentCreateInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSheetThreadCommentMutation, CreateSheetThreadCommentMutationVariables>
export const CreateSlackOrgIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSlackOrgIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slackOrgIntegrationCreateInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SlackOrgIntegrationCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSlackOrgIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slackOrgIntegrationCreateInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'integratedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultChannelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slackUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessTokenExpiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSlackOrgIntegrationMutation, CreateSlackOrgIntegrationMutationVariables>
export const CreateSlackUserIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSlackUserIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slackUserIntegrationCreateInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SlackUserIntegrationCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSlackUserIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slackUserIntegrationCreateInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultChannelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slackUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessTokenExpiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSlackUserIntegrationMutation, CreateSlackUserIntegrationMutationVariables>
export const CreateSnapshotModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSnapshotModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'snapshotModel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotModelCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSnapshotModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'snapshotModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'snapshotModel' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSnapshotModelMutation, CreateSnapshotModelMutationVariables>
export const CreateSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SourceCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSourceMutation, CreateSourceMutationVariables>
export const CreateViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'view' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateViewInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'view' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'View' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'queries' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ui' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateViewMutation, CreateViewMutationVariables>
export const CreateViewGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createViewGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewGroup' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewGroupCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createViewGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewGroup' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateViewGroupMutation, CreateViewGroupMutationVariables>
export const DeleteDimensionGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteDimensionGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDimensionGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DimensionGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DimensionGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDimensionGroupMutation, DeleteDimensionGroupMutationVariables>
export const DeleteEltModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEltModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEltModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEltModelMutation, DeleteEltModelMutationVariables>
export const DeleteFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFavorite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Favorite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFavoriteMutation, DeleteFavoriteMutationVariables>
export const DeleteGoalConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteGoalConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGoalConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalConfigFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDimensionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimension' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptionsV3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSpanType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardViewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension1Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension2Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension3Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension4Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension5Id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpiViews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aggregationFunction' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteGoalConfigMutation, DeleteGoalConfigMutationVariables>
export const DeleteI18nSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteI18nSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteI18nSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteI18nSourceMutation, DeleteI18nSourceMutationVariables>
export const DeletePolicyUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePolicyUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePolicyUserRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PolicyUserRoleFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyUserRoleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyUserRole' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePolicyUserRoleMutation, DeletePolicyUserRoleMutationVariables>
export const DeleteReverseEltDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteReverseElt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReverseElt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReverseEltFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DestinationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Destination' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReverseEltFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReverseElt' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destinationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DestinationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteReverseEltMutation, DeleteReverseEltMutationVariables>
export const DeleteSlackOrgIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSlackOrgIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slackOrgIntegrationDeleteInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SlackOrgIntegrationDeleteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSlackOrgIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slackOrgIntegrationDeleteInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSlackOrgIntegrationMutation, DeleteSlackOrgIntegrationMutationVariables>
export const DeleteSlackUserIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSlackUserIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slackUserIntegrationDeleteInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SlackUserIntegrationDeleteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSlackUserIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slackUserIntegrationDeleteInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSlackUserIntegrationMutation, DeleteSlackUserIntegrationMutationVariables>
export const DeleteSnapshotModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSnapshotModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSnapshotModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSnapshotModelMutation, DeleteSnapshotModelMutationVariables>
export const DeleteHubSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteHubSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteHubSourceMutation, DeleteHubSourceMutationVariables>
export const DeleteUserSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteUserSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserSourceMutation, DeleteUserSourceMutationVariables>
export const DeleteViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'View' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'queries' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ui' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteViewMutation, DeleteViewMutationVariables>
export const DeleteViewGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteViewGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteViewGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteViewGroupMutation, DeleteViewGroupMutationVariables>
export const InitializeGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'initializeGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'goalConfigId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dates' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initializeGoals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'goalConfigId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'goalConfigId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dates' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dates' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalConfigFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDimensionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimension' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptionsV3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSpanType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardViewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension1Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension2Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension3Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension4Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension5Id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpiViews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aggregationFunction' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitializeGoalsMutation, InitializeGoalsMutationVariables>
export const InvalidateEltModelSyncDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'invalidateEltModelSync' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidateEltModelSync' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvalidateEltModelSyncMutation, InvalidateEltModelSyncMutationVariables>
export const JoinSlackBotToChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'joinSlackBotToChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'joinSlackBotToChannelMutationInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JoinSlackBotToChannelMutationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'joinSlackBotToChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'joinSlackBotToChannelMutationInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JoinSlackBotToChannelMutation, JoinSlackBotToChannelMutationVariables>
export const MarkSheetThreadCommentNotificationsAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markSheetThreadCommentNotificationsAsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'commentIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markSheetThreadCommentNotificationsAsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'commentIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'commentIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkSheetThreadCommentNotificationsAsReadMutation,
  MarkSheetThreadCommentNotificationsAsReadMutationVariables
>
export const MoveViewGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'moveViewGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'MoveViewGroupInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveViewGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoveViewGroupsMutation, MoveViewGroupsMutationVariables>
export const MoveViewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'moveViews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'MoveViewInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveViews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'View' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'queries' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ui' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoveViewsMutation, MoveViewsMutationVariables>
export const QueueImportSourceUsersQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'queueImportSourceUsersQueue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'queueImportSourceUsersQueue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueueImportSourceUsersQueueMutation, QueueImportSourceUsersQueueMutationVariables>
export const QueueReverseEltJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'queueReverseEltJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reverseEltId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syncMode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SyncModeEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'queueReverseEltJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reverseEltId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reverseEltId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syncMode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syncMode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueueReverseEltJobMutation, QueueReverseEltJobMutationVariables>
export const RecordRenderDurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'recordRenderDuration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'renderDuration' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RenderDurationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recordRenderDuration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renderDurationInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'renderDuration' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trigger' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRecordsCached' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecordRenderDurationMutation, RecordRenderDurationMutationVariables>
export const ReplaceCustomRolePoliciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'replaceCustomRolePolicies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customRolePolicies' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomRolePoliciesInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replaceCustomRolePolicies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customRolePolicies' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customRolePolicies' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PolicyFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Policy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'principal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'permission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReplaceCustomRolePoliciesMutation, ReplaceCustomRolePoliciesMutationVariables>
export const SyncHubConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'syncHubConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syncContext' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SyncContextEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syncContext' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syncContext' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SyncHubConnectionMutation, SyncHubConnectionMutationVariables>
export const SyncModelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'syncModels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'connectionModels' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'SyncModelsInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syncContext' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SyncContextEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncModels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'connectionModels' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'connectionModels' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syncContext' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syncContext' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SyncModelsMutation, SyncModelsMutationVariables>
export const TakeSnapshotMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'takeSnapshotMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'snapshotModelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'timestamp' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'takeSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'snapshotModelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'snapshotModelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timestamp' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'timestamp' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TakeSnapshotMutationMutation, TakeSnapshotMutationMutationVariables>
export const UpdateDimensionGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDimensionGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dimensionGroup' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroupUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDimensionGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dimensionGroup' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dimensionGroup' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DimensionGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DimensionGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDimensionGroupMutation, UpdateDimensionGroupMutationVariables>
export const UpdateEltConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEltConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eltConfig' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EltConfigInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEltConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eltConfig' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'eltConfig' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConnectionFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sourceNamesWhitelist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyNamesWhitelist' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Connection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltConfigFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEltConfigMutation, UpdateEltConfigMutationVariables>
export const UpdateEltModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEltModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eltModel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModelUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEltModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eltModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'eltModel' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEltModelMutation, UpdateEltModelMutationVariables>
export const UpdateEntityPolicyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEntityPolicy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subjectPolicies' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubjectPoliciesReplaceInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replaceSubjectPolicies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subjectPolicies' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subjectPolicies' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PolicyFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replaceViewPermissionUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Policy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'principal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'permission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEntityPolicyMutation, UpdateEntityPolicyMutationVariables>
export const UpdateGoalConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateGoalConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'goalConfig' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalConfigUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoalConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'goalConfig' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'goalConfig' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalConfigFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDimensionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimension' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptionsV3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSpanType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardViewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension1Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension2Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension3Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension4Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension5Id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpiViews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aggregationFunction' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateGoalConfigMutation, UpdateGoalConfigMutationVariables>
export const UpdateMyUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMyUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'myUser' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MyUserInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMyUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'myUser' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'myUser' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMyUserMutation, UpdateMyUserMutationVariables>
export const UpdateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganizationV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organization' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>
export const UpdatePolicyUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePolicyUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyUserRole' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyUserRoleUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePolicyUserRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyUserRole' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyUserRole' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PolicyUserRoleFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyUserRoleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyUserRole' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePolicyUserRoleMutation, UpdatePolicyUserRoleMutationVariables>
export const UpdateReverseEltDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateReverseElt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reverseElt' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReverseEltUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReverseElt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reverseElt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reverseElt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReverseEltFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DestinationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Destination' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReverseEltFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReverseElt' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destinationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DestinationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateReverseEltMutation, UpdateReverseEltMutationVariables>
export const UpdateSalescoreModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSalescoreModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'column' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSalescoreModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'column' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'column' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSalescoreModelMutation, UpdateSalescoreModelMutationVariables>
export const UpdateSheetCustomModelPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSheetCustomModelProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSheetCustomModelPropertyInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSheetCustomModelProperty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetCustomModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetCustomModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetCustomModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nodeName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSheetCustomModelPropertyMutation, UpdateSheetCustomModelPropertyMutationVariables>
export const UpdateSheetThreadCommentNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSheetThreadCommentNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadCommentNotificationUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSheetThreadCommentNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetThreadCommentNotificationFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedUrlFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedUrl' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadCommentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadComment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedUrl' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackSheetThreadComment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createdBySlackUserName' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadCommentNotificationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadCommentNotification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThreadComment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetThreadCommentFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSheetThreadCommentNotificationMutation,
  UpdateSheetThreadCommentNotificationMutationVariables
>
export const UpdateSlackOrgIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSlackOrgIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slackOrgIntegrationUpdateInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SlackOrgIntegrationUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSlackOrgIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slackOrgIntegrationUpdateInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'integratedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultChannelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slackUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessTokenExpiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSlackOrgIntegrationMutation, UpdateSlackOrgIntegrationMutationVariables>
export const UpdateSlackUserIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSlackUserIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slackUserIntegrationUpdateInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SlackUserIntegrationUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSlackUserIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slackUserIntegrationUpdateInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultChannelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slackUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessTokenExpiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSlackUserIntegrationMutation, UpdateSlackUserIntegrationMutationVariables>
export const UpdateSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'config' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'config' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'config' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'config' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSourceMutation, UpdateSourceMutationVariables>
export const UpdateUserGroupByCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserGroupByCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserGroupByCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'record' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'record' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserGroupByCsvMutation, UpdateUserGroupByCsvMutationVariables>
export const UpdateUserGroupIdsByCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserGroupIdsByCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserGroupIdsByCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'record' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'record' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserGroupIdsByCsvMutation, UpdateUserGroupIdsByCsvMutationVariables>
export const UpdateViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'view' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewUpdateInputSingle' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'view' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'View' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'queries' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ui' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateViewMutation, UpdateViewMutationVariables>
export const UpdateViewGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateViewGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewGroups' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewGroupUpdateInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateViewGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewGroups' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateViewGroupsMutation, UpdateViewGroupsMutationVariables>
export const UpsertAllowedIpsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertAllowedIps' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allowedIps' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AllowedIpInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAllowedIps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'allowedIps' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'allowedIps' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllowedIpFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllowedIpFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllowedIp' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertAllowedIpsMutation, UpsertAllowedIpsMutationVariables>
export const UpsertBusinessDayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertBusinessDay' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessDay' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessDayInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertBusinessDay' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessDay' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessDay' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BusinessDayFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BusinessDayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessDay' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBusinessDay' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertBusinessDayMutation, UpsertBusinessDayMutationVariables>
export const UpsertGoalDimensionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertGoalDimensions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'goalDimensions' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimensionInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertGoalDimension' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'goalDimensions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'goalDimensions' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectOptionsV3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertGoalDimensionsMutation, UpsertGoalDimensionsMutationVariables>
export const UpsertGoalsByCsvV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertGoalsByCsvV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'goalConfigId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertGoalsByCsvV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'goalConfigId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'goalConfigId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'record' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'record' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertGoalsByCsvV2Mutation, UpsertGoalsByCsvV2MutationVariables>
export const UpsertHubConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertHubConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConnectionUpsertInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConnectionFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sourceNamesWhitelist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyNamesWhitelist' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Connection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltConfigFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertHubConnectionMutation, UpsertHubConnectionMutationVariables>
export const UpsertInvitationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertInvitations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitations' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'InvitationInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertInvitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitations' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertInvitationsMutation, UpsertInvitationsMutationVariables>
export const UpsertUserGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertUserGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userGroups' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserGroupInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertUserGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroups' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userGroups' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comdesk_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senses_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoft_dynamics_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'big_query_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kintone_user_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertUserGroupsMutation, UpsertUserGroupsMutationVariables>
export const UpsertUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'users' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserInputV2' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertUsersV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'users' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'users' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertUsersMutation, UpsertUsersMutationVariables>
export const WriteEltChangesChunkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'writeEltChangesChunk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'changesChunks' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'EltChangesChunk' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'writeEltChangesChunk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'changesChunks' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'changesChunks' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WriteEltChangesChunkMutation, WriteEltChangesChunkMutationVariables>
export const FetchAllowedIpsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchAllowedIps' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allowedIps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllowedIpFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllowedIpFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllowedIp' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAllowedIpsQuery, FetchAllowedIpsQueryVariables>
export const FetchAverageRenderDurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchAverageRenderDuration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageRenderDuration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sampleSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'averageDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRecordsCached' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAverageRenderDurationQuery, FetchAverageRenderDurationQueryVariables>
export const FetchBusinessDaysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchBusinessDays' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessDays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BusinessDayFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BusinessDayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessDay' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBusinessDay' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchBusinessDaysQuery, FetchBusinessDaysQueryVariables>
export const FetchConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConnectionFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sourceNamesWhitelist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyNamesWhitelist' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writableWithoutUserSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Connection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltConfigFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchConnectionQuery, FetchConnectionQueryVariables>
export const FetchConnectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchConnections' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConnectionFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sourceNamesWhitelist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyNamesWhitelist' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Connection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltConfigFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writableWithoutUserSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchConnectionsQuery, FetchConnectionsQueryVariables>
export const FetchConnectionsWithStreamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchConnectionsWithStreams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConnectionFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StreamFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sourceNamesWhitelist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyNamesWhitelist' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Property' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeForMigration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'function' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feedbackProperties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'propertyName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatable' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Connection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltConfigFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writableWithoutUserSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StreamFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stream' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filtered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filters' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewDefinitionSQL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'materialized' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'derivedTableDefinition' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deleteSql' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deleteKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primaryKey' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependentStreams' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletable' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PropertyFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchConnectionsWithStreamsQuery, FetchConnectionsWithStreamsQueryVariables>
export const FetchConvertingSalesforceLeadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchConvertingSalesforceLeads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leadIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'convertingSalesforceLeads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'leadIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'leadIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConverted' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'candidateAccounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'candidateContacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadStatuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchConvertingSalesforceLeadsQuery, FetchConvertingSalesforceLeadsQueryVariables>
export const FetchDimensionGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchDimensionGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dimensionGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DimensionGroupFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'models' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalConfigFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimensions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDimensionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimension' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptionsV3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DimensionGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSpanType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardViewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension1Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension2Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension3Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension4Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension5Id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpiViews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aggregationFunction' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchDimensionGroupsQuery, FetchDimensionGroupsQueryVariables>
export const FetchEltModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchEltModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchEltModelQuery, FetchEltModelQueryVariables>
export const FetchEltModelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchEltModels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltModels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchEltModelsQuery, FetchEltModelsQueryVariables>
export const FetchFavoritesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchFavorites' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'favorites' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Favorite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchFavoritesQuery, FetchFavoritesQueryVariables>
export const FetchGoalConfigFormRelatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchGoalConfigFormRelated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimensions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewsForSider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewForSiderFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDimensionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimension' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptionsV3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewForSiderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewForSider' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchGoalConfigFormRelatedQuery, FetchGoalConfigFormRelatedQueryVariables>
export const FetchGoalConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchGoalConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalConfigFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDimensionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimension' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptionsV3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comdesk_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senses_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoft_dynamics_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'big_query_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kintone_user_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSpanType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupIdsConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardViewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension1Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension2Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension3Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension4Id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalDimension5Id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimension5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpiViews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aggregationFunction' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchGoalConfigsQuery, FetchGoalConfigsQueryVariables>
export const FetchGoalDimensionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchGoalDimensions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalDimensions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDimensionFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDimensionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalDimension' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptionsV3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchGoalDimensionsQuery, FetchGoalDimensionsQueryVariables>
export const FetchI18nSourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchI18nSources' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'i18nSources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'I18nSourceFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'I18nSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'I18nSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchI18nSourcesQuery, FetchI18nSourcesQueryVariables>
export const FetchImportButtonParamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchImportButtonParam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewOldestSyncTime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewHasHubspot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewSyncResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'failedEltModels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewConnections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConnectionFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewRelatedModels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConnectionFields' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eltModels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sourceNamesWhitelist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyNamesWhitelist' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Connection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltConfigFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writableWithoutUserSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchImportButtonParamQuery, FetchImportButtonParamQueryVariables>
export const FetchInvitationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchInvitations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionStatusFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DimensionGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BusinessDayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessDay' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBusinessDay' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRestSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfFreeUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfFreeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInvitationFreeOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInvitationCreatable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEarlyReleaseTarget' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSettingFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dimensionGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DimensionGroupFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessDays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BusinessDayFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'feature' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchInvitationsQuery, FetchInvitationsQueryVariables>
export const FetchJobProcessingQueuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchJobProcessingQueues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobProcessingQueues' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchJobProcessingQueuesQuery, FetchJobProcessingQueuesQueryVariables>
export const FetchJobQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchJobQueue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobQueue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobQueueLogs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueLogFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueLogFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueueLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchJobQueueQuery, FetchJobQueueQueryVariables>
export const FetchJobQueuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchJobQueues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobQueues' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchJobQueuesQuery, FetchJobQueuesQueryVariables>
export const FetchJobStatusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchJobStatuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobStatuses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchJobStatusesQuery, FetchJobStatusesQueryVariables>
export const FetchMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'identity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'organization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLicenses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEarlyReleaseTarget' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setting' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSettingFields' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dimensionGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DimensionGroupFields' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessDays' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BusinessDayFields' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'policies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PolicyFields' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organizationFeatures' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'feature' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organizationPlans' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DimensionGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BusinessDayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessDay' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBusinessDay' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Policy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'principal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'permission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comdesk_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senses_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoft_dynamics_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'big_query_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kintone_user_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchMeQuery, FetchMeQueryVariables>
export const FetchMixedUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchMixedUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mixedUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MixedUserFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comdesk_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senses_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoft_dynamics_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'big_query_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kintone_user_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MixedUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MixedUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchMixedUsersQuery, FetchMixedUsersQueryVariables>
export const FetchModelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchModels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'models' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchModelsQuery, FetchModelsQueryVariables>
export const FetchMyIdentityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchMyIdentity' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myIdentity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MyIdentityFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyIdentityFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MyIdentity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchMyIdentityQuery, FetchMyIdentityQueryVariables>
export const FetchOauthAuthorizationUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchOauthAuthorizationUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authorizeUrl' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'additionalScope' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oauthAuthorizationUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'provider' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authorizeUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authorizeUrl' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'additionalScope' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'additionalScope' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchOauthAuthorizationUrlQuery, FetchOauthAuthorizationUrlQueryVariables>
export const FetchOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionStatusFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DimensionGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BusinessDayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessDay' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBusinessDay' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEarlyReleaseTarget' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSettingFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dimensionGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DimensionGroupFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessDays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BusinessDayFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'feature' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRestSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfFreeUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfFreeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInvitationFreeOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInvitationCreatable' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchOrganizationQuery, FetchOrganizationQueryVariables>
export const FetchOrganizationSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchOrganizationSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSettingFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchOrganizationSettingQuery, FetchOrganizationSettingQueryVariables>
export const FetchPoliciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchPolicies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subjectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subjectType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subjectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subjectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subjectType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subjectType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PolicyFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Policy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'principal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'permission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchPoliciesQuery, FetchPoliciesQueryVariables>
export const FetchPolicyUserRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchPolicyUserRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyUserRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PolicyUserRoleFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyUserRoleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyUserRole' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchPolicyUserRolesQuery, FetchPolicyUserRolesQueryVariables>
export const FetchPropertiesBoilerplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchPropertiesBoilerplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sql' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldResolveId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertiesBoilerplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldResolveId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shouldResolveId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sql' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sql' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchPropertiesBoilerplateQuery, FetchPropertiesBoilerplateQueryVariables>
export const FetchQueryResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchQueryResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'queries' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'queryResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'queries' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'queries' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldLabels' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rowCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rows' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchQueryResultsQuery, FetchQueryResultsQueryVariables>
export const FetchRecordChangeHistoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchRecordChangeHistories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'propertyNames' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recordChangeHistories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyNames' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'propertyNames' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RecordChangeHistoryFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecordChangeHistoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecordChangeHistory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'changedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oldValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchRecordChangeHistoriesQuery, FetchRecordChangeHistoriesQueryVariables>
export const FetchResourceUsersWithGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchResourceUsersWithGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceUsersWithGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserWithGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserWithGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUserWithGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d1_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d1_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d2_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d2_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d3_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d3_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d4_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d4_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d5_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_d5_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_depth' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchResourceUsersWithGroupQuery, FetchResourceUsersWithGroupQueryVariables>
export const FetchReverseEltsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchReverseElts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reverseElts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReverseEltFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destinations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DestinationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltModels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DestinationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Destination' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReverseEltFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReverseElt' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destinationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DestinationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchReverseEltsQuery, FetchReverseEltsQueryVariables>
export const FetchS3SignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchS3SignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 's3ObjectPath' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 's3SignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 's3ObjectPath' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 's3ObjectPath' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchS3SignedUrlQuery, FetchS3SignedUrlQueryVariables>
export const FetchSalescoreConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSalescoreConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salescoreConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConnectionFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sourceNamesWhitelist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyNamesWhitelist' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Connection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eltConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltConfigFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writableWithoutUserSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSalescoreConnectionQuery, FetchSalescoreConnectionQueryVariables>
export const FetchSalescoreJwtDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSalescoreJwt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instanceUrl' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oauthCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salescoreJwt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instanceUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'instanceUrl' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oauthCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'oauthCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'provider' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSalescoreJwtQuery, FetchSalescoreJwtQueryVariables>
export const FetchSamlConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSamlConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'samlConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSamlConfigsQuery, FetchSamlConfigsQueryVariables>
export const FetchSharedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSharedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sheetThreadComment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sheetThread' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recordId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recordName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSharedUrlQuery, FetchSharedUrlQueryVariables>
export const FetchSheetCustomModelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSheetCustomModels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetCustomModels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetCustomModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetCustomModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetCustomModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nodeName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSheetCustomModelsQuery, FetchSheetCustomModelsQueryVariables>
export const SheetThreadCommentNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SheetThreadCommentNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThreadCommentNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetThreadCommentNotificationFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedUrlFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedUrl' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadCommentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadComment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedUrl' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackSheetThreadComment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createdBySlackUserName' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadCommentNotificationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadCommentNotification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThreadComment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetThreadCommentFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SheetThreadCommentNotificationsQuery, SheetThreadCommentNotificationsQueryVariables>
export const SheetThreadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SheetThreads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThreads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetThreadFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetThreadCommentFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedUrlFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedUrl' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadCommentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThreadComment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheetThread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedUrl' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackSheetThreadComment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createdBySlackUserName' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetThreadFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SharedUrlFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetThreadCommentFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SheetThreadsQuery, SheetThreadsQueryVariables>
export const FetchSlackBotJoinedChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSlackBotJoinedChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackBotJoinedChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'channelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSlackBotJoinedChannelQuery, FetchSlackBotJoinedChannelQueryVariables>
export const FetchSlackChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSlackChannels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackChannels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numMembers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSlackChannelsQuery, FetchSlackChannelsQueryVariables>
export const FetchSlackOrgIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSlackOrgIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackOrgIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slackUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultChannelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSlackOrgIntegrationQuery, FetchSlackOrgIntegrationQueryVariables>
export const FetchSlackOrgIntegrationUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSlackOrgIntegrationUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackOrgIntegrationUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSlackOrgIntegrationUrlQuery, FetchSlackOrgIntegrationUrlQueryVariables>
export const FetchSlackUserIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSlackUserIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackUserIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slackUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultChannelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSlackUserIntegrationQuery, FetchSlackUserIntegrationQueryVariables>
export const FetchSlackUserIntegrationUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSlackUserIntegrationUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackUserIntegrationUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSlackUserIntegrationUrlQuery, FetchSlackUserIntegrationUrlQueryVariables>
export const FetchSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writableWithoutUserSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSourceQuery, FetchSourceQueryVariables>
export const FetchSourceEntityNamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSourceEntityNames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceEntityNames' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forcePick' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSourceEntityNamesQuery, FetchSourceEntityNamesQueryVariables>
export const FetchSourceEntitySchemasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSourceEntitySchemas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetEntityNames' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceEntitySchemas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetEntityNames' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetEntityNames' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceEntitySchemaFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceEntityPropertySchemaFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SourceEntityPropertySchema' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'forcePick' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceEntitySchemaFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SourceEntitySchema' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'forcePick' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceEntityPropertySchemaFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSourceEntitySchemasQuery, FetchSourceEntitySchemasQueryVariables>
export const FetchSourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSources' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAvailable' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writableWithoutUserSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSourcesQuery, FetchSourcesQueryVariables>
export const FetchSourcesTableRecordsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSourcesTableRecords' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourcesTableRecords' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eltModels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eltModelChange' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eltModelError' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasError' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSourcesTableRecordsQuery, FetchSourcesTableRecordsQueryVariables>
export const FetchSqlResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSqlResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sql' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'destinationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminSqlResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sql' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sql' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destinationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'destinationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rows' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rowCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSqlResultQuery, FetchSqlResultQueryVariables>
export const FetchSyncSuccessfulModelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSyncSuccessfulModels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncSuccessfulModels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSyncSuccessfulModelsQuery, FetchSyncSuccessfulModelsQueryVariables>
export const FetchSyncConnectionResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSyncConnectionResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'connectionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncConnectionResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'connectionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'connectionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobQueueId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkError' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncSuccess' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSyncConnectionResultsQuery, FetchSyncConnectionResultsQueryVariables>
export const FetchSyncModelResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSyncModelResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobQueueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncModelResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobQueueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobQueueId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSyncModelResultsQuery, FetchSyncModelResultsQueryVariables>
export const FetchUserActiviesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchUserActivies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startAt' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endAt' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startAt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endAt' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserActivityFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserActivityFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'loginCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'session' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSignedInAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSheetDaysCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewKpiDaysCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewKpiPivotDaysCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewKpiOrKpiPivotDaysCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchUserActiviesQuery, FetchUserActiviesQueryVariables>
export const FetchUserGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchUserGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserGroupFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSettingFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comdesk_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senses_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoft_dynamics_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'big_query_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kintone_user_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchUserGroupsQuery, FetchUserGroupsQueryVariables>
export const FetchUserSourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchUserSources' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userSources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserSourceFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAvailable' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writableWithoutUserSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchUserSourcesQuery, FetchUserSourcesQueryVariables>
export const FetchUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'auth0UserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'license' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchUsersQuery, FetchUsersQueryVariables>
export const FetchViewGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViewGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewGroupFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewsForSider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewForSiderFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewForSiderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewForSider' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewGroupsQuery, FetchViewGroupsQueryVariables>
export const FetchViewPermissionUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViewPermissionUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPermissionUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewPermissionUsersQuery, FetchViewPermissionUsersQueryVariables>
export const FetchViewPickerRelatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViewPickerRelated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewsForSider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewForSiderFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewGroupFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewForSiderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewForSider' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewPickerRelatedQuery, FetchViewPickerRelatedQueryVariables>
export const FetchViewQueryResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViewQueryResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewQuery' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewQuery' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mustacheParameter' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewQueryResultCursorInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateForDiffHighlight' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'unlimitedRowsEnabled' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewQueryResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewQuery' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewQuery' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mustacheParameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mustacheParameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cursor' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateForDiffHighlight' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateForDiffHighlight' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'unlimitedRowsEnabled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'unlimitedRowsEnabled' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewQueryResultQuery, FetchViewQueryResultQueryVariables>
export const FetchViewQueryResultAsCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViewQueryResultAsCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewQuery' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewQuery' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewUi' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewUI' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mustacheParameter' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewResultAsCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewQuery' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewQuery' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewUi' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewUi' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mustacheParameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mustacheParameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'csv' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewQueryResultAsCsvQuery, FetchViewQueryResultAsCsvQueryVariables>
export const FetchViewQueryResultByStreamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViewQueryResultByStream' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'streamId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'unlimitedRowsEnabled' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewQueryResultByStream' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'streamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'streamId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'unlimitedRowsEnabled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'unlimitedRowsEnabled' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewQueryResultByStreamQuery, FetchViewQueryResultByStreamQueryVariables>
export const FetchViewQueryResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViewQueryResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewQueries' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewQuery' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mustacheParameter' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewQueryResultCursorInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateForDiffHighlight' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewQueryResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewQueries' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewQueries' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mustacheParameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mustacheParameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cursor' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateForDiffHighlight' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateForDiffHighlight' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewQueryResultsQuery, FetchViewQueryResultsQueryVariables>
export const FetchViewSearchSqlResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViewSearchSqlResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sql' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewSearchSqlResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sql' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sql' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rows' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rowCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewSearchSqlResultQuery, FetchViewSearchSqlResultQueryVariables>
export const FetchViewSyncTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViewSyncTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewOldestSyncTime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'viewId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewSyncTimeQuery, FetchViewSyncTimeQueryVariables>
export const FetchViewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'withDependency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'withReverseDependency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewConfigTypeEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'views' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'withDependency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'withDependency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'withReverseDependency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'withReverseDependency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'View' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'queries' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ui' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewsQuery, FetchViewsQueryVariables>
export const FetchViewsForSiderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchViewsForSider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewsForSider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewForSiderFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleRecordPermissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleRecordPermission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewForSiderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewForSider' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eltModelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPermission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewGroupIdBeforeArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleRecordPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleRecordPermissionFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewsForSiderQuery, FetchViewsForSiderQueryVariables>
export const SubscribeJobQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'SubscribeJobQueue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobQueue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscribeJobQueueSubscription, SubscribeJobQueueSubscriptionVariables>
