import { isNull } from '@salescore/buff-common'
import { type PolicyAction, type PolicySubject, UserAbility } from '@salescore/features'
import { selectorFamily, useRecoilValue } from 'recoil'

import { meAtom } from '../atoms'

export const abilitySelectorFamily = selectorFamily<boolean, { action: PolicyAction; subject?: PolicySubject }>({
  key: `common/abilitySelectorFamily`,
  get:
    ({ action, subject }) =>
    ({ get }) => {
      const me = get(meAtom)
      if (me === undefined) {
        // 通常の処理フローではここに到達することはない
        throw new Error('user not logged in')
      }

      const userAbility = new UserAbility(
        {
          id: me.myUser.id,
          role: me.myUser.role === 'guest' ? 'member' : me.myUser.role,
          organizationId: me.organization.id,
        },
        {
          resolve: ({ action, subject }) => {
            const {
              myUser: {
                organization: { policies: orgPolicies },
              },
            } = me
            if (subject === undefined) {
              // NOTE: コード(defaultPolicies.ts)でのポリシー管理から脱却し、
              //       デフォルト/カスタム権限の両方をPolicyテーブルから取得する。
              //       BEで組織紐づかないデフォルト権限用のOR条件(organizationId=null)を加えている
              return orgPolicies.filter((x) => x.action === action && isNull(x.subject)).map((x) => ({ ...x }))
            }

            return orgPolicies
              .filter(
                (x) =>
                  x.subject?.id === subject.id && x.subject.entityType === subject.entityType && x.action === action,
              )
              .map((x) => ({ ...x, subject: x.subject ?? undefined }))
          },
        },
      )
      return userAbility.can(action, subject)
    },
})

export const useCan = (action: PolicyAction, subject?: PolicySubject): boolean =>
  useRecoilValue(abilitySelectorFamily({ action, subject }))
