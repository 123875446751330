import { isNull } from '@salescore/buff-common'
import {
  type InvitationFieldsFragment,
  type UserFieldsFragment,
  UserLicenseTypeEnum,
  type UserPlanEnum,
} from '@salescore/client-api'
import { Space, Tag } from 'antd'
import { t } from 'i18next'

// invitationテーブルからplanForSync|Visを消すまでの暫定の型とcheck関数
// 削除後はuserLicensesで統一される
type UserOrInvitation = UserFieldsFragment | InvitationFieldsFragment
function isUserFieldsFragment(obj: UserOrInvitation): obj is UserFieldsFragment {
  return 'userLicenses' in obj
}

export const UserPlanTag = ({ userOrInvitation }: { userOrInvitation: UserOrInvitation | undefined }) => {
  if (isNull(userOrInvitation)) {
    return <></>
  }
  const syncPlan = isUserFieldsFragment(userOrInvitation)
    ? userOrInvitation.userLicenses.find((x) => x.license === UserLicenseTypeEnum.Sync)?.plan
    : userOrInvitation.planForSync
  const visualizePlan = isUserFieldsFragment(userOrInvitation)
    ? userOrInvitation.userLicenses.find((x) => x.license === UserLicenseTypeEnum.Visualize)?.plan
    : userOrInvitation.planForVis
  const riPlan = isUserFieldsFragment(userOrInvitation)
    ? userOrInvitation.userLicenses?.find((x) => x.license === UserLicenseTypeEnum.Ri)?.plan
    : undefined
  return (
    <Space>
      <PlanTag color={`blue`} plan={syncPlan}>
        SYNC
      </PlanTag>
      <PlanTag color={`green`} plan={visualizePlan}>
        VISUALIZE
      </PlanTag>
      <PlanTag color={`volcano`} plan={riPlan}>
        RI
      </PlanTag>
    </Space>
  )
}

function PlanTag({ plan, children, color }: { plan: UserPlanEnum | undefined; children: string; color: string }) {
  if (plan === 'none' || plan === undefined) {
    return <></>
  }

  return (
    <Tag style={{ opacity: plan === 'standard' ? 1 : 0.7 }} color={color}>
      {children}
      {plan === 'free' ? t(`(無料)`) : ''}
    </Tag>
  )
}
