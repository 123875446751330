import { notifyBugsnag } from '@salescore/client-base'
import type { NodePropertyName, ViewConfigField, ViewQueryList } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'
import { t } from 'i18next'

import { InvalidViewNodesError, validateTree } from '../../../../state/useViewEditorState/treeActions'
import { feedbackMessagesAtom } from '../../../records/atoms'
import { hasChangeToViewSchemaAtom, listQueryAtom } from '../../atoms'
import { meRelatedSelector } from '../../selectors/meRelatedSelector'
import { viewSelector } from '../../selectors/viewSelector'
import { pruneQuery } from '../pruneQuery'
import { setConfigMutation } from '../setConfigMutation'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Argument = {
  nodeProperty: NodePropertyName
  targetIndex: number
  onRemoved: () => void
  onAdded: () => void
}
mutation<ViewQueryList>({
  key: `view/setTreeAndFieldsMutation`,
  set({ get, set }, newQuery) {
    set(listQueryAtom, (oldQuery) => {
      const prunedQuery = pruneQuery(newQuery)
      if (!validateTree(prunedQuery.tree)) {
        notifyBugsnag({ error: new InvalidViewNodesError() })
        set(feedbackMessagesAtom, [
          {
            message: t(`同じ名前のノードが含まれています`),
            type: 'warn' as const,
          },
        ])
        return oldQuery
      }

      return prunedQuery
    })
  },
})

export const addFieldByPropertyMutation = mutation<Argument>({
  key: `view/addFieldByPropertyMutation`,
  set({ get, set }, { nodeProperty, targetIndex, onRemoved, onAdded }) {
    const { permission, me } = get(meRelatedSelector)
    if (!permission.view.canEditField) {
      return // 本来辿り着かないはずなので、特にメッセージなどは出さない（出したいが、recoilの中でメッセージを表示させる機構が現状ない）
    }
    const { config } = get(viewSelector)
    // TODO: このレイヤでnode,model,propertyのチェックは行うか？
    // const { streams } = get(connectionsSelector)
    // const { getNodeName } = get(querySelector)
    const fields = config.fields ?? []
    // 既に含まれているfieldをクリックしたのであれば、削除する
    if (
      isNodePropertyIncluded(
        nodeProperty,
        fields.map((x) => x.property),
      )
    ) {
      // 削除する
      set(setConfigMutation, {
        ...config,
        fields: fields.filter((field) => !isSame(nodeProperty, field.property)),
      })
      set(hasChangeToViewSchemaAtom, true)
      onRemoved()
      return
    }

    const newField: ViewConfigField = {
      type: 'property',
      property: nodeProperty,
    }
    set(setConfigMutation, {
      ...config,
      fields: [...fields.slice(0, targetIndex + 1), newField, ...fields.slice(targetIndex + 1)],
    })
    set(hasChangeToViewSchemaAtom, true)
    onAdded()
  },
})

export function isNodePropertyIncluded(x: NodePropertyName, ys: NodePropertyName[]) {
  return ys.some((y) => isSame(x, y))
}

function isSame(x: NodePropertyName, field: NodePropertyName) {
  return field.nodeName === x.nodeName && field.modelName === x.modelName && field.propertyName === x.propertyName
}
