import { r } from '@salescore/buff-common'

import type { PolicyAction } from './action'
import { createdByAttributePolicyDefinition } from './defaultPolicies/createdByAttributePolicyDefinition'
import { managerRolePolicyDefinition } from './defaultPolicies/managerRolePolicyDefinition'
import { memberRolePolicyDefinition } from './defaultPolicies/memberRolePolicyDefinition'
import { minimalRolePolicyDefinition } from './defaultPolicies/minimalRolePolicyDefinition'
import { nxMemberRolePolicyDefinition } from './defaultPolicies/nxMemberRolePolicyDefinition'
import { playingManagerRolePolicyDefinition } from './defaultPolicies/playingManagerRolePolicyDefinition'
import type { Policy, PolicyUserRole } from './schema'

// デフォルトで指定されるポリシー
const defaultPolicies = [
  //
  // ロールごとのポリシー宣言
  //
  ...r(managerRolePolicyDefinition).map((action, permission) => createRolePolicy('manager', action, permission)),
  ...r(playingManagerRolePolicyDefinition).map((action, permission) =>
    createRolePolicy('playingManager', action, permission),
  ),
  ...r(memberRolePolicyDefinition).map((action, permission) => createRolePolicy('member', action, permission)),
  ...r(minimalRolePolicyDefinition).map((action, permission) => createRolePolicy('minimal', action, permission)),
  // nx様対応
  ...r(nxMemberRolePolicyDefinition).map((action, permission) => createRolePolicy('nxMember', action, permission)),
  ...r(createdByAttributePolicyDefinition).map((action, permission) =>
    permission === undefined ? undefined : createUserAttributePolicy('createdBy', action, permission),
  ),
].compact()

// abilityのロジックで使うのは、actionをkeyとしたRecord型
export const defaultPoliciesMapper: Record<PolicyAction, Policy[]> = defaultPolicies.groupBy((x) => x.action).data

function createRolePolicy(role: PolicyUserRole, action: PolicyAction, permission: boolean): Policy {
  return {
    principal: {
      type: `userRole`,
      role,
    },
    action,
    permission,
  }
}

function createUserAttributePolicy(attributeType: 'createdBy', action: PolicyAction, permission: boolean): Policy {
  return {
    principal: {
      type: `userAttribute`,
      attributeType,
    },
    action,
    permission,
  }
}
