import { recoil } from '@salescore/client-recoil'
import { pruneSheetConfigTree } from '@salescore/core'
import { message } from 'antd'
import { t } from 'i18next'
import { useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { treeActions } from '../../state/useViewEditorState/treeActions'
import { configAtom, hasChangeToViewSchemaAtom, listQueryAtom, uiAtom, viewAtom } from './atoms'
import { useViewsContextValue } from './hooks'
import { setConfigWithoutChangeMutation } from './mutations/setConfigMutation'

// TODO: 全てをselectorで書き直し
export const useViewMutation = () => {
  const { updateView } = useViewsContextValue()

  const view = useRecoilValue(viewAtom)
  const query = useRecoilValue(listQueryAtom)
  const ui = useRecoilValue(uiAtom)
  const config = useRecoilValue(configAtom)
  const configReference = useRef(config)

  const setConfigWithoutChange = useSetRecoilState(setConfigWithoutChangeMutation)
  const setHasChangeToViewSchema = useSetRecoilState(hasChangeToViewSchemaAtom)
  const setCurrentViewUI = useSetRecoilState(uiAtom)
  const canUpdate = recoil.global.policy.useCanForView('update', view)

  useEffect(() => {
    configReference.current = config
  }, [config])

  return {
    setConfigWithoutChange,
    setHasChangeToViewSchema,
    setCurrentViewUI,
    ...treeActions(),

    async saveCurrentView() {
      // HACK:
      // Reactのバッチ更新の兼ね合いにより、saveCurrentViewを呼び出した時点のconfigAtomの値が古い場合がある
      // そのため、configAtomの代わりにconfigRefを使って最新の値を取得している
      const saveConfig = configReference.current
      const originalConfig = view.config
      if (originalConfig.type !== undefined && originalConfig.type !== saveConfig.type) {
        // XXX: originalConfigがなぜか{}になることがある？このケースへの対応で、originalConfig.typeのundefinedを許す
        // ありえないはずだが一応チェック
        return
      }

      // sheetでなければ、単純に保存するだけ
      if (saveConfig.type !== 'sheet') {
        if (!canUpdate) {
          void message.warning(t(`このビューを変更する権限がないため、変更は保存されません。`)) // TODO: セッション中に一度だけ表示したい。
          return
        }
        try {
          const result = await updateView({
            id: view.id,
            config: saveConfig,
          })
          return result
        } catch (error) {
          if (error instanceof Error) {
            void message.error(error.message)
          } else {
            void message.error(t(`エラーが発生しました`))
          }
          return
        }
      }

      if (view === undefined || query === undefined || ui === undefined) {
        return
      }

      if (!canUpdate) {
        void message.warning(t(`このビューを変更する権限がないため、変更は保存されません。`)) // TODO: セッション中に一度だけ表示したい。
        return
      }

      const prunedTree = pruneSheetConfigTree(saveConfig)
      const prunedConfig = {
        ...saveConfig,
        tree: prunedTree,
      }

      try {
        const result = await updateView({
          id: view.id,
          config: prunedConfig,
        })
        return result
      } catch (error) {
        if (error instanceof Error) {
          void message.error(error.message)
        } else {
          void message.error(t(`エラーが発生しました`))
        }
      }
    },
  }
}
